import _ from 'lodash';
import BankEmployeeService from '@/api/bankEmployee.service';
import _moduleHelper from '@/store/modules/_moduleHelper';

export const NAMESPACE = 'BANK_EMPLOYEE';

export const STATE = {
    TOKEN: 'TOKEN',
    TOKEN_PAYLOAD: 'TOKEN_PAYLOAD',
    LOGGED_IN_BANK_EMPLOYEE: 'LOGGED_IN_BANK_EMPLOYEE',
    CUSTOMER_LIST: 'CUSTOMER_LIST',
    CUSTOMER_TABLE_CONFIG: 'CUSTOMER_TABLE_CONFIG',
    ENQUIRIES_TABLE_CONFIG: 'ENQUIRIES_TABLE_CONFIG',
    DISBURSEMENT_TABLE_CONFIG: 'DISBURSEMENT_TABLE_CONFIG',
    BANK_EMPLOYEE_LIST: 'BANK_EMPLOYEE_LIST',
};

export const GETTERS = {
    IS_LOGGED_IN: 'IS_LOGGED_IN',
    REFRESH_NEEDED: 'REFRESH_NEEDED',
    LOGGED_IN_BANK_EMPLOYEE_LOADED: 'LOGGED_IN_BANK_EMPLOYEE_LOADED',
    ROLES: 'ROLES',
    HAS_ROLE: 'HAS_ROLE',
};

export const MUTATIONS = {
    SET_TOKEN: 'SET_TOKEN',
    RESET_TOKEN: 'RESET_TOKEN',
    SET_TOKEN_PAYLOAD: 'SET_TOKEN_PAYLOAD',
    RESET_TOKEN_PAYLOAD: 'RESET_TOKEN_PAYLOAD',
    SET_LOGGED_IN_BANK_EMPLOYEE: 'SET_LOGGED_IN_BANK_EMPLOYEE',
    RESET_LOGGED_IN_BANK_EMPLOYEE: 'RESET_LOGGED_IN_BANK_EMPLOYEE',
    SET_CUSTOMER_LIST: 'SET_CUSTOMER_LIST',
    SET_CUSTOMER_TABLE_CONFIG: 'SET_CUSTOMER_TABLE_CONFIG',
    SET_ENQUIRIES_TABLE_CONFIG: 'SET_ENQUIRIES_TABLE_CONFIG',
    SET_DISBURSEMENT_TABLE_CONFIG: 'SET_DISBURSEMENT_TABLE_CONFIG',
    SET_BANK_EMPLOYEE_LIST: 'SET_BANK_EMPLOYEE_LIST',
};

export const ACTIONS = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    REFRESH: 'REFRESH',
    FETCH_CUSTOMER_LIST: 'FETCH_CUSTOMER_LIST',
    FETCH_BANK_EMPLOYEE_LIST: 'FETCH_BANK_EMPLOYEE_LIST',
    ACTIVATE: 'ACTIVATE',
    REQUEST_PASSWORD_RESET: 'REQUEST_PASSWORD_RESET',
    RESET_PASSWORD: 'RESET_PASSWORD',
};

export const MODULE = {
    namespaced: true,
    state: {
        [STATE.TOKEN]: null,
        [STATE.TOKEN_PAYLOAD]: {},
        [STATE.LOGGED_IN_BANK_EMPLOYEE]: {},
        [STATE.CUSTOMER_LIST]: {},
        [STATE.CUSTOMER_TABLE_CONFIG]: {
            search: '',
            pagination: {
                sortBy: null,
                descending: false,
                page: 1,
                rowsPerPage: 10,
                rowsNumber: null,
            },
            showArchived: false,
        },
        [STATE.ENQUIRIES_TABLE_CONFIG]: {
            search: '',
            statusFilter: null,
            pagination: {
                sortBy: null,
                descending: false,
                page: 1,
                rowsPerPage: 10,
                rowsNumber: null,
            },
            showArchived: false,
        },
        [STATE.DISBURSEMENT_TABLE_CONFIG]: {
            search: '',
            statusFilter: null,
            pagination: {
                sortBy: null,
                descending: false,
                page: 1,
                rowsPerPage: 10,
                rowsNumber: null,
            },
            showArchived: false,
        },
        [STATE.BANK_EMPLOYEE_LIST]: {},
    },
    getters: {
        [GETTERS.IS_LOGGED_IN](state) {
            return !!state[STATE.TOKEN_PAYLOAD].id;
        },
        [GETTERS.REFRESH_NEEDED](state, getter) {
            return getter[GETTERS.IS_LOGGED_IN] && state[STATE.TOKEN] === null;
        },
        [GETTERS.LOGGED_IN_BANK_EMPLOYEE_LOADED](state) {
            return Object.keys(state[STATE.LOGGED_IN_BANK_EMPLOYEE]).length > 0;
        },
        [GETTERS.ROLES](state) {
            return state[STATE.TOKEN_PAYLOAD].roles ?? [];
        },
        [GETTERS.HAS_ROLE](state) {
            return (roles, bankEmployee = undefined, all = false) => {
                let bankEmployeeRoles;
                if (bankEmployee === undefined) {
                    bankEmployeeRoles = _.uniq([...(state[STATE.TOKEN_PAYLOAD].roles ?? []), ...(state[STATE.TOKEN_PAYLOAD].inheritedRoles ?? [])]);
                } else {
                    bankEmployeeRoles = _.uniq([...(bankEmployee.roles ?? []), ...(bankEmployee.inheritedRoles ?? [])]);
                }

                if (!Array.isArray(roles)) {
                    roles = [roles];
                }

                const checks = [];
                roles.forEach(role => checks.push(bankEmployeeRoles.includes(role)));

                if (all) {
                    return checks.every(v => !!v);
                }

                return checks.some(v => !!v);
            };
        },
    },
    mutations: {
        [MUTATIONS.SET_TOKEN](state, token) {
            state[STATE.TOKEN] = token;
        },
        [MUTATIONS.RESET_TOKEN](state) {
            state[STATE.TOKEN] = null;
        },
        [MUTATIONS.SET_TOKEN_PAYLOAD](state, payload) {
            state[STATE.TOKEN_PAYLOAD] = Object.assign({}, payload);
        },
        [MUTATIONS.RESET_TOKEN_PAYLOAD](state) {
            state[STATE.TOKEN_PAYLOAD] = {};
        },
        [MUTATIONS.SET_LOGGED_IN_BANK_EMPLOYEE](state, payload) {
            state[STATE.LOGGED_IN_BANK_EMPLOYEE] = Object.assign({}, payload);
        },
        [MUTATIONS.RESET_LOGGED_IN_BANK_EMPLOYEE](state) {
            state[STATE.LOGGED_IN_BANK_EMPLOYEE] = {};
        },
        [MUTATIONS.SET_CUSTOMER_LIST](state, payload) {
            state[STATE.CUSTOMER_LIST] = Object.assign({}, payload);
        },
        [MUTATIONS.SET_CUSTOMER_TABLE_CONFIG](state, payload) {
            state[STATE.CUSTOMER_TABLE_CONFIG] = Object.assign({}, payload);
        },
        [MUTATIONS.SET_ENQUIRIES_TABLE_CONFIG](state, payload) {
            state[STATE.ENQUIRIES_TABLE_CONFIG] = Object.assign({}, payload);
        },
        [MUTATIONS.SET_DISBURSEMENT_TABLE_CONFIG](state, payload) {
            state[STATE.DISBURSEMENT_TABLE_CONFIG] = Object.assign({}, payload);
        },
        [MUTATIONS.SET_BANK_EMPLOYEE_LIST](state, payload) {
            state[STATE.BANK_EMPLOYEE_LIST] = Object.assign({}, payload);
        },
    },
    actions: {
        async [ACTIONS.LOGIN]({ commit, state }, { email, password }) {
            let { data } = await BankEmployeeService.login(email, password);
            await handleToken(data.token, commit, state);
        },
        async [ACTIONS.LOGOUT]({ commit }) {
            await BankEmployeeService.logout();
            commit(MUTATIONS.RESET_TOKEN);
            commit(MUTATIONS.RESET_TOKEN_PAYLOAD);
            commit(MUTATIONS.RESET_LOGGED_IN_BANK_EMPLOYEE);
        },
        async [ACTIONS.REFRESH]({ commit, state }) {
            let { data } = await BankEmployeeService.refresh();
            await handleToken(data.token, commit, state);
        },
        async [ACTIONS.FETCH_CUSTOMER_LIST]({ commit }, params) {
            const { data } = await BankEmployeeService.getCustomerList(params);
            commit(MUTATIONS.SET_CUSTOMER_LIST, data);

            return data;
        },
        async [ACTIONS.FETCH_BANK_EMPLOYEE_LIST]({ commit }, params) {
            const { data } = await BankEmployeeService.getBankEmployees(params);
            commit(MUTATIONS.SET_BANK_EMPLOYEE_LIST, data);

            return data;
        },
        async [ACTIONS.ACTIVATE]({ commit, state, dispatch }, { invitationCode, password }) {
            let { data } = await BankEmployeeService.activate(invitationCode, password);
            await handleToken(data.token, commit, state);
            await dispatch(ACTIONS.REFRESH);
        },
        [ACTIONS.REQUEST_PASSWORD_RESET](_, email) {
            return BankEmployeeService.requestPasswordReset(email);
        },
        [ACTIONS.RESET_PASSWORD](_, { passwordResetCode, password }) {
            return BankEmployeeService.resetPassword(passwordResetCode, password);
        },
    },
};

export default {
    NAMESPACE,
    STATE,
    GETTERS,
    MUTATIONS,
    ACTIONS,
    MODULE,
    ..._moduleHelper(NAMESPACE),
};

async function handleToken(token, commit, state) {
    commit(MUTATIONS.SET_TOKEN, token);

    const parts = token.split('.');
    commit(MUTATIONS.SET_TOKEN_PAYLOAD, JSON.parse(atob(parts[1])));

    let { data } = await BankEmployeeService.getBankEmployee(state[STATE.TOKEN_PAYLOAD].id);
    commit(MUTATIONS.SET_LOGGED_IN_BANK_EMPLOYEE, data);
}
