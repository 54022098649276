//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date } from 'quasar';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import ValutierungService from '@/api/valutierung.service';
import { centToEuro } from '@/plugins/filter';
import DisbursementStatusBadge from '@/components/valutierung/DisbursementStatusBadge';
import * as _ from 'lodash';


export default {
    components: { DisbursementStatusBadge },
    data() {
        return {
            pageData: null,
            tableConfig: _.cloneDeep(bankEmployeeStore.state[bankEmployeeStore.STATE.DISBURSEMENT_TABLE_CONFIG]),
            columns: [
                {
                    name: 'credit.financingProject.customer.customerId',
                    label: 'Kunden-Nr.',
                    field: row => row.financingProject?.customer?.customerId,
                    sortable: true,
                },
                {
                    name: 'credit.financingProject.customer.lastName',
                    label: 'Kunde',
                    field: row => {
                        return row.financingProject?.customer?.lastName + ', ' + row.financingProject?.customer?.firstName;
                    },
                    sortable: true,
                },
                // { name: 'id', label: 'Vorhaben-Nr.', field: 'id', sortable: true },
                { name: 'name', label: 'Vorhaben', field: 'name', sortable: true },
                {
                    name: 'amount',
                    label: 'Auszahlungsbetrag',
                    field: 'amount',
                    sortable: true,
                    format: v => centToEuro(v),
                }, // TODO centToEuro
                // { name: 'employee', label: 'Berater', field: 'employee', sortable: true },
                {
                    name: 'createdAt',
                    label: 'Erstellt am',
                    field: 'createdAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
                { name: 'status', label: 'Status', field: 'status', sortable: true },
            ],
        };
    },
    computed: {
        statusFilterOptions() {
            let statusFilterOptions = ['approved', 'disbursed', 'archived', 'draft', 'problem', 'rejected', 'submitted'];

            if (!this.tableConfig.showArchived) {
                statusFilterOptions = statusFilterOptions.filter((option) => option !== 'archived');
            }

            return statusFilterOptions;
        },
        computedColumns() {
            const computedColumns = this.columns;
            computedColumns.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name === this.tableConfig.pagination.sortBy)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });

            return computedColumns;
        },
    },
    watch: {
        tableConfig: {
            deep: true,
            handler(tableConfig) {
                bankEmployeeStore.commit(bankEmployeeStore.MUTATIONS.SET_DISBURSEMENT_TABLE_CONFIG, tableConfig);
            },
        },
    },
    mounted() {
        this.updateTable({ pagination: this.tableConfig.pagination });
    },
    methods: {
        async refresh() {
            await this.updateTable({ pagination: this.tableConfig.pagination }, true);
            this.$notify('Valutierungsübersicht wurde aktualisiert.');
        },
        async updateTable(props, resetPage = false) {
            this.$q.loading.show();
            try {
                const params = {
                    'items-per-page': props.pagination.rowsPerPage,
                    'page': props.pagination.page,
                };
                if (props.pagination.sortBy) {
                    params[`order[${props.pagination.sortBy}]`] = props.pagination.descending ? 'desc' : 'asc';
                }

                if (this.tableConfig.search) {
                    params['search'] = this.tableConfig.search;
                }

                if (this.tableConfig.statusFilter?.length) {
                    params['status'] = this.tableConfig.statusFilter;
                } else if (!this.showArchived) {
                    params['status'] = this.statusFilterOptions;
                }

                const { data: filters } = await ValutierungService.getDisbursements(params);
                this.pageData = filters;
                this.tableConfig.pagination = Object.assign({}, this.tableConfig.pagination, props.pagination);
                this.tableConfig.pagination.rowsNumber = this.pageData['hydra:totalItems'];

                if (resetPage) {
                    this.tableConfig.pagination.page = 1;
                }
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        rowClick(event, row) {
            this.$router.push({ name: 'admin-valutierung-disbursement', params: { id: row.id } });
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }

        next();
    },
};
