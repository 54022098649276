//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import configStore from '@/store/modules/configStore';

export default {
    data() {
        return {
            carousel: 0,
        };
    },
    computed: {
        slides() {
            return [
                {
                    image: require('@/assets/img/intro/slide01.svg'),
                    text: `Willkommen ${this.config?.app?.titlePrepositionIn} ${this.config?.app?.title} der ${this.config?.app?.bankName}.`,
                },
                {
                    image: require('@/assets/img/intro/slide02.svg'),
                    text: `${_.upperFirst(this.config?.app?.titlePossessivePronounNominativ)} ${this.config?.app?.title} steht Ihnen 24h am Tag zur Verfügung.`,
                },
                {
                    image: require('@/assets/img/intro/slide03.svg'),
                    text: 'Sie können die Anwendung mit dem PC, Laptop, Tablet oder Smartphone nutzen.',
                },
                {
                    text: '### So geht’s!\n\n1. Vorgang öffnen\n2. Punkt „Offene Dokumente“ klicken\n3. Datei(en) auswählen oder Foto erstellen\n4. Datei(en) freigeben\n5. Fertig\n\nWiederholen Sie den Ablauf bis alle Dokumente hochgeladen sind.',
                },
                {
                    image: require('@/assets/img/intro/slide05.svg'),
                    text: 'Sie haben bereits Unterlagen an die Bank übergeben oder Ihnen liegt ein Dokument nicht vor?\n\nKein Problem geben Sie das bei dem betreffenden Dokument an.',
                },
                {
                    image: require('@/assets/img/intro/slide06.svg'),
                    text: 'In der Übersicht sehen Sie die "offenen" und "erledigten" Vorgänge oder falls es Rückfragen zu Dokumenten gibt.',
                },
            ];
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        ok() {
            this.$emit('ok');
            this.hide();
        },
        cancel() {
            this.hide();
        },
    },
};
