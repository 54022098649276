//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BankEmployeeService from '../../../api/bankEmployee.service';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import BankEmployeeArchiveDialog from '@/components/bank-employee/dialog/BankEmployeeArchiveDialog';

export default {
    data() {
        return {
            employee: null,
            editEmployee: false,
            inviteBankEmployee: 'Mitarbeiter einladen',
            availableRoles: [
                {
                    label: 'Instanz-Administrator',
                    value: 'ROLE_ADMIN',
                    hasRole: 'ROLE_ADMIN',
                },
                {
                    label: 'Demo-Administrator',
                    value: 'ROLE_DEMO_ADMIN',
                    hasRole: 'ROLE_ADMIN',
                },
                {
                    label: 'Bank-Administrator',
                    value: 'ROLE_BANK_ADMIN',
                    hasRole: 'ROLE_BANK_ADMIN',
                },
                /*
                {
                    label: 'Bank-Mitarbeiter',
                    value: 'ROLE_BANK_EMPLOYEE',
                    hasRole: 'ROLE_BANK_ADMIN',
                },
                */
                {
                    label: 'Bank-Mitarbeiter - Einreicher-Modul',
                    value: 'ROLE_BANK_EMPLOYEE_UPLOAD',
                    hasRole: 'ROLE_BANK_ADMIN',
                    hasModule: 'UPLOAD',
                },
                {
                    label: 'Bank-Mitarbeiter - Valutierungs-Modul',
                    value: 'ROLE_BANK_EMPLOYEE_VALUTIERUNG',
                    hasRole: 'ROLE_BANK_ADMIN',
                    hasModule: 'VALUTIERUNG',
                },
                {
                    label: 'Bank-Mitarbeiter - Selbstauskunft',
                    value: 'ROLE_BANK_EMPLOYEE_SELBSTAUSKUNFT',
                    hasRole: 'ROLE_BANK_ADMIN',
                    hasModule: 'SELBSTAUSKUNFT',
                },
            ],
            salutationOptions: [
                { value: 'NO_SALUTATION', label: 'Keine Anrede' },
                { value: 'MRS', label: 'Frau' },
                { value: 'MR', label: 'Herr' },
            ],
        };
    },
    computed: {
        roleValue() {
            return (role) => {
                return this.employee?.roles?.indexOf(role) !== -1;
            };
        },
        isRoleAdministrable() {
            return (config) => {
                let result = true;

                if (config.hasRole) {
                    result = result && this.hasRole(config.hasRole);
                }

                if (config.hasModule) {
                    result = result && this.hasModule(config.hasModule);
                }

                return result;
            };
        },
        hasModule() {
            // TODO
            // eslint-disable-next-line no-unused-vars
            return (module) => {
                return true;
            };
        },
        ...bankEmployeeStore.mapGetters({
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
    },
    methods: {
        edit() {
            this.editEmployee = true;
        },
        toggleRole(val, role) {
            if (val) {
                this.employee.roles.push(role);
            } else {
                this.employee.roles = this.employee.roles.filter(r => r !== role);
            }
        },
        async patchEmployee() {
            this.$q.loading.show();
            try {
                const { data } = await BankEmployeeService.patchBankEmployee(this.employee.id, {
                    salutation: this.employee.salutation,
                    firstName: this.employee.firstName,
                    lastName: this.employee.lastName,
                    email: this.employee.email,
                    roles: this.employee.roles,
                });
                this.employee = data;
                this.$notify('Der Bank-Mitarbeiter wurde erfolgreich bearbeitet.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            this.editEmployee = false;
            this.$q.loading.hide();
        },
        cancel() {
            this.editEmployee = false;
        },
        async invite() {
            this.$q.loading.show();
            try {
                await BankEmployeeService.inviteEmployee(this.employee.id);
                const { data } = await BankEmployeeService.getBankEmployee(this.employee.id);
                this.employee = data;
                this.$notify('Der Bank-Mitarbeiter wurde erfolgreich eingeladen.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async block() {
            this.$q.loading.show();
            try {
                const { data } = await BankEmployeeService.patchBankEmployee(this.employee.id, {
                    blocked: !this.employee.blocked,
                });
                if (this.employee.blocked) {
                    this.$notify('Der Bank-Mitarbeiter wurde erfolgreich entsperrt.', 'positive');
                } else {
                    this.$notify('Der Bank-Mitarbeiter wurde erfolgreich gesperrt.', 'positive');
                }
                this.employee = data;
            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
        async archive() {
            if (!this.hasRole('ROLE_BANK_ADMIN')) {
                return false;
            }
            this.$q.dialog({
                component: BankEmployeeArchiveDialog,
                parent: this,
                name: `${this.employee?.firstName} ${this.employee?.lastName}`,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await BankEmployeeService.archive(this.employee.id);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
                this.$notify('Der Bankmitarbeiter wurde archiviert.', 'positive');
                await this.$router.push({ name: 'admin-bank-employee-list' });
            });
        },
    },
    async beforeRouteEnter(to, from, next) {
        let inviteBankEmployee = 'Mitarbeiter einladen';
        try {
            const { data: employee } = await BankEmployeeService.getBankEmployee(to.params.id);

            if (employee.invitedAt || employee.activatedAt) {
                inviteBankEmployee = 'Mitarbeiter erneut einladen';
            }

            next(vm => {
                vm.employee = employee;
                vm.inviteBankEmployee = inviteBankEmployee;
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
