//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ValutierungService from '@/api/valutierung.service';

export default {
    name: 'CustomerCreditDetails',
    data() {
        return {
            creditDetails: null,
            editableCredit: false,
            editCredit: false,
            creditCategoryOptions: null,
            projectTypeOptions: null,
        };
    },
    methods: {
        edit() {
            this.editCredit = true;
        },
        async patchCredit() {
            const projectTypes = [];
            for (let id in this.creditDetails.financingProjectTypes) {
                projectTypes.push(this.creditDetails.financingProjectTypes[id]['@id']);
            }
            this.$q.loading.show();
            try {
                await ValutierungService.patchCredit(this.creditDetails.id, {
                    name: this.creditDetails.name,
                    description: this.creditDetails.description,
                    creditNumber: this.creditDetails.creditNumber,
                    amount: this.creditDetails.amount,
                    financingProjectTypes: projectTypes,
                    category: this.creditDetails?.category?.['@id'] ?? null,
                });
                this.$notify('Der Kredit wurde erfolgreich bearbeitet.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            this.editCredit = false;
            this.$q.loading.hide();
        },
        async deleteCredit() {
            this.$q.loading.show();
            try {
                await ValutierungService.deleteCredit(this.creditDetails.id);
                this.$notify('Der Kredit wurde erfolgreich gelöscht.', 'positive');
                await this.$router.push({ name: 'admin-valutierung-project-details' });
            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
        cancel() {
            this.editCredit = false;
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const { data: credit } = await ValutierungService.getCreditById(to.params.creditId);
            const { data: categoryTypes } = await ValutierungService.getCreditCategories();
            const { data: projectTypes } = await ValutierungService.getFinancingProjectTypes();
            const { data: project } = await ValutierungService.getFinancingProjectById(to.params.vorhabenId);

            next(vm => {
                vm.creditDetails = credit;
                vm.editableCredit = project.status === 'draft';
                vm.creditCategoryOptions = categoryTypes['hydra:member'];
                vm.projectTypeOptions = projectTypes['hydra:member'];
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
