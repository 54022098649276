//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AdminNavigation from '@/layouts/admin/components/AdminNavigation';

export default {
    components: {
        AdminNavigation,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        config: {
            type: Object,
            required: true,
        },
    },
};
