//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomerHeaderDesktop from '@/layouts/customer/components/CustomerHeaderDesktop';
import CustomerHeaderMobile from '@/layouts/customer/components/CustomerHeaderMobile';
import CustomerMenuMobile from '@/layouts/customer/components/CustomerMenuMobile';
import CustomerFooter from '@/layouts/customer/components/CustomerFooter';
import configStore from '@/store/modules/configStore';

export default {
    components: {
        CustomerHeaderDesktop,
        CustomerHeaderMobile,
        CustomerMenuMobile,
        CustomerFooter,
    },
    data() {
        return {
            menu: false,
        };
    },
    computed: {
        showHeader() {
            return !this.$route.meta?.hideHeader;
        },
        showFooter() {
            return !this.$route.meta?.hideFooter;
        },
        showMenu() {
            return !this.$route.meta.hideMenu;
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
};
