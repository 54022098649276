//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: String,
            default: 'null',
        },
        template: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        rules() {
            if (this.template.required) {
                return [v => !!this.parseValue(v) || 'Pflichtfeld'];
            }

            return [];
        },
    },
    methods: {
        parseValue(value) {
            try {
                return  JSON.parse(value);
            } catch (e) {
                return null;
            }
        },
        input(value, { target }) {
            if (target.nodeName === 'A') {
                this.accept = !value;
                window.open(target.getAttribute('href'), target.getAttribute('target'));
            } else {
                this.$emit('input', JSON.stringify(value));
            }
        },
        validate() {
            return this.$refs.field.validate();
        },
        resetValidation() {
            return this.$refs.field.resetValidation();
        },
        focus() {
            this.$refs.field.focus();
        },
    },
};
