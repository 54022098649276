//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';
import IntroDialog from '@/components/upload/dialogs/IntroDialog';

export default {
    computed: {
        titleRelativPronounAkkusativ() {
            return this.config?.app?.titleRelativPronounAkkusativ || 'das';
        },
        titleRelativPronounNominativ() {
            return this.config?.app?.titleRelativPronounNominativ || 'das';
        },
        title() {
            return this.config?.app?.title || 'ServicePortal';
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    methods: {
        intro() {
            this.$q.dialog({
                component: IntroDialog,
                parent: this,
            });
        },
    },
};
