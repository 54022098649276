//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BankEmployeeService from '@/api/bankEmployee.service';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import _cloneDeep from 'lodash/cloneDeep';

export default {
    data() {
        return {
            editing: false,
            employee: {},
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            salutationOptions: [
                { value: 'NO_SALUTATION', label: 'Keine Anrede' },
                { value: 'MRS', label: 'Frau' },
                { value: 'MR', label: 'Herr' },
            ],
        };
    },
    computed: {
        roles() {
            return this.loggedInBankEmployee.roles ?? [];
        },
        ...bankEmployeeStore.mapState({
            loggedInBankEmployee: bankEmployeeStore.STATE.LOGGED_IN_BANK_EMPLOYEE,
        }),
        ...bankEmployeeStore.mapGetters({
            isLoggedIn: bankEmployeeStore.GETTERS.IS_LOGGED_IN,
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
    },
    async mounted() {
        this.$set(this, 'employee', _cloneDeep(this.loggedInBankEmployee));
    },
    methods: {
        reset() {
            this.$set(this, 'employee', _cloneDeep(this.loggedInBankEmployee));
            this.currentPassword = '';
            this.newPassword = '';
            this.confirmNewPassword = '';
        },
        async patchEmployee() {
            this.$q.loading.show();
            try {
                await BankEmployeeService.changeOwnData({
                    salutation: this.employee.salutation,
                    firstName: this.employee.firstName,
                    lastName: this.employee.lastName,
                    email: this.employee.email,
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword,
                    confirmNewPassword: this.confirmNewPassword,
                });
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.REFRESH);
                this.$notify('Der Bank-Mitarbeiter wurde erfolgreich bearbeitet.', 'positive');
                if (this.employee.email !== this.loggedInBankEmployee.email) {
                    this.$notify('Um die Änderung der E-Mail-Adresse abzuschließen, schauen Sie bitte in Ihr Postfach.', 'positive');
                }
                this.reset();
                this.editing = false;
            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }

        next();
    },
};
