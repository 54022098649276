//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BankEmployeeService from '@/api/bankEmployee.service';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import downloadBlobResponse from '@/util/downloadBlobResponse';
import { date } from 'quasar';

export default {
    data() {
        return {
            search: '',
            showArchived: false,
            pagination: {
                sortBy: null,
                descending: false,
                page: 1,
                rowsPerPage: 10,
                rowsNumber: null,
            },
            columns: [
                { name: 'email', label: 'E-Mail', field: 'email', sortable: true },
                {
                    name: 'lastName',
                    label: 'Bank-Mitarbeiter',
                    field: row => {
                        return row.lastName + ', ' + row.firstName;
                    },
                    sortable: true,
                },
                {
                    name: 'invitedAt',
                    label: 'Eingeladen am',
                    field: 'invitedAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
                {
                    name: 'rights',
                    label: 'Berechtigung',
                    field: row => {
                        return this.rightsAsList(row);
                    },
                    style: { // we are going to break at words!
                        maxWidth: '220px',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                        wordSpacing: '9999px',

                    },
                },
                {
                    name: 'blocked',
                    label: 'Status',
                    sortable: true,
                    field: row => {
                        return row.blocked ? 'Gesperrt' : row.archivedAt ? 'Archiviert' : 'Aktiv';
                    },
                    classes: (row) => {
                        return row.archivedAt ? 'ellipsis status--is-archived' : 'ellipsis';
                    },
                },
            ],
        };
    },
    computed: {
        computedColumns() {
            const computedColumns = this.columns;
            computedColumns.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name === this.pagination.sortBy)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });

            return computedColumns;
        },
        ...bankEmployeeStore.mapState({
            pageData: bankEmployeeStore.STATE.BANK_EMPLOYEE_LIST,
        }),
    },
    methods: {
        rightsAsList(row) {
            // Don't introduce spaces!
            const values = ['Instanz-Administrator', 'ROLE_ADMIN',
                            'Demo-Administrator', 'ROLE_DEMO_ADMIN',
                            'Bank-Administrator', 'ROLE_BANK_ADMIN',
                            'Bank-Mitarbeiter-Einreicher-Modul', 'ROLE_BANK_EMPLOYEE_UPLOAD',
                            'Bank-Mitarbeiter-Valutierungs-Modul', 'ROLE_BANK_EMPLOYEE_VALUTIERUNG',
                            'Bank-Mitarbeiter-Selbstauskunft',  'ROLE_BANK_EMPLOYEE_SELBSTAUSKUNFT'];
            let result = '';
            for (let i = 0; i < values.length / 2; i++) {
                if (row.roles?.indexOf(values[i * 2 + 1]) !== -1) {
                    result += values[i * 2].replace('Bank-Mitarbeiter-', '') + ' ';
                }
            }
            return result;
        },
        async refresh() {
            await this.updateTable({ pagination: this.pagination });
            this.$notify('Bank-Mitarbeiter-Übersicht wurde aktualisiert.');
        },
        async updateTable(props) {
            this.$q.loading.show();
            try {
                const params = {
                    'items-per-page': props.pagination.rowsPerPage,
                    'page': props.pagination.page,
                };

                if (props.pagination.sortBy) {
                    params[`order[${props.pagination.sortBy}]`] = props.pagination.descending ? 'desc' : 'asc';
                }

                if (!this.showArchived) {
                    params['exists[archivedAt]'] = false;
                }

                if (this.search) {
                    params['search'] = this.search;
                }
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.FETCH_BANK_EMPLOYEE_LIST, params);
                this.pagination = Object.assign({}, this.pagination, props.pagination);
                this.pagination.rowsNumber = this.pageData['hydra:totalItems'];
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        rowClick(event, row) {
            // not for archived employees
            if (row.archivedAt) {
                return;
            }
            this.$router.push({ name: 'admin-bank-employee-details', params: { id: row.id } });
        },
        async csvExport() {
            const response = await BankEmployeeService.csvExport();
            downloadBlobResponse(response);
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }

        try {
            let pageData = await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.FETCH_BANK_EMPLOYEE_LIST, { 'items-per-page': 10, 'exists[archivedAt]': false });
            next(vm => vm.pagination.rowsNumber = pageData['hydra:totalItems']);
        } catch (error) {
            next(vm => vm.$handleError(error));
        }
    },
};
