//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'quasar';

export default {
    props: {
        value: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        accept: {
            type: String,
            default: 'image/*, application/pdf',
        },
        maxFileSize: {
            type: Number,
            default: 64 * 1024 * 1024,
        },
        maxTotalSize: {
            type: Number,
            default: 64 * 1024 * 1024,
        },
        text: {
            type: String,
            default: 'Foto erstellen oder Datei auswählen',
        },
        icon: {
            type: String,
            default: 'fal fa-upload',
        },
        iconColor: {
            type: String,
            default: 'primary',
        },
        message: {
            type: String,
            default: '',
        },
        comment: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        uploading: {
            type: Boolean,
            default: false,
        },
        error: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            fileProgresses: {},
        };
    },
    methods: {
        input(files) {
            this.$emit('input', files);
        },
        sort(index, direction) {
            let newValue = [...this.value];
            const buffer = newValue[index];
            newValue[index] = newValue[index + direction];
            newValue[index + direction] = buffer;

            this.$emit('input', newValue);
        },
        removeFile(index) {
            this.$refs.input.removeAtIndex(index);
        },
        rejected(rejectedEntries) {
            rejectedEntries.forEach(item => {
                switch (item.failedPropValidation) {
                    case 'max-file-size':
                        this.$notify(`Die Datei "${item.file.name}" ist mit ${format.humanStorageSize(item.file.size)} größer als die erlaubten ${format.humanStorageSize(this.maxFileSize)}.`, 'warning');
                        break;
                    case 'accept':
                        this.$notify(`Die Datei "${item.file.name}" entspricht nicht den Dateityp-Anforderungen (${this.accept}).`, 'warning');
                        break;
                    case 'max-total-size':
                        this.$notify(`Der gesamte Upload darf nicht größer sein als ${format.humanStorageSize(this.maxTotalSize)}. Die Datei "${item.file.name}" wurde daher nicht hinzugefügt.`, 'warning');
                        break;
                    default:
                        this.$notify(`Die Datei "${item.file.name}" entspricht nicht den Anforderungen.`, 'warning');
                        break;
                }
            });
        },
        progress(event) {
            let bytesLoaded = event.loaded;
            this.value.forEach((file, index) => {
                this.$set(this.fileProgresses, index, Math.max(bytesLoaded / file.size, 0.0));
                bytesLoaded -= file.size;
            });
        },
        reset() {
            this.$emit('input', []);
            this.resetProgress();
        },
        resetProgress() {
            this.fileProgresses = {};
        },
        triggerInput(event) {
            this.$refs.input.pickFiles(event);
        },
    },
};
