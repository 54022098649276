//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        disable: {
            type: Boolean,
            default: undefined,
        },
        type: {
            type: String,
            default: undefined,
        },
        to: {
            type: Object,
            default: () => undefined,
        },
        size: {
            type: String,
            default: undefined,
        },
        icon: {
            type: String,
            default: undefined,
        },
        iconRight: {
            type: String,
            default: undefined,
        },
        forceIcon: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            default: undefined,
        },
        noWrap: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        showIcon() {
            return this.forceIcon || this.$root.buttons.icons;
        },
    },
};
