import ApiClient from '@/api/_apiClient';

const EnquiryTemplatesService = {
    getEnquiryTemplates(search = '', page = 1, itemsPerPage = 10, orderBy = 'name', order = 'asc') {
        return ApiClient.get('enquiry-templates', {
            params: {
                'search': search,
                'page': page,
                'items-per-page': itemsPerPage,
                [`order[${orderBy}]`]: order,
            },
        });
    },
    postEnquiryTemplate(enquiryTemplate) {
        return ApiClient.post('enquiry-templates', enquiryTemplate);
    },
    patchEnquiryTemplate(enquiryTemplate) {
        return ApiClient.patch(`enquiry-templates/${enquiryTemplate.id}`, enquiryTemplate);
    },
    deleteEnquiryTemplate(enquiryTemplate) {
        return ApiClient.delete(`enquiry-templates/${enquiryTemplate.id}`);
    },
};

export default EnquiryTemplatesService;
