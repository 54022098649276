//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date } from 'quasar';
import CustomerService from '@/api/customer.service';
import ValutierungService from '@/api/valutierung.service';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import customerStore from '@/store/modules/customerStore';
import { centToEuro } from '@/plugins/filter';
import BankEmployeeService from '@/api/bankEmployee.service';
import EnquiryCreateDialog from '@/components/upload/dialogs/EnquiryCreateDialog';
import CustomerArchiveDialog from '@/components/customer/dialog/CustomerArchiveDialog';
import EnquiryService from '@/api/enquiry.service';

export default {
    name: 'CustomerDetails',
    data() {
        return {
            customer: {},
            inviteCustomer: 'Kunden einladen',
            financingProjects: null,
            enquiries: null,
            editCustomer: false,
            salutationOptions: [
                { value: 'NO_SALUTATION', label: 'Keine Anrede' },
                { value: 'MRS', label: 'Frau' },
                { value: 'MR', label: 'Herr' },
            ],
            availableRoles: [
                {
                    label: 'Einreicher-Modul',
                    value: 'ROLE_CUSTOMER_UPLOAD',
                    hasRole: 'ROLE_BANK_EMPLOYEE_UPLOAD',
                },
                {
                    label: 'Valutierungs-Modul',
                    value: 'ROLE_CUSTOMER_VALUTIERUNG',
                    hasRole: 'ROLE_BANK_EMPLOYEE_VALUTIERUNG',
                },
                {
                    label: 'Selbstauskunft',
                    value: 'ROLE_CUSTOMER_SELBSTAUSKUNFT',
                    hasRole: 'ROLE_BANK_EMPLOYEE_SELBSTAUSKUNFT',
                },
            ],
            columns: [
                { name: 'publicId', label: 'Vorhaben-Id', field: 'publicId', sortable: true },
                { name: 'name', label: 'Vorhaben', field: 'name', sortable: true },
                {
                    name: 'description',
                    label: 'Vorhabenbeschreibung',
                    field: 'description',
                    sortable: true,
                    style: 'white-space: normal',
                },
                { name: 'status', label: 'Vorhabenstatus', field: 'status', sortable: true },
                {
                    name: 'remainingAmount',
                    label: 'Verbleibender Kreditbetrag',
                    field: 'remainingAmount',
                    sortable: true,
                    format: v => centToEuro(v),
                },
            ],
            columnsEnquries: [
                { name: 'publicId', label: 'Id', field: 'publicId', sortable: true },
                { name: 'name', label: 'Vorgang', field: 'name', sortable: true },
                {
                    name: 'description',
                    label: 'Vorgangsbeschreibung',
                    field: 'description',
                    sortable: true,
                    style: 'white-space: normal',
                },
                { name: 'status', label: 'Vorgangsstatus', field: 'status', sortable: true },
                {
                    name: 'expiresAt',
                    label: 'Ablaufdatum',
                    field: 'expiresAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
            ],
            bankEmployeeOptions: [{ value: null, label: '--Kein Berater--' }],
        };
    },
    computed: {
        computedColumns() {
            const computedColumns = this.columns;
            computedColumns.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });

            return computedColumns;
        },
        computedEnquiryColumns() {
            const computedEnquiriesColumns = this.columnsEnquries;
            computedEnquiriesColumns.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });

            return computedEnquiriesColumns;
        },
        roleValue() {
            return (role) => {
                return this.customer?.roles?.indexOf(role) !== -1;
            };
        },
        ...bankEmployeeStore.mapGetters({
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
        ...customerStore.mapGetters({
            customerHasRole: customerStore.GETTERS.HAS_ROLE,
        }),
        editMail() {
            if (!this.editCustomer) {
                return false;
            }

            return this.customer.activatedAt === null;
        },
    },
    methods: {
        async patchCustomer() {
            this.editCustomer = false;
            this.$q.loading.show();
            try {
                const { data } = await CustomerService.editCustomer(this.customer.id, this.customer);
                this.customer = data;
                this.$notify('Der Kunde wurde erfolgreich bearbeitet.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        rowClickValutierung(event, row) {
            this.$router.push({ name: 'admin-valutierung-project-details', params: { vorhabenId: row.id } });
        },
        rowClickEnquiries(event, row) {
            this.$router.push({ name: 'admin-upload-enquiry', params: { id: row.id } });
        },
        async invite() {
            this.$q.loading.show();
            try {
                await CustomerService.inviteCustomer(this.customer.id);
                const { data } = await CustomerService.getCustomer(this.customer.id);
                this.customer = data;
                this.$notify('Der Kunde wurde erfolgreich eingeladen.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async archive() {
            this.$q.dialog({
                component: CustomerArchiveDialog,
                parent: this,
                name: `${this.customer?.firstName} ${this.customer?.lastName}`,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await CustomerService.hardArchive(this.customer.id);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
                this.$notify('Der Kunde wurde archiviert.', 'positive');
                await this.$router.push({ name: 'admin-customer-list' });
            });
        },
        async cancelDeletionRequest() {
            this.$q.loading.show();
            try {
                this.customer.requestedDeletionAt = null;
                await CustomerService.cancelDeletionRequest(this.customer.id);
                this.$notify('Löschanfrage storniert.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        toggleRole(val, role) {
            if (val) {
                this.customer.roles.push(role);
            } else {
                this.customer.roles = this.customer.roles.filter(r => r !== role);
            }
        },
        async requestSelbstauskunft() {
            this.$q.loading.show();
            try {
                await CustomerService.requestSelbstauskunft(this.customer.id);
                this.$notify('Beim Kunden wurde erfolgreich eine Selbstauskunft angefordert.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async bankEmployeeFilter(val, update) {
            const { data } = await BankEmployeeService.getBankEmployees({ 'items-per-page': 30, 'search': val, 'exists[archivedAt]': false });
            const options = [{ value: null, label: '--Kein Berater--' }];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item['@id'],
                    label: item.email,
                });
            });

            update(() => {
                this.bankEmployeeOptions = options;
            });
        },
        createEnquiry() {
            this.$q.dialog({
                component: EnquiryCreateDialog,
                parent: this,
                customer: this.customer,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    let data;
                    if (payload.enquiryTemplate) {
                        const response = await EnquiryService.createByEnquiryTemplate(
                            payload.customer,
                            payload.enquiryTemplate,
                            payload.publicId,
                            'draft',
                            payload.overrideName
                        );
                        data = response.data;
                    } else {
                        const response = await EnquiryService.postEnquiry({
                            customer: payload.customer,
                            publicId: payload.publicId,
                            name: 'Neuer Vorgang',
                            status: 'draft',
                        });
                        data = response.data;
                    }
                    await this.$router.push({ name: 'admin-upload-enquiry', params: { id: data.id } });
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        selbstauskunftBeraterEmailChanged(value) {
            this.customer.selbstauskunftBeraterEmail = typeof value === 'string' ? value : (value?.label ?? null);
        },
    },
    async beforeRouteEnter(to, from, next) {
        let inviteCustomer = 'Kunden einladen';
        try {
            const { data: customer } = await CustomerService.getCustomer(to.params.id);
            const hasRole = bankEmployeeStore.getters[bankEmployeeStore.GETTERS.HAS_ROLE];
            const customerHasRole = customerStore.getters[customerStore.GETTERS.HAS_ROLE];

            let financingProjects = null;
            if (hasRole('ROLE_BANK_EMPLOYEE_VALUTIERUNG') && customerHasRole('ROLE_CUSTOMER_VALUTIERUNG', customer)) {
                const { data } = await ValutierungService.getFinancingProjects({
                    customer: customer['@id'],
                });
                financingProjects = data;
            }

            if (customer.invitedAt || customer.activatedAt) {
                inviteCustomer = 'Kunden erneut einladen';
            }

            const bankEmployeeOptions = [{ value: null, label: '--Kein Berater--' }];
            if (customer.bankEmployee) {
                const { data: bankEmployee } = await BankEmployeeService.getResourceByIri(customer.bankEmployee);
                bankEmployeeOptions.push({
                    value: bankEmployee['@id'],
                    label: bankEmployee.email,
                });
            } else {
                customer.bankEmployee = null;
            }

            next(vm => {
                vm.customer = customer;
                vm.financingProjects = financingProjects;
                vm.inviteCustomer = inviteCustomer;
                vm.enquiries = customer.enquiries;
                vm.bankEmployeeOptions = bankEmployeeOptions;
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
