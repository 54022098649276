//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EnquiryStatus from '@/components/upload/EnquiryStatus';
export default {
    components: { EnquiryStatus },
    props: {
        enquiry: {
            type: Object,
            required: true,
        },
        newStatus: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            message: '',
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        ok() {
            this.$emit('ok', {
                file: this.file,
                message: this.message,
                newStatus: this.newStatus,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
    },
};
