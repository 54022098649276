//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerStore from '@/store/modules/customerStore';
import ValutierungService from '@/api/valutierung.service';
import FinancingProjectCard from '@/components/valutierung/FinancingProjectCard';

const itemsPerPage = 10;

export default {
    components: {
        FinancingProjectCard,
    },
    data() {
        return {
            financingProjectsPageData: {},
            page: 1,
        };
    },
    computed: {
        financingProjects() {
            return this.financingProjectsPageData['hydra:member'] ?? [];
        },
        totalItems() {
            const totalItems = this.financingProjectsPageData['hydra:totalItems'] ?? 0;

            return Math.ceil(totalItems / itemsPerPage);
        },
        ...customerStore.mapState({
            loggedInCustomer: customerStore.STATE.LOGGED_IN_CUSTOMER,
        }),
    },
    methods: {
        async refresh() {
            const { data } = await ValutierungService.getFinancingProjects({
                'customer': this.loggedInCustomer.id,
                'items-per-page': itemsPerPage,
                'page': this.page,
            });
            this.financingProjectsPageData = data;
        },
    },
    async beforeRouteEnter(to, from, next) {
        const loggedInCustomer = customerStore.state[customerStore.STATE.LOGGED_IN_CUSTOMER];
        const { data } = await ValutierungService.getFinancingProjects({
            'customer': loggedInCustomer.id,
            'items-per-page': itemsPerPage,
        });
        next(vm => {
            vm.financingProjectsPageData = data;
        });
    },
};
