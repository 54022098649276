//
//
//
//
//
//
//

export default {
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    computed: {
        active() {
            if (!this.config.active) {
                return false;
            }

            const now = new Date();

            if (this.config.endDatetime) {
                const endDatetime = new Date(this.config.endDatetime);
                if (now > endDatetime) {
                    return false;
                }
            }

            if (this.config.startDatetime) {
                const startDatetime = new Date(this.config.startDatetime);
                if (now < startDatetime) {
                    return false;
                }
            }

            return true;
        },
        icon() {
            switch (this.config.category) {
                case 'POSITIVE':
                    return 'fal fa-check-circle';
                case 'WARNING':
                case 'NEGATIVE':
                    return 'fal fa-exclamation-triangle';
                case 'INFO':
                default:
                    return 'fal fa-info-circle';
            }
        },
        category() {
            switch (this.config.category) {
                case 'POSITIVE':
                    return 'bg-positive-background text-positive';
                case 'WARNING':
                    return 'bg-warning-background text-warning';
                case 'NEGATIVE':
                    return 'bg-negative-background text-negative';
                case 'INFO':
                default:
                    return 'bg-info text-info-invert';
            }
        },
    },
};
