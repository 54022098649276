//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date } from 'quasar';
import enquiryStore from '@/store/modules/enquiryStore';

export default {
    computed: {
        title() {
            switch (this.$route.params.status) {
                case 'requested':
                    return 'Fehlende Dokumente';
                case 'uploaded':
                    return 'Hochgeladene Dokumente, die auf Ihre Bestätigung warten';
                case 'problem':
                    return 'Dokumente mit Klärungsbedarf';
                case 'ready,verified,processed,archived':
                    return 'Erledigte Dokumente';
                default:
                    return 'Dokumente';
            }
        },
        subtitle() {
            switch (this.$route.params.status) {
                case 'requested':
                    return this.enquiry.expiresAt ? `Bitte bis zum ${date.formatDate(new Date(this.enquiry.expiresAt), 'DD.MM.YYYY')} einreichen.` : '';
                case 'uploaded':
                    return 'Bitte bestätigen Sie noch die Vollständig- und Korrektheit Ihre hochgeladenen Dokumente.';
                case 'problem':
                case 'ready,verified,processed,archived':
                default:
                    return this.enquiry.expiresAt ? `Bitte bis zum ${date.formatDate(new Date(this.enquiry.expiresAt), 'DD.MM.YYYY')} einreichen.` : '';
            }
        },
        items() {
            return this.filesWithStatus(this.$route.params.status.split(','));
        },
        categories() {
            return this.filesWithStatusByCategory(this.$route.params.status.split(','));
        },
        ...enquiryStore.mapGetters({
            enquiry: enquiryStore.GETTERS.CURRENT_ENQUIRY,
            filesWithStatus: enquiryStore.GETTERS.FILES_WITH_STATUS,
            filesWithStatusByCategory: enquiryStore.GETTERS.FILES_WITH_STATUS_BY_CATEGORY,
        }),
    },
    async beforeRouteEnter(to, from, next) {
        try {
            await Promise.all([
                enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY, to.params.id),
                enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_STATUS_CHANGES, to.params.id),
                enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_FILE_STATUS_CHANGES, to.params.id),
            ]);

            if (enquiryStore.getters[enquiryStore.GETTERS.FILES_WITH_STATUS](to.params.status.split(',')).length) {
                next();
            } else {
                next({ name: 'customer-enquiry', params: { id: to.params.id } });
            }
        } catch (error) {
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY);
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY_STATUS_CHANGES);
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES);
            next({ name: 'customer-hub' });
        }
    },
};
