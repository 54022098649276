//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            acceptOptions: [
                {
                    label: 'keine Einschränkung',
                    value: null,
                    icon: 'fal fa-file',
                },
                {
                    label: 'Dokument, Bild oder PDF',
                    value: 'image/*, application/*, text/*',
                    icon: 'fal fa-copy',
                },
                {
                    label: 'Bild',
                    value: 'image/*',
                    icon: 'fal fa-file-image',
                },
                {
                    label: 'PDF',
                    value: 'application/pdf',
                    icon: 'fal fa-file-pdf',
                },
                {
                    label: 'Video',
                    value: 'video/*',
                    icon: 'fal fa-file-video',
                },
            ],
            maxSizeOptions: [
                {
                    label: 'keine Einschränkung',
                    value: null,
                },
                {
                    label: '512 kB',
                    value: 512 * 1024,
                },
                {
                    label: '1 MB',
                    value: 1024 * 1024,
                },
                {
                    label: '2 MB',
                    value: 2 * 1024 * 1024,
                },
                {
                    label: '5 MB',
                    value: 5 * 1024 * 1024,
                },
                {
                    label: '10 MB',
                    value: 10 * 1024 * 1024,
                },
            ],
        };
    },
    mounted() {
        this.$emit('input', Object.assign({}, this.value, {
            required: this.value.required ?? false,
            accept: this.value.accept ?? null,
            maxSize: this.value.maxSize ?? null,
        }));
    },
    methods: {
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        validate(focus = false) {
            return this.$refs.form.validate(focus);
        },
    },
};
