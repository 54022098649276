//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _cloneDeep from 'lodash/cloneDeep';

export default {
    props: {
        entity: {
            type: Object,
            required: true,
        },
        entityKind: {
            type: String,
            default: 'File',
            validator: (value) => ['File', 'EnquiryAttachment'].indexOf(value) !== -1,
        },
        customer: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            entityModel: _cloneDeep(this.entity),
            customerModel: _cloneDeep(this.customer),
            updateStatus: true,
        };
    },
    computed: {
        headline() {
            let entity = 'Dokument';
            if (this.entityKind === 'EnquiryAttachment') {
                entity = 'Bankdokument';
            }

            return `${entity} archivieren`;
        },
    },
    methods: {
        validateArchiveSystem(errorMessage) {
            return () => {
                if (this.entityModel.agree21ShelfId || (this.entityModel.agree21FormId && this.entityModel.agree21FormName)) {
                    return true;
                }

                return errorMessage;
            };
        },
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async ok() {
            const isValid = await this.$refs.form.validate(true);
            if (!isValid) {
                return;
            }

            this.$emit('ok', {
                entity: {
                    ...this.entityModel,
                    updateStatus: this.updateStatus,
                },
                customer: this.customerModel,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
    },
};
