//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DisbursementInputTypeCheckbox
    from '@/components/valutierung/disbursementInputTypes/DisbursementInputTypeCheckbox';
import DisbursementInputTypeDate
    from '@/components/valutierung/disbursementInputTypes/DisbursementInputTypeDate';
import DisbursementInputTypeInfo
    from '@/components/valutierung/disbursementInputTypes/DisbursementInputTypeInfo';
import DisbursementInputTypeInput
    from '@/components/valutierung/disbursementInputTypes/DisbursementInputTypeInput';
import DisbursementInputTypeProgress
    from '@/components/valutierung/disbursementInputTypes/DisbursementInputTypeProgress';
import DisbursementInputTypeSelect
    from '@/components/valutierung/disbursementInputTypes/DisbursementInputTypeSelect';
import DisbursementInputTypeTextarea
    from '@/components/valutierung/disbursementInputTypes/DisbursementInputTypeTextarea';

export default {
    components: {
        DisbursementInputTypeCheckbox,
        DisbursementInputTypeDate,
        DisbursementInputTypeInfo,
        DisbursementInputTypeInput,
        DisbursementInputTypeProgress,
        DisbursementInputTypeSelect,
        DisbursementInputTypeTextarea,
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
        disbursement: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        validate() {
            return this.$refs.input.validate();
        },
        resetValidation() {
            return this.$refs.input.resetValidation();
        },
        focus() {
            this.$refs.input.focus();
        },
    },
};
