//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            email: '',
            password: '',
        };
    },
    emits: ['submit', 'passwordReset'],
    methods: {
        submit() {
            this.$emit('submit', { email: this.email, password: this.password });
        },
        passwordReset() {
            this.$emit('passwordReset');
        },
    },
};
