import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import '@/plugins/quasar';
import registerFilter from '@/plugins/filter';
import registerMmmComponents from '@/plugins/mmm-components';
import '@/plugins/markdown';
import '@/plugins/money';
import '@/plugins/portal';
import '@/util/polyfills';
import '@/styles/index.scss';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';
import updateColors from '@/util/updateColors';
import usersnapInjector from '@/util/usersnapInjector';

// plugins
registerFilter(Vue);
registerMmmComponents(Vue);

Vue.config.productionTip = (process.env.APP_ENV !== 'production');

new Vue({
    data: {
        ready: false,
        routing: false,
        routingTimeout: null,
        loadingTimeout: null,
        activeElement: null,
        buttons: {
            rounded: true,
            icons: false,
            noCaps: true,
        },
    },
    meta() {
        const meta = {
            title: this.config?.app?.title || 'ServicePortal',
            link: {},
        };

        if (this.config?.app?.favicon) {
            meta.link.favicon = { rel: 'icon', href: this.config?.app?.favicon };
        }

        return meta;
    },
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    watch: {
        routing(value) {
            clearTimeout(this.routingTimeout);
            if (value) {
                // blur activeElement
                this.activeElement = window.document.activeElement;
                if (this.activeElement) {
                    this.activeElement.blur();
                }

                // after 750ms add active class and start ajaxBar
                this.routingTimeout = setTimeout(() => {
                    window.document.querySelector('.router-is-active').classList.add('active');
                    this.$q.loadingBar.start();
                }, 750);
            } else {
                // stop ajaxBar
                this.$q.loadingBar.stop();
            }
        },
        '$q.loading.isActive'(value) {
            clearTimeout(this.loadingTimeout);
            if (value) {
                // blur activeElement
                this.activeElement = window.document.activeElement;
                if (this.activeElement) {
                    this.activeElement.blur();
                }

                // after 750ms add active class and start ajaxBar
                this.loadingTimeout = setTimeout(() => {
                    window.document.querySelector('.q-loading').classList.add('active');
                    this.$q.loadingBar.start();
                }, 750);
            } else {
                // refocus activeElement
                if (this.activeElement) {
                    this.activeElement.focus();
                }

                // stop ajaxBar
                this.$q.loadingBar.stop();
            }
        },
    },
    async mounted() {
        try {
            await Promise.all([
                configStore.dispatch(configStore.ACTIONS.FETCH_CURRENT),
                configStore.dispatch(configStore.ACTIONS.FETCH_FOOTER_URLS),
            ]);
            updateColors(this.config.colors);

            this.buttons.rounded = this.config.design.buttonsRounded;
            this.buttons.icons = this.config.design.buttonsIcons;
            this.buttons.noCaps = this.config.design.buttonsNoCaps;

            if (this.config.design.fontSegoeUi) {
                window.document.querySelector('body').classList.add('use-segoe-ui');
                window.document.querySelector('html').classList.add('use-segoe-ui');
            }

            if (this.config.app?.usersnapCode && this.$q.screen.gt.sm) {
                usersnapInjector(this.config.app.usersnapCode);
            }
        } catch (e) {
            await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
            await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
            await this.$router.push({ name: 'customer-login' });
        }

        // refresh bank jwt
        if (bankEmployeeStore.getters[bankEmployeeStore.GETTERS.REFRESH_NEEDED]) {
            try {
                await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.REFRESH);
            } catch (e) {
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
                await this.$router.push({ name: 'admin-login' });
            }
        }

        // refresh customer jwt
        if (customerStore.getters[customerStore.GETTERS.REFRESH_NEEDED]) {
            try {
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
                await customerStore.dispatch(customerStore.ACTIONS.REFRESH);
            } catch (e) {
                await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
                await this.$router.push({ name: 'customer-login' });
            }
        }

        Vue.prototype.$notify = (message, color = 'info', position = 'top', options) => {
            this.$q.notify({
                color,
                textColor: `${color}-invert`,
                message,
                position,
                actions: [{ icon: 'fal fa-times', color: `${color}-invert` }],
                ...options,
            });
        };
        Vue.prototype.$handleError = (error = {}) => {
            this.$q.notify({
                message: 'Es ist ein Fehler aufgetreten',
                caption: error.response?.data?.detail ?? error.response?.data?.['hydra:description'] ?? null,
                color: 'negative',
                textColor: 'negative-invert',
                position: 'top',
                actions: [{ icon: 'fal fa-times', color: 'negative-invert' }],
                timeout: 30000,
            });
        };

        this.ready = true;
    },
    router,
    store,
    render: h => h(App),
}).$mount('#app');
