//
//
//
//
//
//
//
//
//

import customerStore from '@/store/modules/customerStore';

export default {
    computed: {
        ...customerStore.mapGetters({
            isLoggedIn: customerStore.GETTERS.IS_LOGGED_IN,
        }),
    },
};
