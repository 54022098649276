//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        icon: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'primary',
        },
        textColor: {
            type: String,
            default: 'primary-invert',
        },
        disable: {
            type: Boolean,
            default: undefined,
        },
        type: {
            type: String,
            default: undefined,
        },
        to: {
            type: Object,
            default: () => undefined,
        },
        size: {
            type: String,
            default: undefined,
        },
        tooltip: {
            type: String,
            default: undefined,
        },
        noWrap: {
            type: Boolean,
            default: true,
        },
    },
};
