import { render, staticRenderFns } from "./FileUploader.vue?vue&type=template&id=5cd137f2&scoped=true&"
import script from "./FileUploader.vue?vue&type=script&lang=js&"
export * from "./FileUploader.vue?vue&type=script&lang=js&"
import style0 from "./FileUploader.vue?vue&type=style&index=0&id=5cd137f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cd137f2",
  null
  
)

export default component.exports
import {QFile,QIcon,QChip,QLinearProgress,QBtn,QAvatar,QSpinner,QSpace,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QFile,QIcon,QChip,QLinearProgress,QBtn,QAvatar,QSpinner,QSpace,QField})
