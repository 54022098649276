//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _cloneDeep from 'lodash/cloneDeep';
import configStore from '@/store/modules/configStore';

const defaultEnquiryAttachmentTemplate = {
    name: '',
    description: null,
    public: false,
    agree21ShelfId: null,
    agree21Comment: null,
    agree21FormId: null,
    agree21FormName: null,
};

export default {
    props: {
        enquiryAttachment: {
            type: Object,
            default: () => _cloneDeep(defaultEnquiryAttachmentTemplate),
        },
        type: {
            type: String,
            default: 'create',
            validator: (value) => ['create', 'edit'].indexOf(value) !== -1,
        },
    },
    data() {
        const enquiryAttachmentModel = { ...(_cloneDeep(defaultEnquiryAttachmentTemplate)), ...(_cloneDeep(this.enquiryAttachment)) };

        return {
            enquiryAttachmentModel,
            file: null,
            shelfIdOptions: [],
            commentOptions: [],
        };
    },
    computed: {
        headline() {
            let type = '';
            switch (this.type) {
                case 'edit':
                    type = 'bearbeiten';
                    break;
                case 'create':
                default:
                    type = 'anlegen';
                    break;
            }

            return `Bankdokument ${type}`;
        },
        buttonLabel() {
            switch (this.type) {
                case 'edit':
                    return 'Speichern';
                case 'create':
                default:
                    return 'Anlegen';
            }
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),

        /**
         * existing uploadShelfdata affects the UI by switching to select elements instead of plain inputs
         * to provide instance specific choices and wording
         */
        useCustomAgree21ShelfData() {
            return this.config.agree21.uploadShelfdata && this.config.agree21.uploadShelfdata.length > 0;
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async ok() {
            const isValid = await this.$refs.form.validate(true);
            if (!isValid) {
                return;
            }

            this.$emit('ok', {
                enquiryAttachment: this.enquiryAttachmentModel,
                file: this.file,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
        agree21ShelfIdChanged(shelfId) {
            Object.entries(this.config.agree21.uploadShelfdata[0] ?? [])
                .find(([key, value]) => key === shelfId ? this.enquiryAttachmentModel.agree21Comment = value : '');
        },
        agree21CommentChanged(comment) {
            // Important: In the json the key is a string, so I force it to an integer
            Object.entries(this.config.agree21.uploadShelfdata[0] ?? [])
                .find(([key, value]) => value === comment ? this.enquiryAttachmentModel.agree21ShelfId = Number.parseInt(key) : '');
        },
        shelfIdFilter (val, update) {
            if (val === '') {
                update(() => {
                    this.shelfIdOptions = this.config.agree21.uploadShelfdata ? Object.keys(this.config.agree21.uploadShelfdata[0]) : [];
                });
                return;
            }

            update(() => {
                const needle = val.toLowerCase();
                this.shelfIdOptions = this.shelfIdOptions.filter(v => v.toLowerCase().indexOf(needle) > -1);
            });
        },
        commentFilter (val, update) {
            if (val === '') {
                update(() => {
                    this.commentOptions = this.config.agree21.uploadShelfdata ? Object.values(this.config.agree21.uploadShelfdata[0]) : [];
                });
                return;
            }
            update(() => {
                const needle = val.toLowerCase();
                this.commentOptions = this.commentOptions.filter(v => v.toLowerCase().indexOf(needle) > -1);
            });
        },
    },
};
