//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import enquiryTemplateStore from '@/store/modules/enquiryTemplatesStore';
import EnquiryTemplateDialog from '@/components/upload/dialogs/EnquiryTemplateDialog';
import EnquiryTemplateDeleteDialog from '@/components/upload/dialogs/EnquiryTemplateDeleteDialog';

export default {
    data() {
        return {
            loading: false,
            pagination: {
                sortBy: enquiryTemplateStore.state[enquiryTemplateStore.STATE.ORDER_BY],
                descending: enquiryTemplateStore.state[enquiryTemplateStore.STATE.ORDER] === 'desc',
                page: enquiryTemplateStore.state[enquiryTemplateStore.STATE.PAGE],
                rowsPerPage: enquiryTemplateStore.state[enquiryTemplateStore.STATE.ITEMS_PER_PAGE],
                rowsNumber: enquiryTemplateStore.state[enquiryTemplateStore.STATE.TOTAL_ITEMS],
            },
            columns: [
                { name: 'name', label: 'Bezeichnung', field: 'name', sortable: true, align: 'left' },
                { name: 'description', label: 'Beschreibung', field: (item) => _.truncate(item.description, { length: 100 }), align: 'left' },
                { name: 'fileTemplates', label: 'Dokumente', field: (item) => item.fileTemplates?.length || 0, align: 'left' },
                { name: 'actions', label: 'Aktionen', field: () => 'v-slot:body-cell-actions', align: 'left' },
            ],
        };
    },
    computed: {
        search: {
            get: () => enquiryTemplateStore.state[enquiryTemplateStore.STATE.SEARCH],
            set: (search) => enquiryTemplateStore.commit(enquiryTemplateStore.MUTATIONS.SET_SEARCH, search),
        },
        ...enquiryTemplateStore.mapState({
            enquiryTemplates: enquiryTemplateStore.STATE.ENQUIRY_TEMPLATES,
        }),
    },
    mounted() {
        if (!this.pagination.rowsNumber) {
            this.updateTable(this.pagination);
        }
    },
    methods: {
        resetSearch() {
            this.search = '';
            this.updateTable(this.pagination);
        },
        rowClick(event, row) {
            this.edit(row);
        },
        async updateTable(pagination) {
            enquiryTemplateStore.commit(enquiryTemplateStore.MUTATIONS.SET_PAGE, pagination.page);
            enquiryTemplateStore.commit(enquiryTemplateStore.MUTATIONS.SET_ITEMS_PER_PAGE, pagination.rowsPerPage);
            enquiryTemplateStore.commit(enquiryTemplateStore.MUTATIONS.SET_ORDER_BY, pagination.sortBy);
            enquiryTemplateStore.commit(enquiryTemplateStore.MUTATIONS.SET_ORDER, pagination.descending ? 'desc' : 'asc');
            this.loading = true;
            await enquiryTemplateStore.dispatch(enquiryTemplateStore.ACTIONS.GET_ENQUIRY_TEMPLATES);
            this.loading = false;
            this.pagination = Object.assign({}, this.pagination, pagination);
            this.pagination.rowsNumber = enquiryTemplateStore.state[enquiryTemplateStore.STATE.TOTAL_ITEMS];
        },
        create() {
            this.$q.dialog({
                component: EnquiryTemplateDialog,
                parent: this,
            }).onOk(async (enquiryTemplate) => {
                this.$q.loading.show();
                try {
                    await enquiryTemplateStore.dispatch(enquiryTemplateStore.ACTIONS.POST_ENQUIRY_TEMPLATE, enquiryTemplate);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        edit(enquiryTemplate) {
            this.$q.dialog({
                component: EnquiryTemplateDialog,
                parent: this,
                value: _.cloneDeep(enquiryTemplate),
                type: 'edit',
            }).onOk(async (editedEnquiryTemplate) => {
                this.$q.loading.show();
                try {
                    await enquiryTemplateStore.dispatch(enquiryTemplateStore.ACTIONS.PATCH_ENQUIRY_TEMPLATE, editedEnquiryTemplate);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        remove(enquiryTemplate) {
            this.$q.dialog({
                component: EnquiryTemplateDeleteDialog,
                parent: this,
                name: enquiryTemplate.name,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await enquiryTemplateStore.dispatch(enquiryTemplateStore.ACTIONS.DELETE_ENQUIRY_TEMPLATE, enquiryTemplate);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
    },
};
