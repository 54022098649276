//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';
import AdminMenu from '@/layouts/admin/components/AdminMenu';
import { VERSION } from '@/version';

export default {
    components: {
        AdminMenu,
    },
    data() {
        return {
            menu: false,
            VERSION: VERSION,
        };
    },
    computed: {
        showHeader() {
            return !this.$route.meta?.hideHeader;
        },
        showFooter() {
            return !this.$route.meta?.hideFooter;
        },
        showMenu() {
            return !this.$route.meta.hideMenu;
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
};
