//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import customerStore from '@/store/modules/customerStore';
import configStore from '@/store/modules/configStore';
import ApiClient from '@/api/_apiClient';
import AcceptCheckboxInvite from '@/components/customer/invite/AcceptCheckboxInvite';
import YesInvite from '@/components/customer/invite/YesInvite';
import CredentialInvite from '@/components/customer/invite/CredentialInvite';
import TechnicalMessages from '@/components/core/TechnicalMessages';

export default {
    components: { TechnicalMessages, CredentialInvite, YesInvite, AcceptCheckboxInvite },
    data() {
        return {
            tab: 'yes',
            accept: false,
        };
    },
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    methods: {
        async yes() {
            this.$q.loading.show();
            try {
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
                const { data } = await ApiClient.get('/_yes/register-with-invitation-code', {
                    baseURL: '',
                    params: {
                        'invitation-code': this.$route.params.invitationCode,
                    },
                });
                window.location.href = data.url;
            } catch (error) {
                this.$root.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async submit(password) {
            this.$q.loading.show();
            try {
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
                await customerStore.dispatch(customerStore.ACTIONS.REGISTER_WITH_INVITATION_CODE, {
                    invitationCode: this.$route.params.invitationCode,
                    password,
                });
                await this.$router.push({ name: 'customer-dashboard' });
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (customerStore.getters[customerStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'customer-dashboard' });
        }

        if (configStore.state[configStore.STATE.CONFIG]?.customer?.closedRegistration === false) {
            // TODO: implement open registration
            // return next({ name: 'customer-registration' });
        }

        if (to.query['yes-error'] !== undefined) {
            return next(vm => {
                vm.$router.replace({ name: 'customer-invite' });
                const message = to.query['yes-error'] || 'Bei der Registrierung mit Ihrer Bank ist ein Fehler aufgetreten.';
                vm.$notify(message, 'negative', 'top', { timeout: 30000 });
            });
        }

        next();
    },
};
