import ApiClient from '@/api/_apiClient';

const FileTemplatesService = {
    getFileTemplates(search = '', page = 1, itemsPerPage = 10, orderBy = 'sortIndex', order = 'asc') {
        return ApiClient.get('file-templates', {
            params: {
                'search': search,
                'page': page,
                'items-per-page': itemsPerPage,
                [`order[${orderBy}]`]: order,
            },
        });
    },
    postFileTemplate(fileTemplate) {
        return ApiClient.post('file-templates', fileTemplate);
    },
    patchFileTemplate(fileTemplate) {
        return ApiClient.patch(`file-templates/${fileTemplate.id}`, fileTemplate);
    },
    deleteFileTemplate(fileTemplate) {
        return ApiClient.delete(`file-templates/${fileTemplate.id}`);
    },
};

export default FileTemplatesService;
