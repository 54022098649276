//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        to: {
            type: Object,
            default: () => undefined,
        },
    },
};
