//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DemoService from '@/api/demo.service';
import CreateDemoUserDialog from '@/components/demo/dialogs/CreateDemoUserDialog';
import InviteDemoUserDialog from '@/components/demo/dialogs/InviteDemoUserDialog';

export default {
    methods: {
        async createDemoCustomer() {
            this.$q.dialog({
                parent: this,
                component: CreateDemoUserDialog,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    const { data } = await DemoService.createDemoCustomer(payload);
                    this.$notify('Der Kunde wurde erfolgreich angelegt.', 'positive');
                    if (data.password) {
                        this.$notify(`Das Passwort des Kunden lautet: "${data.password}"`, 'accent', 'center', {
                            timeout: 60000,
                        });
                    }
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        async inviteDemoCustomer() {
            this.$q.dialog({
                parent: this,
                component: InviteDemoUserDialog,
            }).onOk(async (email) => {
                this.$q.loading.show();
                try {
                    const { data } = await DemoService.inviteDemoCustomer(email);
                    this.$notify('Der Kunde wurde erfolgreich eingeladen.', 'positive');
                    if (data.activationCode) {
                        this.$notify(`Der Aktivierungscode des Kunden lautet: "${data.activationCode}"`, 'accent', 'center', {
                            timeout: 60000,
                        });
                    }
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
    },
};
