//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import ValutierungService from '@/api/valutierung.service';
import CustomerService from '@/api/customer.service';
import configStore from '@/store/modules/configStore';
import ConfigService from '@/api/config.service';

export default {
    name: 'ProjectNew',
    data() {
        return {
            customerId: null,
            options: null,
            id: '',
            projectId: '',
            publicId: '',
            projectName: '',
            projectDescription: '',
            projectStatus: 'draft',
            projectCredits: [],
            minimumDisbursementAmount: 0,
            maxDisbursements: 0,
            financingProject: '',
        };
    },
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    methods: {
        addProject: async function () {
            const { data: customer } = await CustomerService.getCustomer(this.customerId);

            this.$q.loading.show();
            try {
                const { data: res } = await ValutierungService.postFinancingProject({
                    publicId: this.publicId,
                    name: this.projectName,
                    description: this.projectDescription,
                    status: 'draft',
                    customer: customer['@id'],
                    maxDisbursements: parseInt(this.maxDisbursements),
                    minimumDisbursementAmount: this.minimumDisbursementAmount,
                });
                this.projectId = res['@id'];
                await this.$router.push({ name: 'admin-valutierung-project-details', params: { vorhabenId: res.id } });
                this.$notify('Das Vorhaben wurde erfolgreich angelegt.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const { data: config } = await ConfigService.fetchCurrent();
            next(vm => {
                vm.customerId = to.params.id;
                vm.maxDisbursements = config.valutierung.defaultMaxDisbursements;
            });
        } catch (error) {
            next(vm => vm.$handleError(error));
        }
    },
};
