//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EnquiryStatus from '@/components/upload/EnquiryStatus';

export default {
    components: {
        EnquiryStatus,
    },
    props: {
        statusChange: {
            type: Object,
            required: true,
        },
        enquiry: {
            type: Object,
            required: true,
        },
    },
};
