//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: [String, Array],
            default: undefined,
        },
        template: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        label() {
            if (this.template.multiSelect) {
                return `${this.template.label} (Mehrfachauswahl)`;
            }

            return this.template.label;
        },
        rules() {
            if (this.template.required) {
                return this.template.multiSelect ? [v => v.length || 'Pflichtfeld'] : [v => !!v || 'Pflichtfeld'];
            }

            return [];
        },
    },
    methods: {
        parseValue(value) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return this.template?.multiSelect ? [] : '';
            }
        },
        input(value) {
            this.$emit('input', JSON.stringify(value));
        },
        validate() {
            return this.$refs.select.validate();
        },
        resetValidation() {
            return this.$refs.select.resetValidation();
        },
        focus() {
            this.$refs.select.focus();
        },
    },
};
