import _moduleHelper from '@/store/modules/_moduleHelper';
import EnquiryService from '@/api/enquiry.service';

export const NAMESPACE = 'ENQUIRY';

export const STATE = {
    PAGE_DATA: 'PAGE_DATA',
    CURRENT_ENQUIRY: 'CURRENT_ENQUIRY',
    CURRENT_ENQUIRY_STATUS_CHANGES: 'CURRENT_ENQUIRY_STATUS_CHANGES',
    CURRENT_ENQUIRY_FILE_STATUS_CHANGES: 'CURRENT_ENQUIRY_FILE_STATUS_CHANGES',
};

export const GETTERS = {
    CURRENT_ENQUIRY: 'CURRENT_ENQUIRY',
    FILES: 'FILES',
    FILES_WITH_STATUS: 'FILES_WITH_STATUS',
    FILES_BY_CATEGORY: 'FILE_BY_CATEGORY',
    FILES_WITH_STATUS_BY_CATEGORY: 'FILE_WITH_STATUS_BY_CATEGORY',
    LAST_ENQUIRY_STATUS_CHANGE: 'LAST_ENQUIRY_STATUS_CHANGE',
};

export const MUTATIONS = {
    UPDATE_PAGE_DATA: 'UPDATE_PAGE_DATA',
    RESET_PAGE_DATA: 'RESET_PAGE_DATA',
    SET_CURRENT_ENQUIRY: 'SET_CURRENT_ENQUIRY',
    RESET_CURRENT_ENQUIRY: 'RESET_CURRENT_ENQUIRY',
    SET_CURRENT_ENQUIRY_STATUS_CHANGES: 'SET_CURRENT_ENQUIRY_STATUS_CHANGES',
    RESET_CURRENT_ENQUIRY_STATUS_CHANGES: 'RESET_CURRENT_ENQUIRY_STATUS_CHANGES',
    SET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES: 'SET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES',
    RESET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES: 'RESET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES',
};

export const ACTIONS = {
    FETCH_PAGE_DATA: 'FETCH_PAGE',
    FETCH_ENQUIRY: 'FETCH_ENQUIRY',
    COMPLETE_ENQUIRY: 'COMPLETE_ENQUIRY',
    PATCH_CURRENT_ENQUIRY: 'PATCH_CURRENT_ENQUIRY',
    CREATE_ENQUIRY: 'CREATE_ENQUIRY',
    DOWNLOAD_FILES: 'DOWNLOAD_FILES',
    FETCH_ENQUIRY_STATUS_CHANGES: 'FETCH_ENQUIRY_STATUS_CHANGES',
    FETCH_ENQUIRY_FILE_STATUS_CHANGES: 'FETCH_ENQUIRY_FILE_STATUS_CHANGES',
    DELETE_ENQUIRY: 'DELETE_ENQUIRY',
};

export const MODULE = {
    namespaced: true,
    state: {
        [STATE.PAGE_DATA]: null,
        [STATE.CURRENT_ENQUIRY]: null,
        [STATE.CURRENT_ENQUIRY_STATUS_CHANGES]: null,
        [STATE.CURRENT_ENQUIRY_FILE_STATUS_CHANGES]: null,
    },
    getters: {
        [GETTERS.CURRENT_ENQUIRY](state) {
            return state[STATE.CURRENT_ENQUIRY];
        },
        [GETTERS.FILES](_state, getters) {
            return getters[GETTERS.CURRENT_ENQUIRY]?.requestedFiles || [];
        },
        [GETTERS.FILES_WITH_STATUS](_, getters) {
            return status => {
                if (!Array.isArray(status)) {
                    status = [status];
                }

                return getters[GETTERS.FILES].filter(file => status.indexOf(file.status) !== -1);
            };
        },
        [GETTERS.FILES_BY_CATEGORY](_, getters) {
            let categories = {};
            let filesWithoutCategories = [];

            getters[GETTERS.FILES].forEach(file => {
                if (file.fileCategory) {
                    categories[file.fileCategory.id] = categories[file.fileCategory.id] || {
                        name: file.fileCategory.name,
                        sortIndex: file.fileCategory.sortIndex,
                        files: [],
                    };
                    categories[file.fileCategory.id].files.push(file);
                } else {
                    filesWithoutCategories.push(file);
                }
            });

            categories = Object.values(categories).sort((a, b) => {
                return a.sortIndex === b.sortIndex ? 0 : (a.sortIndex > b.sortIndex ? 1 : -1);
            });

            if (filesWithoutCategories.length) {
                categories = [
                    ...categories,
                    {
                        name: categories.length ? 'Sonstige Dokumente' : 'Dokumente',
                        files: filesWithoutCategories,
                    },
                ];
            }

            return categories;
        },
        [GETTERS.FILES_WITH_STATUS_BY_CATEGORY](_, getters) {
            return status => {
                if (!Array.isArray(status)) {
                    status = [status];
                }

                const categories = getters[GETTERS.FILES_BY_CATEGORY];
                categories.forEach((_, index) => {
                    categories[index].files = categories[index].files.filter(file => status.indexOf(file.status) !== -1);
                });

                return categories.filter(category => category.files.length);
            };
        },
        [GETTERS.LAST_ENQUIRY_STATUS_CHANGE](state) {
            let fileStatusChanges = state[STATE.CURRENT_ENQUIRY_STATUS_CHANGES]?.['hydra:member'] || [];
            if (!fileStatusChanges.length) {
                return null;
            }

            return fileStatusChanges[0];
        },
    },
    mutations: {
        [MUTATIONS.UPDATE_PAGE_DATA](state, page) {
            state[STATE.PAGE_DATA] = Object.assign({}, page);
        },
        [MUTATIONS.RESET_PAGE_DATA](state) {
            state[STATE.PAGE_DATA] = null;
        },
        [MUTATIONS.SET_CURRENT_ENQUIRY](state, enquiry) {
            state[STATE.CURRENT_ENQUIRY] = Object.assign({}, enquiry);
        },
        [MUTATIONS.RESET_CURRENT_ENQUIRY](state) {
            state[STATE.CURRENT_ENQUIRY] = null;
        },
        [MUTATIONS.SET_CURRENT_ENQUIRY_STATUS_CHANGES](state, enquiryStatusChanges) {
            state[STATE.CURRENT_ENQUIRY_STATUS_CHANGES] = enquiryStatusChanges;
        },
        [MUTATIONS.RESET_CURRENT_ENQUIRY_STATUS_CHANGES](state) {
            state[STATE.CURRENT_ENQUIRY_STATUS_CHANGES] = null;
        },
        [MUTATIONS.SET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES](state, fileStatusChanges) {
            state[STATE.CURRENT_ENQUIRY_FILE_STATUS_CHANGES] = fileStatusChanges;
        },
        [MUTATIONS.RESET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES](state) {
            state[STATE.CURRENT_ENQUIRY_FILE_STATUS_CHANGES] = null;
        },
    },
    actions: {
        async [ACTIONS.FETCH_PAGE_DATA]({ commit }, params) {
            const { data } = await EnquiryService.fetchPage(params);
            commit(MUTATIONS.UPDATE_PAGE_DATA, data);

            return data;
        },
        async [ACTIONS.FETCH_ENQUIRY]({ commit }, id) {
            const { data } = await EnquiryService.fetchEnquiry(id);
            commit(MUTATIONS.SET_CURRENT_ENQUIRY, data);

            return data;
        },
        async [ACTIONS.COMPLETE_ENQUIRY]({ commit }, id) {
            const { data } = await EnquiryService.completeEnquiry(id);
            commit(MUTATIONS.SET_CURRENT_ENQUIRY, data);

            return data;
        },
        async [ACTIONS.CREATE_ENQUIRY]({ commit }, enquiry) {
            const { data } = await EnquiryService.createEnquiry(enquiry);
            commit(MUTATIONS.SET_CURRENT_ENQUIRY, data);

            return data;
        },
        async [ACTIONS.PATCH_CURRENT_ENQUIRY]({ commit }, { id, enquiry }) {
            const { data } = await EnquiryService.patchEnquiry(id, enquiry);
            commit(MUTATIONS.SET_CURRENT_ENQUIRY, data);

            return data;
        },
        async [ACTIONS.DOWNLOAD_FILES](_, id) {
            return await EnquiryService.download(id);
        },
        async [ACTIONS.FETCH_ENQUIRY_STATUS_CHANGES]({ commit }, id) {
            const { data } = await EnquiryService.fetchEnquiryStatusChanges(id);
            commit(MUTATIONS.SET_CURRENT_ENQUIRY_STATUS_CHANGES, data['hydra:member']);

            return data;
        },
        async [ACTIONS.FETCH_ENQUIRY_FILE_STATUS_CHANGES]({ commit }, id) {
            const { data } = await EnquiryService.fetchFileStatusChanges(id);
            commit(MUTATIONS.SET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES, data['hydra:member']);

            return data;
        },
        async [ACTIONS.DELETE_ENQUIRY](_, id){
            return await EnquiryService.deleteEnquiry(id);
        },
    },
};

export default {
    NAMESPACE,
    STATE,
    GETTERS,
    MUTATIONS,
    ACTIONS,
    MODULE,
    ..._moduleHelper(NAMESPACE),
};
