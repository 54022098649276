//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fileCategoriesStore from '@/store/modules/fileCategoriesStore';
import FileCategoryDialog from '@/components/upload/dialogs/FileCategoryDialog';
import FileCategoryDeleteDialog from '@/components/upload/dialogs/FileCategoryDeleteDialog';

export default {
    data() {
        return {
            loading: false,
            pagination: {
                sortBy: fileCategoriesStore.state[fileCategoriesStore.STATE.ORDER_BY],
                descending: fileCategoriesStore.state[fileCategoriesStore.STATE.ORDER] === 'desc',
                page: fileCategoriesStore.state[fileCategoriesStore.STATE.PAGE],
                rowsPerPage: fileCategoriesStore.state[fileCategoriesStore.STATE.ITEMS_PER_PAGE],
                rowsNumber: fileCategoriesStore.state[fileCategoriesStore.STATE.TOTAL_ITEMS],
            },
            columns: [
                { name: 'sortIndex', label: 'Sortierindex', field: 'sortIndex', sortable: true, align: 'left' },
                { name: 'name', label: 'Bezeichnung', field: 'name', sortable: true, align: 'left' },
                { name: 'actions', label: 'Aktionen', field: () => 'v-slot:body-cell-actions', align: 'left' },
            ],
        };
    },
    computed: {
        search: {
            get: () => fileCategoriesStore.state[fileCategoriesStore.STATE.SEARCH],
            set: (search) => fileCategoriesStore.commit(fileCategoriesStore.MUTATIONS.SET_SEARCH, search),
        },
        ...fileCategoriesStore.mapState({
            fileCategories: fileCategoriesStore.STATE.FILE_CATEGORIES,
        }),
    },
    mounted() {
        if (!this.pagination.rowsNumber) {
            this.updateTable(this.pagination);
        }
    },
    methods: {
        resetSearch() {
            this.search = '';
            this.updateTable(this.pagination);
        },
        rowClick(event, row) {
            this.edit(row);
        },
        async updateTable(pagination) {
            fileCategoriesStore.commit(fileCategoriesStore.MUTATIONS.SET_PAGE, pagination.page);
            fileCategoriesStore.commit(fileCategoriesStore.MUTATIONS.SET_ITEMS_PER_PAGE, pagination.rowsPerPage);
            fileCategoriesStore.commit(fileCategoriesStore.MUTATIONS.SET_ORDER_BY, pagination.sortBy);
            fileCategoriesStore.commit(fileCategoriesStore.MUTATIONS.SET_ORDER, pagination.descending ? 'desc' : 'asc');
            this.loading = true;
            await fileCategoriesStore.dispatch(fileCategoriesStore.ACTIONS.GET_FILE_CATEGORIES);
            this.loading = false;
            this.pagination = Object.assign({}, this.pagination, pagination);
            this.pagination.rowsNumber = fileCategoriesStore.state[fileCategoriesStore.STATE.TOTAL_ITEMS];
        },
        create() {
            this.$q.dialog({
                component: FileCategoryDialog,
                parent: this,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    await fileCategoriesStore.dispatch(fileCategoriesStore.ACTIONS.POST_FILE_CATEGORY, payload);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        edit(fileCategory) {
            this.$q.dialog({
                component: FileCategoryDialog,
                parent: this,
                value: fileCategory,
                type: 'edit',
            }).onOk(async (editedFileCategory) => {
                this.$q.loading.show();
                try {
                    await fileCategoriesStore.dispatch(fileCategoriesStore.ACTIONS.PATCH_FILE_CATEGORY, editedFileCategory);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        remove(fileCategory) {
            this.$q.dialog({
                component: FileCategoryDeleteDialog,
                parent: this,
                name: fileCategory.name,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await fileCategoriesStore.dispatch(fileCategoriesStore.ACTIONS.DELETE_FILE_CATEGORY, fileCategory);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
    },
};
