//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BankEmployeeService from '@/api/bankEmployee.service';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';

export default {
    data() {
        return {
            email: '',
            salutation: '',
            salutationOptions: [
                { value: 'NO_SALUTATION', label: 'Keine Anrede' },
                { value: 'MRS', label: 'Frau' },
                { value: 'MR', label: 'Herr' },
            ],
            availableRoles: [
                {
                    label: 'Instanz-Administrator',
                    value: 'ROLE_ADMIN',
                    hasRole: 'ROLE_ADMIN',
                },
                {
                    label: 'Demo-Administrator',
                    value: 'ROLE_DEMO_ADMIN',
                    hasRole: 'ROLE_ADMIN',
                },
                {
                    label: 'Bank-Administrator',
                    value: 'ROLE_BANK_ADMIN',
                    hasRole: 'ROLE_BANK_ADMIN',
                },
                /*
                {
                    label: 'Bank-Mitarbeiter',
                    value: 'ROLE_BANK_EMPLOYEE',
                    hasRole: 'ROLE_BANK_ADMIN',
                },
                */
                {
                    label: 'Bank-Mitarbeiter - Einreicher-Modul',
                    value: 'ROLE_BANK_EMPLOYEE_UPLOAD',
                    hasRole: 'ROLE_BANK_ADMIN',
                    hasModule: 'UPLOAD',
                },
                {
                    label: 'Bank-Mitarbeiter - Valutierungs-Modul',
                    value: 'ROLE_BANK_EMPLOYEE_VALUTIERUNG',
                    hasRole: 'ROLE_BANK_ADMIN',
                    hasModule: 'VALUTIERUNG',
                },
                {
                    label: 'Bank-Mitarbeiter - Selbstauskunft',
                    value: 'ROLE_BANK_EMPLOYEE_SELBSTAUSKUNFT',
                    hasRole: 'ROLE_BANK_ADMIN',
                    hasModule: 'SELBSTAUSKUNFT',
                },
            ],
            roles: [],
            firstName: '',
            lastName: '',
        };
    },
    computed: {
        roleValue() {
            return (role) => {
                return this.roles?.indexOf(role) !== -1;
            };
        },
        isRoleAdministrable() {
            return (config) => {
                let result = true;

                if (config.hasRole) {
                    result = result && this.hasRole(config.hasRole);
                }

                if (config.hasModule) {
                    result = result && this.hasModule(config.hasModule);
                }

                return result;
            };
        },
        hasModule() {
            // TODO
            // eslint-disable-next-line no-unused-vars
            return (module) => {
                return true;
            };
        },
        ...bankEmployeeStore.mapGetters({
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
    },
    methods: {
        toggleRole(val, role) {
            if (val) {
                this.roles.push(role);
            } else {
                this.roles = this.roles.filter(r => r !== role);
            }
        },
        async submit() {
            this.$q.loading.show();
            try {
                const { data: newEmployee } =  await BankEmployeeService.createBankEmployees({
                    email: this.email,
                    roles: this.roles,
                    salutation: this.salutation,
                    firstName: this.firstName,
                    lastName: this.lastName,
                });
                await this.$router.push({ name: 'admin-bank-employee-details', params: { id: newEmployee.id } });
                this.$notify('Der Bank-Mitarbeiter wurde erfolgreich angelegt.', 'positive');
            }  catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
};
