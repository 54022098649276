import { render, staticRenderFns } from "./BankEmployeeList.vue?vue&type=template&id=4a633c72&scoped=true&"
import script from "./BankEmployeeList.vue?vue&type=script&lang=js&"
export * from "./BankEmployeeList.vue?vue&type=script&lang=js&"
import style0 from "./BankEmployeeList.vue?vue&type=style&index=0&id=4a633c72&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a633c72",
  null
  
)

export default component.exports
import {QPage,QSpace,QBtn,QTooltip,QTable,QToggle,QInput,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QSpace,QBtn,QTooltip,QTable,QToggle,QInput,QIcon})
