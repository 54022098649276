//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileStatusChange from '@/components/upload/FileStatusChange';
import EnquiryStatusChange from '@/components/upload/EnquiryStatusChange';

export default {
    components: {
        EnquiryStatusChange,
        FileStatusChange,
    },
    props: {
        statusChanges: {
            type: Array,
            required: true,
        },
        enquiries: {
            type: Array,
            required: true,
        },
        files: {
            type: Array,
            required: true,
        },
    },
};
