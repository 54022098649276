//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';
import PasswordChangeDialog from '@/components/core/dialogs/PasswordChangeDialog';
import CustomerService from '@/api/customer.service';

export default {
    data() {
        return {
            showConfirmDeletionDialog: false,
            customerDTO: {},
        };
    },
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        ...customerStore.mapState({
            customer: customerStore.STATE.LOGGED_IN_CUSTOMER,
        }),
    },
    mounted() {
        this.customerDTO = { ...this.customer };
        this.customerDTO.salutation = this.customerDTO.salutation || 'NO_SALUTATION';
    },
    methods: {
        submit() {
            // TODO;
        },
        changePasswordDialog() {
            this.$q.dialog({
                parent: this,
                component: PasswordChangeDialog,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    await customerStore.dispatch(customerStore.ACTIONS.CHANGE_PASSWORD, payload);
                    this.$notify('Ihr Passwort wurde erfolgreich geändert.', 'positive');
                } catch (e) {
                    this.$handleError(e);
                }
                this.$q.loading.hide();
            });
        },
        async requestDeletion() {
            await CustomerService.requestDeletion();
            this.$notify('Der Account wurde gelöscht.', 'positive');
            await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
            await this.$router.push({ name: 'customer-login' });
        },
    },
};
