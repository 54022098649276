//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';
import EnquiryStatus from '@/components/upload/EnquiryStatus';
import IntroDialog from '@/components/upload/dialogs/IntroDialog';

export default {
    components: { EnquiryStatus },
    computed: {
        // TODO: this should be solved by the backend - not here
        filteredEnquiries() {
            return (this?.customer?.enquiries || []).filter(enq => ['draft', 'archived'].indexOf(enq.status) === -1);
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        ...customerStore.mapState({
            customer: customerStore.STATE.LOGGED_IN_CUSTOMER,
        }),
    },
    mounted() {
        if (this.$route.query.showIntro) {
            this.$q.dialog({
                component: IntroDialog,
                parent: this,
            }).onDismiss(() => {
                this.$router.replace({ name: 'customer-hub' });
            });
        }
    },
    async beforeRouteEnter(to, from, next) {
        try {
            await customerStore.dispatch(customerStore.ACTIONS.REFRESH_LOGGED_IN_CUSTOMER);
        } catch (e) {
            await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
            return next({ name: 'customer-login' });
        }
        next();
    },
};
