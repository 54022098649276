//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';

export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    methods: {
        input(value, { target }) {
            if (target.nodeName === 'A') {
                window.open(target.getAttribute('href'), target.getAttribute('target'));
            } else {
                this.$emit('input', value);
            }
        },
    },
};
