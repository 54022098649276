import store from '@/store';
import { createNamespacedHelpers } from 'vuex';

export default (namespace) => {
    return {
        state: new Proxy({}, {
            get(target, property) {
                if (store.state[namespace][property] !== undefined) {
                    return store.state[namespace][property];
                }
            },
        }),
        getters: new Proxy({}, {
            get(target, property) {
                if (store.getters[`${namespace}/${property}`] !== undefined) {
                    return store.getters[`${namespace}/${property}`];
                }
            },
        }),
        commit(mutation, payload = undefined, options = undefined) {
            return store.commit(`${namespace}/${mutation}`, payload, options);
        },
        dispatch(action, payload = undefined, options = undefined) {
            return store.dispatch(`${namespace}/${action}`, payload, options);
        },
        ...createNamespacedHelpers(namespace),
    };
};
