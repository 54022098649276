//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        template: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            expansionItemState: true,
        };
    },
    methods: {
        validate() {
            return true;
        },
        resetValidation() {
            // noop
        },
        focus() {
            // noop
        },
    },
};
