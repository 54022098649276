//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BankEmployeeService from '@/api/bankEmployee.service';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';

export default {
    data() {
        return {
            answer: 'NOA',
        };
    },
    async mounted() {
        try {
            await BankEmployeeService.confirmEmail(this.$route.params.code);
            await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
            this.$notify('Ihre E-Mail-Adresse wurde erfolgreich geändert. Bitte loggen Sie sich ein.', 'positive');
            this.$router.push({ name: 'admin-login' });
        } catch(e) {
            this.$handleError(e);
        }
    },
};
