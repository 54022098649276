import Vue from 'vue';

import './styles.scss';
import 'quasar/dist/quasar.ie.polyfills';
import iconSet from 'quasar/icon-set/fontawesome-v5-pro.js';
import lang from 'quasar/lang/de.js';
import '@quasar/extras/roboto-font/roboto-font.css';
import { Dialog, Loading, LoadingBar, Meta, Notify, Quasar } from 'quasar';
import _ from 'lodash';

Vue.use(Quasar, {
    config: {
        loading: {
            backgroundColor: 'white',
            delay: 0,
            spinner: null,
        },
        loadingBar: {
            color: 'accent',
            size: '5px',
            skipHijack: true,
        },
    },
    components: { /* not needed if importStrategy is not 'manual' */},
    directives: { /* not needed if importStrategy is not 'manual' */},
    plugins: {
        Dialog,
        Loading,
        LoadingBar,
        Meta,
        Notify,
    },
    lang: lang,
    iconSet: _.merge(
        iconSet,
        {
            uploader: {
                removeQueue: 'fal fa-times',
            },
        }
    ),
});
