//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import enquiryStore from '@/store/modules/enquiryStore';
import EnquiryDataForm from '@/components/upload/forms/EnquiryDataForm';
import FileDataForm from '@/components/upload/forms/FileDataForm';

/**
 * @deprecated?
 */
export default {
    components: {
        EnquiryDataForm,
        FileDataForm,
    },
    data() {
        return {
            activeTab: 0,
            enquiry: {
                requestedFiles: [{}],
            },
        };
    },
    methods: {
        addFile() {
            this.enquiry.requestedFiles.push({});
            this.activeTab = this.enquiry.requestedFiles.length;
        },
        deleteFile(index) {
            this.$q.dialog({
                title: 'Löschen',
                message: 'Wolle Sie die Datei wirklich löschen?',
                cancel: true,
                persistent: true,
            }).onOk(() => {
                this.enquiry.requestedFiles.splice(index, 1);
                this.activeTab = index;
            });
        },
        async validate() {
            let enquiryValid = await this.$refs['enquiry-data-form'].validate();
            if (!enquiryValid) {
                this.activeTab = 0;
                this.$refs['enquiry-data-form'].validate(true);

                return false;
            }

            let fileValidCollection = await Promise.all(this.$refs['file-data-form'].map(fileDataForm => fileDataForm.validate()));
            return fileValidCollection.every((valid, index) => {
                if (!valid) {
                    this.activeTab = index + 1;
                    this.$refs['file-data-form'][index].validate(true);
                }

                return valid;
            });
        },
        async create() {
            if (!await this.validate()) {
                return;
            }

            this.$q.loading.show();
            try {
                const enquiry = await enquiryStore.dispatch(enquiryStore.ACTIONS.CREATE_ENQUIRY, this.enquiry);
                this.$notify('Vorgang wurde erfolgreich erstellt.', 'positive');
                await this.$router.push({ name: 'admin-enquiry', params: { id: enquiry.id } });
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
    beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }
        next();
    },
};
