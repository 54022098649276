//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import EnquiryCreateDialog from '@/components/upload/dialogs/EnquiryCreateDialog';
import EnquiryService from '@/api/enquiry.service';

export default {
    computed: {
        ...bankEmployeeStore.mapState({
            loggedInBankEmployee: bankEmployeeStore.STATE.LOGGED_IN_BANK_EMPLOYEE,
        }),
        ...bankEmployeeStore.mapGetters({
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
    },
    beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }
        next();
    },
    methods: {
        createEnquiry() {
            this.$q.dialog({
                component: EnquiryCreateDialog,
                parent: this,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    let data;
                    if (payload.enquiryTemplate) {
                        const response = await EnquiryService.createByEnquiryTemplate(
                            payload.customer,
                            payload.enquiryTemplate,
                            payload.publicId,
                            'draft',
                            payload.overrideName
                        );
                        data = response.data;
                    } else {
                        const response = await EnquiryService.postEnquiry({
                            customer: payload.customer,
                            publicId: payload.publicId,
                            name: 'Neuer Vorgang',
                            status: 'draft',
                        });
                        data = response.data;
                    }
                    await this.$router.push({ name: 'admin-upload-enquiry', params: { id: data.id } });
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
    },
};
