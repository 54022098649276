//
//
//
//
//
//

export default {
    props: {
        customer: {
            type: Object,
            required: true,
        },
        admin: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        content() {
            if (this.customer.customerId) {
                return `${this.customer.customerId}: ${this.customer.email}`;
            }

            return this.customer.email;
        },
    },
    methods: {
        click() {
            if (!this.admin) {
                return;
            }

            this.$router.push({
                name: 'admin-customer-details',
                params: {
                    id: this.customer.id,
                },
            });
        },
    },
};
