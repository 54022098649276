//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';

export default {
    computed: {
        showLogo() {
            return !this.$route.meta?.hideLogo;
        },
        showMenu() {
            return !this.$route.meta?.hideMenu;
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
};
