import ApiClient from '@/api/_apiClient';

const ValutierungService = {
    getResourceByIri(iri) {
        return ApiClient.get(iri, {
            baseURL: '',
        });
    },
    getFinancingProjectById(id) {
        return ApiClient.get(`valutierung-financing-projects/${id}`);
    },
    patchFinancingProject(id, params) {
        return ApiClient.patch(`valutierung-financing-projects/${id}`, params);
    },
    postFinancingProject(params) {
        return ApiClient.post('valutierung-financing-projects', params);
    },
    getFinancingProjects(params = {}) {
        return ApiClient.get('valutierung-financing-projects', { params });
    },
    getFinancingProjectProgress(id) {
        return ApiClient.get(`valutierung-financing-projects/${id}/progress`);
    },
    getFinancingProjectTypes(params = {}) {
        return ApiClient.get('valutierung-financing-project-types', { params });
    },
    getCreditById(id) {
        return ApiClient.get(`valutierung-credits/${id}`);
    },
    deleteCredit(id) {
        return ApiClient.delete(`valutierung-credits/${id}`);
    },
    patchCredit(id, params) {
        return ApiClient.patch(`valutierung-credits/${id}`, params);
    },
    getCredits(params = {}) {
        return ApiClient.get('valutierung-credits', { params });
    },
    postCredit(params = {}) {
        return ApiClient.post('valutierung-credits',  params);
    },
    getCreditCategories(params = {}) {
        return ApiClient.get('valutierung-credit-categories', { params });
    },
    getDisbursements(params = {}) {
        return ApiClient.get('valutierung-disbursements', { params });
    },
    getDisbursementById(id) {
        return ApiClient.get(`valutierung-disbursements/${id}`);
    },
    getDisbursementProgressForTemplate(disbursementId, templateId) {
        return ApiClient.get(`valutierung-disbursements/${disbursementId}/progress`, {
            params: {
                'template-id': templateId,
            },
        });
    },
    requestSubmitForDisbursement(id, payload) {
        return ApiClient.post(`valutierung-disbursements/${id}/submit-request`, payload);
    },
    submitDisbursement(id, payload) {
        return ApiClient.post(`valutierung-disbursements/${id}/submit`, payload);
    },
    resubmitDisbursement(id) {
        return ApiClient.post(`valutierung-disbursements/${id}/resubmit`);
    },
    createDisbursement(financingProjectId, createDisbursementRequest) {
        return ApiClient.post(
            `valutierung-financing-projects/${financingProjectId}/create-disbursement`,
            createDisbursementRequest
        );
    },
    approveDisbursement(id, payload) {
        return ApiClient.post(`valutierung-disbursements/${id}/approve`, payload);
    },
    rejectDisbursement(id, payload) {
        return ApiClient.post(`valutierung-disbursements/${id}/reject`, payload);
    },
    inquireDisbursement(id, payload) {
        return ApiClient.post(`valutierung-disbursements/${id}/inquire`, payload);
    },
    patchDisbursement(id, payload) {
        return ApiClient.patch(`valutierung-disbursements/${id}`, payload);
    },
    deleteDisbursement(id) {
        return ApiClient.delete(`valutierung-disbursements/${id}`);
    },
    patchDisbursementPosition(id, payload) {
        return ApiClient.patch(`valutierung-disbursement-positions/${id}`, payload);
    },
    getDisbursementStatusChanges(params = {}) {
        return ApiClient.get('valutierung-disbursement-status-changes', { params });
    },
    getInputTemplates(params = {}) {
        return ApiClient.get('valutierung-input-templates', { params });
    },
    getInputValues(params = {}) {
        return ApiClient.get('valutierung-input-values', { params });
    },
    patchInputValue(id, payload) {
        return ApiClient.patch(`valutierung-input-values/${id}`, payload);
    },
    getDocumentTemplates(params = {}) {
        return ApiClient.get('valutierung-document-templates', { params });
    },
    getDocumentFiles(params = {}) {
        return ApiClient.get('valutierung-document-files', { params });
    },
    uploadDocumentFile(id, files, comment = null, onUploadProgress = null) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        if (!Array.isArray(files)) {
            files = [files];
        }

        const formData = new FormData();
        files.forEach(file => formData.append('file[]', file));

        if (comment) {
            formData.append('comment', comment);
        }

        return ApiClient.post(`valutierung-document-files/${id}/upload`, formData, {
            headers,
            onUploadProgress,
        });
    },
    downloadSummaryAsPdf(id) {
        return ApiClient.get(`valutierung-disbursements/${id}/summary-as-pdf`,  { responseType: 'blob' });
    },
    downloadCompleteDisbursementAsPdf(id) {
        return ApiClient.get(`valutierung-disbursements/${id}/complete-disbursement-as-pdf`,  { responseType: 'blob' });
    },
};

export default ValutierungService;
