//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ValutierungService from '@/api/valutierung.service';
import DisbursementDocument from '@/components/valutierung/DisbursementDocument';
import DisbursementInput from '@/components/valutierung/DisbursementInput';
import SubmitDisbursementDialog from '@/components/valutierung/dialogs/SubmitDisbursementDialog';
import DisbursementStatusBadge from '@/components/valutierung/DisbursementStatusBadge';
import configStore from '@/store/modules/configStore';

export default {
    components: {
        DisbursementStatusBadge,
        DisbursementDocument,
        DisbursementInput,
    },
    data() {
        return {
            tab: 'eckdaten',
            tabs: ['eckdaten', 'progress', 'nachweise', 'summary'],
            disbursement: {},
            disbursementStatusChanges: [],
            inputValues: [],
            documentFiles: [],
            progressElements: [],
            formElements: [],
            summaryElements: [],
        };
    },
    computed: {
        readonly() {
            return ['draft', 'problem'].indexOf(this.disbursement.status) === -1;
        },
        problem() {
            return this.disbursement.status === 'problem';
        },
        lastStatusChangeMessage() {
            return this.disbursementStatusChanges[0]?.context?.message ?? '';
        },
        infoForPosition() {
            return (index) => {
                let info = [];

                const position = this.disbursement.disbursementPositions[index];

                if (position.financingProjectType?.infotext) {
                    info.push(
                        `**Hinweis zu einer Auszahlung mit dem Zweck "${position.financingProjectType.name}":**\n\n` +
                            position.financingProjectType.infotext
                    );
                }

                if (position.credit?.category?.infotext) {
                    info.push(
                        `**Hinweis zu einer Auszahlung aus einem Kredit der Kategorie "${position.credit.category.name}":**\n\n` +
                            position.credit.category.infotext
                    );
                }

                return info.join('\n\n');
            };
        },
        minimumDisbursementAmount() {
            if (this.disbursement.financingProject.minimumDisbursementAmount) {
                return this.disbursement.financingProject.minimumDisbursementAmount;
            }

            return this.config.valutierung.defaultMinimumDisbursementAmount ?? 500000;
        },
        minimumDisbursementAmountByPosition() {
            return (position) => {
                if (this.correctedRemainingAmountByPosition(position) < this.minimumDisbursementAmount) {
                    return this.correctedRemainingAmountByPosition(position);
                }

                return this.minimumDisbursementAmount;
            };
        },
        correctedRemainingAmountByPosition() {
            return (position) => position.credit.remainingAmount + position.originalAmount;
        },
        isFinalDisbursement() {
            return this.disbursement.financingProject.remainingDisbursements <= 0;
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    methods: {
        getTabButtonColor(tab) {
            if (tab === this.tab) {
                return 'accent';
            }

            if (this.tabs.indexOf(tab) > this.tabs.indexOf(this.tab)) {
                return 'grey-2';
            }

            return 'grey-8';
        },
        getTabButtonTextColor(tab) {
            if (tab === this.tab) {
                return 'accent-invert';
            }

            if (this.tabs.indexOf(tab) > this.tabs.indexOf(this.tab)) {
                return 'grey-8';
            }

            return 'grey-2';
        },
        saveOriginalAmount() {
            this.disbursement.disbursementPositions.forEach(position => {
                position.originalAmount = position.amount;
            });
        },
        async navigateBack() {
            this.$q.loading.show();
            await this.patchFormElements();
            this.$q.loading.hide();
            await this.$router.push({
                name: 'customer-financing-project',
                params: {
                    id: this.$route.params.financingProjectId,
                },
            });
        },
        navigateToTab(tab) {
            this.patchFormElements();
            this.tab = tab;
            this.scrollToTop();
        },
        previousTab() {
            this.patchFormElements();
            this.$refs.tab.previous();
            this.scrollToTop();
        },
        nextTab() {
            this.patchFormElements();
            this.$refs.tab.next();
            this.scrollToTop();
        },
        buildFormElements() {
            this.formElements = [];

            this.inputValues.forEach(inputValue => {
                if (inputValue.inputTemplate.type !== 'progress' && inputValue.inputTemplate.showWithDocuments) {
                    this.formElements.push({
                        type: 'input',
                        sortIndex: inputValue.inputTemplate.sortIndex,
                        inputValue: inputValue,
                        inputTemplate: inputValue.inputTemplate,
                    });
                }
            });

            this.documentFiles.forEach(documentFile => {
                this.formElements.push({
                    type: 'document',
                    sortIndex: documentFile.documentTemplate.sortIndex,
                    documentFile: documentFile,
                    documentTemplate: documentFile.documentTemplate,
                });
            });

            this.formElements.sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1);

            this.buildProgressElements();
            this.buildSummaryElements();
        },
        buildProgressElements() {
            this.progressElements = [];

            this.inputValues.forEach(inputValue => {
                if (inputValue.inputTemplate.type === 'progress') {
                    this.progressElements.push({
                        type: 'input',
                        sortIndex: inputValue.inputTemplate.sortIndex,
                        inputValue: inputValue,
                        inputTemplate: inputValue.inputTemplate,
                    });
                }
            });

            this.progressElements.sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1);
        },
        buildSummaryElements() {
            this.summaryElements = [];

            this.inputValues.forEach(inputValue => {
                if (inputValue.inputTemplate.type !== 'progress' && !inputValue.inputTemplate.showWithDocuments) {
                    this.summaryElements.push({
                        type: 'input',
                        sortIndex: inputValue.inputTemplate.sortIndex,
                        inputValue: inputValue,
                        inputTemplate: inputValue.inputTemplate,
                    });
                }
            });

            this.summaryElements.sort((a, b) => a.sortIndex > b.sortIndex ? 1 : -1);
        },
        async submit() {
            if (!await this.validate()) {
                return this.patchFormElements();
            }

            if (this.problem) {
                return this.resubmit();
            }

            this.$q.loading.show();
            try {
                await this.patchFormElements();
                await ValutierungService.requestSubmitForDisbursement(this.disbursement.id);
                this.$q.dialog({
                    parent: this,
                    component: SubmitDisbursementDialog,
                }).onOk(async ({ submitCode }) => {
                    this.$q.loading.show();
                    try {
                        await ValutierungService.submitDisbursement(
                            this.disbursement.id,
                            { submitCode }
                        );
                        await this.refreshDisbursement();
                        await this.$router.push({
                            name: 'customer-financing-project',
                            params: {
                                id: this.$route.params.financingProjectId,
                            },
                        });
                        this.$notify('Ihr Auszahlungsantrag wurde erfolgreich übermittelt.', 'positive', 'center');
                    } catch (error) {
                        this.$handleError(error);
                    }
                    this.$q.loading.hide();
                });
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async resubmit() {
            this.$q.loading.show();
            try {
                await this.patchFormElements();
                await ValutierungService.resubmitDisbursement(this.disbursement.id);
                await this.refreshDisbursement();
                await this.$router.push({
                    name: 'customer-financing-project',
                    params: {
                        id: this.$route.params.financingProjectId,
                    },
                });
                this.$notify('Ihr Auszahlungsantrag wurde erneut übermittelt.', 'positive', 'center');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async validate() {
            const currentTab = this.tab;

            if (this.$refs.eckdatenForm === undefined) {
                this.$refs.tab.goTo('eckdaten');
                await new Promise(resolve => {
                    let interval = setInterval(() => {
                        if (this.$refs.eckdatenForm) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 10);
                });
            }
            if (!await this.$refs.eckdatenForm.validate()) {
                this.$refs.tab.goTo('eckdaten');
                this.$refs.eckdatenForm.resetValidation();
                this.$nextTick(this.$refs.eckdatenForm.validate);
                return false;
            }

            if (this.progressElements.length) {
                if (this.$refs.progressForm === undefined) {
                    this.$refs.tab.goTo('progress');
                    await new Promise(resolve => {
                        let interval = setInterval(() => {
                            if (this.$refs.progressForm) {
                                clearInterval(interval);
                                resolve();
                            }
                        }, 10);
                    });
                }
                if (!await this.$refs.progressForm.validate()) {
                    this.$refs.tab.goTo('progress');
                    this.$refs.progressForm.resetValidation();
                    this.$nextTick(this.$refs.progressForm.validate);
                    return false;
                }
            }

            if (this.formElements.length) {
                if (this.$refs.nachweiseForm === undefined) {
                    this.$refs.tab.goTo('nachweise');
                    await new Promise(resolve => {
                        let interval = setInterval(() => {
                            if (this.$refs.nachweiseForm) {
                                clearInterval(interval);
                                resolve();
                            }
                        }, 10);
                    });
                }
                if (!await this.$refs.nachweiseForm.validate()) {
                    this.$refs.tab.goTo('nachweise');
                    this.$refs.nachweiseForm.resetValidation();
                    this.$nextTick(this.$refs.nachweiseForm.validate);
                    return false;
                }
            }

            if (this.summaryElements.length) {
                if (this.$refs.summaryForm === undefined) {
                    this.$refs.tab.goTo('summary');
                    await new Promise(resolve => {
                        let interval = setInterval(() => {
                            if (this.$refs.summaryForm) {
                                clearInterval(interval);
                                resolve();
                            }
                        }, 10);
                    });
                }
                if (!await this.$refs.summaryForm.validate()) {
                    this.$refs.tab.goTo('summary');
                    this.$refs.summaryForm.resetValidation();
                    this.$nextTick(this.$refs.summaryForm.validate);
                    return false;
                }
            }

            this.$refs.tab.goTo(currentTab);

            return true;
        },
        async patchFormElements() {
            if (this.readonly) {
                return;
            }

            const ajaxRequests = [];

            ajaxRequests.push(ValutierungService.patchDisbursement(
                this.disbursement.id,
                {
                    name: this.disbursement.name,
                    amount: this.disbursement.amount,
                }
            ));

            this.disbursement.disbursementPositions.forEach(position => {
                ajaxRequests.push(ValutierungService.patchDisbursementPosition(
                    position.id,
                    {
                        amount: position.amount,
                    }
                ));
            });

            this.progressElements.forEach(progressElement => {
                if (progressElement.type === 'input') {
                    ajaxRequests.push(ValutierungService.patchInputValue(progressElement.inputValue.id, {
                        value: progressElement.inputValue.value,
                    }));
                }
            });

            this.formElements.forEach(formElement => {
                if (formElement.type === 'input') {
                    ajaxRequests.push(ValutierungService.patchInputValue(formElement.inputValue.id, {
                        value: formElement.inputValue.value,
                    }));
                }
            });

            this.summaryElements.forEach(summaryElement => {
                if (summaryElement.type === 'input') {
                    ajaxRequests.push(ValutierungService.patchInputValue(summaryElement.inputValue.id, {
                        value: summaryElement.inputValue.value,
                    }));
                }
            });

            if (this.$refs.documents) {
                this.$refs.documents.forEach(document => ajaxRequests.push(document.saveComment()));
            }

            await Promise.all(ajaxRequests);
        },
        async refreshDisbursement() {
            const { data: disbursement } = await ValutierungService.getDisbursementById(this.$route.params.disbursementId);
            const [
                { data: disbursementStatusChangesPageData },
                { data: inputValuesPageData },
                { data: documentFilesPageData },
            ] = await Promise.all([
                ValutierungService.getDisbursementStatusChanges({ disbursement: disbursement.id }),
                ValutierungService.getInputValues({ disbursement: disbursement.id }),
                ValutierungService.getDocumentFiles({ disbursement: disbursement.id }),
            ]);

            this.disbursement = disbursement;
            this.disbursementStatusChanges = disbursementStatusChangesPageData['hydra:member'];
            this.inputValues = inputValuesPageData['hydra:member'];
            this.documentFiles = documentFilesPageData['hydra:member'];
            this.saveOriginalAmount();
            this.buildFormElements();
        },
        scrollToTop() {
            if (window.document.body.scrollIntoView) {
                window.document.body.scrollIntoView();
            }
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const { data: disbursement } = await ValutierungService.getDisbursementById(to.params.disbursementId);
            const [
                { data: disbursementStatusChangesPageData },
                { data: inputValuesPageData },
                { data: documentFilesPageData },
            ] = await Promise.all([
                ValutierungService.getDisbursementStatusChanges({ disbursement: disbursement.id }),
                ValutierungService.getInputValues({ disbursement: disbursement.id }),
                ValutierungService.getDocumentFiles({ disbursement: disbursement.id }),
            ]);

            next(vm => {
                vm.disbursement = disbursement;
                vm.disbursementStatusChanges = disbursementStatusChangesPageData['hydra:member'];
                vm.inputValues = inputValuesPageData['hydra:member'];
                vm.documentFiles = documentFilesPageData['hydra:member'];
                vm.saveOriginalAmount();
                vm.buildFormElements();
                if (to.query.next !== undefined) {
                    vm.$nextTick(() => {
                        vm.$router.replace({
                            ...to,
                            query: null,
                        });
                        vm.$refs.tab.next();
                    });
                }
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
