//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import customerStore from '@/store/modules/customerStore';
import configStore from '@/store/modules/configStore';
import PasswordResetDialog from '@/components/core/dialogs/PasswordResetDialog';

export default {
    data() {
        return {
            email: '',
            password: '',
        };
    },
    methods: {
        async submit() {
            this.$q.loading.show();
            try {
                await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGIN, {
                    email: this.email,
                    password: this.password,
                });
                await configStore.dispatch(configStore.ACTIONS.FETCH_CURRENT);
                await this.$router.push({ name: 'admin-dashboard' });
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        passwordReset() {
            this.$q.dialog({
                parent: this,
                component: PasswordResetDialog,
            }).onOk(async (email) => {
                this.$q.loading.show();
                try {
                    await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.REQUEST_PASSWORD_RESET, email);
                    this.$notify(
                        `Sofern Ihr Account mit der E-Mail-Adresse "${email}" besteht, finden Sie nun in Ihrem Postfach
                         eine E-Mail mit der Sie Ihr Passwort zurücksetzen können.`,
                        'positive',
                        'top',
                        { timeout: 10000 }
                    );
                } catch (e) {
                    this.$handleError(e);
                }
                this.$q.loading.hide();
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        if (bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-dashboard' });
        }
        next();
    },
};
