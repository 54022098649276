//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Loading } from 'quasar';
import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';
import CustomerService from '@/api/customer.service';

export default {
    computed: {
        ...customerStore.mapState({
            loggedInCustomer: customerStore.STATE.LOGGED_IN_CUSTOMER,
        }),
        ...customerStore.mapGetters({
            hasRole: customerStore.GETTERS.HAS_ROLE,
        }),
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        activateEnquiryAttachments() {
            return this.config?.upload?.activateEnquiryAttachments;
        },
    },
    methods: {
        loginSelbstauskunft() {
            const win = window.open('', '_blank');
            win.document.write('Eine Sekunde Geduld bitte...');
            (async () => {
                Loading.show();
                try {
                    const { data } = await CustomerService.getLoginForSelbstauskunft();
                    win.location.href = data['href'];
                } catch (error) {
                    this.$handleError(error);
                }
                Loading.hide();
            })();
        },
        // if there is only one document, we go directly to it
        visitCustomerHubOrSingleDocument() {
            const enqs = (this?.loggedInCustomer?.enquiries || []).filter(enq => enq.status !== 'draft');
            if (enqs.length == 1) {
                this.$router.push({ name: 'customer-enquiry', params: { id: enqs[0].id } });
            } else {
                this.$router.push({ name: 'customer-hub' });
            }
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            await customerStore.dispatch(customerStore.ACTIONS.REFRESH_LOGGED_IN_CUSTOMER);
        } catch (e) {
            await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
            return next({ name: 'customer-login' });
        }

        // no ROLE_CUSTOMER means account was not activated yet
        if (!customerStore.getters[customerStore.GETTERS.HAS_ROLE]('ROLE_CUSTOMER')) {
            return next({ name: 'customer-activation' });
        }

        next();
    },
};
