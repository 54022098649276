//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date } from 'quasar';
import CustomerService from '@/api/customer.service';
import enquiryService from '@/api/enquiry.service';
import enquiryStore from '@/store/modules/enquiryStore';
import CustomerInviteFirst from '../CustomerInviteFirst';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';

export default {
    name: 'CustomerEnquiryDetails',
    data() {
        return {
            statusMessage: '',
            enquiry: null,
            checkExpiresAt: false,
            customerId: '',
            editableEnquiry: false,
            editEnquiry: false,
            columns: [
                {
                    name: 'name', label: 'Name', field: 'name', sortable: true, style: 'white-space: normal',
                },
                {
                    name: 'description',
                    label: 'Kreditbeschreibung',
                    field: 'description',
                    sortable: true,
                    style: 'white-space: normal',
                },
                {
                    name: 'status',
                    label: 'Dokumentenstatus',
                    field: 'status',
                    sortable: true,
                },
                {
                    name: 'createdAt',
                    label: 'Erstellt am',
                    field: 'createdAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
                {
                    name: 'updatedAt',
                    label: 'Bearbeitet am',
                    field: 'updatedAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
            ],
        };
    },
    computed: {
        computedColumns() {
            const computedColumns = this.columns;
            computedColumns.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });

            return computedColumns;
        },
    },
    methods: {
        edit() {
            this.editEnquiry = true;
        },
        async patchEnquiry() {
            this.$q.loading.show();
            try {
                if (!this.checkExpiresAt) {
                    this.enquiry.expiresAt = null;
                }
                await enquiryStore.dispatch(enquiryStore.ACTIONS.PATCH_CURRENT_ENQUIRY, {
                    id: this.enquiry.id, enquiry:
                        {
                            name:
                                this.enquiry.name,
                            publicId:
                                this.enquiry.publicId,
                            description:
                                this.enquiry.description,
                            expiresAt: this.enquiry.expiresAt,
                        },
                });
                await this.refresh();
                this.$notify('Der Vorgang wurde erfolgreich bearbeitet.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            this.editEnquiry = false;
            this.$q.loading.hide();
        },
        async changeStatus(status) {
            this.$q.loading.show();
            try {
                await enquiryStore.dispatch(enquiryStore.ACTIONS.PATCH_CURRENT_ENQUIRY, {
                    id: this.enquiry.id, enquiry: {
                        status: status,
                        __context: {
                            message: this.statusMessage,
                        },
                    },
                });
                await this.refresh();
                this.$notify('Der Vorgang wurde erfolgreich freigegeben.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
        async checkCustomerInvited() {
            const { data: customer } = await CustomerService.getCustomer(this.customerId);
            if (!customer.invitedAt && !customer.activatedAt) {
                this.$q.dialog({
                    parent: this,
                    component: CustomerInviteFirst,
                }).onOk(() => {
                    this.changeStatus('open');
                });
            } else {
                await this.changeStatus('open');
            }
        },
        async deleteEnquiry() {
            this.$q.loading.show();
            try {
                await enquiryStore.dispatch(enquiryStore.ACTIONS.DELETE_ENQUIRY, this.enquiry.id);
                await this.$router.push({ name: 'admin-customer-details' });
                this.$notify('Der Vorgang wurde erfolgreich gelöscht.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
        cancel() {
            this.editEnquiry = false;
        },
        rowClick(event, row) {
            this.$router.push({ name: 'admin-enquiry-document-details', params: { fileId: row.id } });
        },
        async refresh() {
            let { data: enquiry } = await enquiryService.fetchEnquiry(this.enquiry.id);
            this.enquiry = enquiry;
            this.enquiry.expiresAt = date.formatDate(enquiry.expiresAt, 'DD.MM.YYYY');
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }
        try {
            let { data: enquiry } = await enquiryService.fetchEnquiry(to.params.enquiryId);
            let check = false;
            if (enquiry.expiresAt) {
                check = true;
                enquiry.expiresAt = date.formatDate(enquiry.expiresAt, 'DD.MM.YYYY');
            }
            next(vm => {
                vm.enquiry = enquiry;
                vm.checkExpiresAt = check;
                vm.customerId = to.params.id;
            });
        } catch (error) {
            next(vm => vm.$handleError(error));
        }
    },
};
