//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    methods: {
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        validate(focus = false) {
            return this.$refs.form.validate(focus);
        },
    },
};
