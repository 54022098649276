//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileStatus from '@/components/upload/FileStatus';

export default {
    components: {
        FileStatus,
    },
    props: {
        statusChange: {
            type: Object,
            required: true,
        },
        file: {
            type: Object,
            required: true,
        },
    },
};
