import axios from 'axios';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import customerStore from '@/store/modules/customerStore';

const ApiClient = axios.create({
    baseURL: '/api/',
    headers: {
        Accept: 'application/ld+json, application/problem+json, application/json',
    },
});

ApiClient.interceptors.request.use(config => {
    const bankEmployeeToken = bankEmployeeStore.state[bankEmployeeStore.STATE.TOKEN];
    if (bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN] && bankEmployeeToken) {
        config.headers['X-Bank-Employee-Token'] = bankEmployeeToken;
    }

    const customerToken = customerStore.state[customerStore.STATE.TOKEN];
    if (customerStore.getters[customerStore.GETTERS.IS_LOGGED_IN] && customerToken) {
        config.headers['X-Customer-Token'] = customerToken;
    }

    return config;
});

/*
  I leave this here, it helped debugging.
  ApiClient.interceptors.request.use(request => {
    console.log('Starting Request', JSON.stringify(request, null, 2));
    return request;
  });

  ApiClient.interceptors.response.use(response => {
    console.log('Response:', JSON.stringify(response, null, 2));
    return response;
  });
  */
export default ApiClient;
