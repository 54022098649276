//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { format } from 'quasar';
import FileProblemDialog from '@/components/upload/dialogs/FileProblemDialog';
import FilePreviewDialog from '@/components/upload/dialogs/FilePreviewDialog';

export default {
    props: {
        file: {
            type: Object,
            required: true,
        },
        lastFileStatusChange: {
            type: Object,
            default: null,
        },
        accept: {
            type: String,
            default: 'image/*, application/pdf',
        },
        maxFileSize: {
            type: Number,
            default: 64 * 1024 * 1024,
        },
        maxTotalSize: {
            type: Number,
            default: 64 * 1024 * 1024,
        },
        enquiry: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            files: null,
            uploading: false,
            error: false,
            fileProgresses: {},
            uploadAgain: false,
        };
    },
    computed: {
        config() {
            let config = {
                disabled: false,
                text: 'Foto erstellen oder Datei auswählen',
                comment: '',
                message: '',
                icon: 'fal fa-upload',
                iconColor: 'primary',
                fileSizeHint: !!this.file.maxSize,
                uploadButton: true,
                problemButton: true,
                unproblemButton: false,
                correctButton: false,
                cancelButton: false,
                readyButton: false,
            };

            if (this.file.status === 'uploaded' && !this.uploadAgain) {
                config.disabled = true;
                config.text =
                    'Ihre Datei(en) wurde(n) **erfolgreich** zu **einem PDF-Dokument** zusammengefasst.\n\n' +
                    'Sie können das PDF-Dokument über die **Vorschau** ansehen, prüfen und ggfs. korrigieren.\n\n' +
                    '**Geben** Sie danach das Dokument zur weiteren Verarbeitung durch die Bank **frei**.'
                ;
                config.icon = 'fas fa-file-pdf';
                config.iconColor = 'primary';
                config.fileSizeHint = false;
                config.uploadButton = false;
                config.problemButton = false;
                config.correctButton = true;
                config.cancelButton = false;
                config.readyButton = true;
            }

            if (this.file.status === 'uploaded' && this.uploadAgain) {
                config.cancelButton = true;
            }

            if (this.file.status === 'problem') {
                config.disabled = true;
                config.text = 'Sachverhalt wird geprüft';
                config.message = this.lastFileStatusChange?.context?.message;
                config.comment = this.lastFileStatusChange?.context?.comment;
                config.icon = 'fas fa-question-circle';
                config.iconColor = 'primary';
                config.fileSizeHint = false;
                config.uploadButton = false;
                config.problemButton = false;
                config.unproblemButton = this.enquiry.status === 'open';
            }

            if (this.file.status === 'ready') {
                config.disabled = true;
                config.text = 'Datei wurde zur Überprüfung freigegeben';
                config.icon = 'fas fa-check-circle';
                config.iconColor = 'primary';
                config.fileSizeHint = false;
                config.uploadButton = false;
                config.problemButton = false;
            }

            if (['verified', 'processed', 'archived'].indexOf(this.file.status) !== -1) {
                config.disabled = true;
                config.text = 'Datei wurde erfolgreich geprüft';
                config.icon = 'fas fa-check-circle';
                config.iconColor = 'positive';
                config.fileSizeHint = false;
                config.uploadButton = false;
                config.problemButton = false;
            }

            return config;
        },
        context() {
            let message = this.lastFileStatusChange?.context?.message;
            let comment = this.lastFileStatusChange?.context?.comment;

            return {
                show: message && ['customer', 'access_token'].indexOf(this.lastFileStatusChange?.initiatedBy) === -1,
                message,
                comment,
                colorClass: this.file.status === 'requested' ? 'text-negative' : 'text-primary',
            };
        },
        computedAccept() {
            return this.file.accept || this.accept;
        },
        computedMaxFileSize() {
            return this.file.maxSize && this.file.maxSize <= this.maxFileSize ? this.file.maxSize : this.maxFileSize;
        },
    },
    methods: {
        // TODO @deprecated
        isValid() {
            return !this.file.required || this.file.status === 'uploaded';
        },
        input() {
            this.error = false;
            this.fileProgresses = {};
        },
        removeFile(index) {
            this.$refs.input.removeAtIndex(index);
        },
        rejected(rejectedEntries) {
            rejectedEntries.forEach(item => {
                switch (item.failedPropValidation) {
                    case 'max-file-size':
                        this.$notify(`Die Datei "${item.file.name}" ist mit ${format.humanStorageSize(item.file.size)} größer als die erlaubten ${format.humanStorageSize(this.computedMaxFileSize)}.`, 'warning');
                        break;
                    case 'accept':
                        this.$notify(`Die Datei "${item.file.name}" entspricht nicht den Dateityp-Anforderungen (${this.computedAccept}).`, 'warning');
                        break;
                    case 'max-total-size':
                        this.$notify(`Der gesamte Upload darf nicht größer sein als ${format.humanStorageSize(this.maxTotalSize)}. Die Datei "${item.file.name}" wurde daher nicht hinzugefügt.`, 'warning');
                        break;
                    default:
                        this.$notify(`Die Datei "${item.file.name}" entspricht nicht den Anforderungen.`, 'warning');
                        break;
                }
            });
        },
        preview() {
            this.$q.dialog({
                parent: this,
                component: FilePreviewDialog,
                file: this.file,
            });
        },
        upload() {
            this.uploading = true;
            this.error = false;
            this.$emit('upload', this.files);
        },
        progress(event) {
            let bytesLoaded = event.loaded;
            this.files.forEach((file, index) => {
                this.$set(this.fileProgresses, index, Math.max(bytesLoaded / file.size, 0.0));
                bytesLoaded -= file.size;
            });
        },
        completeUpload() {
            this.files = null;
            this.uploading = false;
            this.fileProgresses = {};
            this.uploadAgain = false;
        },
        uploadError() {
            this.uploading = false;
            this.error = true;
        },
        ready() {
            this.$emit('ready');
        },
        problem() {
            this.$q.dialog({
                component: FileProblemDialog,
                parent: this,
            }).onOk((payload) => {
                if (payload.message) {
                    this.$emit('problem', payload);
                }
            });
        },
        unproblem() {
            this.$emit('unproblem', {});
            // this.$q.dialog({
            //     component: FileUnproblemDialog,
            //     parent: this,
            // }).onOk((payload) => {
            //     this.$emit('unproblem', payload);
            // });
        },
        triggerInput(event) {
            this.$refs.input.pickFiles(event);
        },
    },
};
