//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date } from 'quasar';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import enquiryStore from '@/store/modules/enquiryStore';
import EnquiryStatus from '@/components/upload/EnquiryStatus';
import EnquiryCreateDialog from '@/components/upload/dialogs/EnquiryCreateDialog';
import enquiryStatusConfig from '@/config/enquiryStatus.config';
import EnquiryService from '@/api/enquiry.service';
import * as _ from 'lodash';

export default {
    components: { EnquiryStatus },
    filters: {
        enquiryStatus(value) {
            return enquiryStatusConfig[value]?.text ?? value;
        },
    },
    data() {
        return {
            tableConfig: _.cloneDeep(bankEmployeeStore.state[bankEmployeeStore.STATE.ENQUIRIES_TABLE_CONFIG]),
            columns: [
                { name: 'publicId', label: 'Vorgangsnummer', field: 'publicId', sortable: true },
                { name: 'name', label: 'Bezeichnung', field: 'name', sortable: true },
                { name: 'customer.firstName', label: 'Vorname', field: row => row.customer?.firstName, sortable: true },
                { name: 'customer.lastName', label: 'Nachname', field: row => row.customer?.lastName, sortable: true },
                { name: 'status', label: 'Status', field: 'status', sortable: true },
                {
                    name: 'createdAt',
                    label: 'Erstellt am',
                    field: 'createdAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
            ],
        };
    },
    computed: {
        computedColumns() {
            const computedColumns = this.columns;
            computedColumns.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name === this.tableConfig.pagination.sortBy)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });

            return computedColumns;
        },
        filteredEnquiryStatusConfig() {
            if (!this.tableConfig.showArchived) {
                let filteredEnquiryStatusConfig = _.cloneDeep(enquiryStatusConfig);
                delete filteredEnquiryStatusConfig['archived'];

                return filteredEnquiryStatusConfig;
            }

            return enquiryStatusConfig;
        },
        ...enquiryStore.mapState({
            pageData: enquiryStore.STATE.PAGE_DATA,
        }),
    },
    watch: {
        tableConfig: {
            deep: true,
            handler(tableConfig) {
                bankEmployeeStore.commit(bankEmployeeStore.MUTATIONS.SET_ENQUIRIES_TABLE_CONFIG, tableConfig);
            },
        },
    },
    mounted() {
        this.updateTable({ pagination: this.tableConfig.pagination });
    },
    methods: {
        async refresh() {
            await this.updateTable({ pagination: this.tableConfig.pagination }, true);
            this.$notify('Vorgangsübersicht wurde aktualisiert.');
        },
        async updateTable(props, resetPage = false) {
            this.$q.loading.show();
            try {
                const params = {
                    'items-per-page': props.pagination.rowsPerPage,
                    'page': props.pagination.page,
                };

                if (props.pagination.sortBy) {
                    params[`order[${props.pagination.sortBy}]`] = props.pagination.descending ? 'desc' : 'asc';
                }

                if (this.tableConfig.search) {
                    params['search'] = this.tableConfig.search;
                }

                if (this.tableConfig.statusFilter?.length) {
                    params['status'] = this.tableConfig.statusFilter;
                } else if (!this.showArchived) {
                    params['status'] = Object.keys(this.filteredEnquiryStatusConfig);
                }

                await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_PAGE_DATA, params);
                this.tableConfig.pagination = Object.assign({}, this.tableConfig.pagination, props.pagination);
                this.tableConfig.pagination.rowsNumber = this.pageData['hydra:totalItems'];

                if (resetPage) {
                    this.tableConfig.pagination.page = 1;
                }
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        rowClick(event, row) {
            this.$router.push({
                name: 'admin-upload-enquiry',
                params: { id: row.id },
            });
        },
        createEnquiry() {
            this.$q.dialog({
                component: EnquiryCreateDialog,
                parent: this,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    let data;
                    if (payload.enquiryTemplate) {
                        const response = await EnquiryService.createByEnquiryTemplate(
                            payload.customer,
                            payload.enquiryTemplate,
                            payload.publicId,
                            'draft',
                            payload.overrideName,
                        );
                        data = response.data;
                    } else {
                        const response = await EnquiryService.postEnquiry({
                            customer: payload.customer,
                            publicId: payload.publicId,
                            name: payload.overrideName || 'Neuer Vorgang',
                            status: 'draft',
                        });
                        data = response.data;
                    }
                    await this.$router.push({ name: 'admin-upload-enquiry', params: { id: data.id } });
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }

        next();
    },
};
