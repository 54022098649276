//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import enquiryStore from '@/store/modules/enquiryStore';

export default {
    name: 'CustomerEnquiryNew',
    data() {
        return {
            publicId: '',
            name: '',
            description: '',
            expiresAt: null,
            customerId: '',
            checkExpiresAt: false,
        };
    },
    methods: {
        async addEnquiry() {
            this.$q.loading.show();
            try {
                let customerIRI = 'api/customers/' + this.customerId;
                if(!this.checkExpiresAt) {
                    this.expiresAt = null;
                }

                const res = await enquiryStore.dispatch(enquiryStore.ACTIONS.CREATE_ENQUIRY, {
                    name: this.name,
                    publicId: this.publicId,
                    description: this.description,
                    status: 'draft',
                    customer: customerIRI,
                    expiresAt: this.expiresAt,
                });
                await this.$router.push({ name: 'admin-enquiry-details', params: { enquiryId: res.id } });
                this.$notify('Der Vorgang wurde erfolgreich angelegt.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            next(vm => {
                vm.customerId = to.params.id;
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
