import ApiClient from '@/api/_apiClient';
import _cloneDeep from 'lodash/cloneDeep';

const EnquiryService = {
    async fetchPage(params) {
        return await ApiClient.get('enquiries', { params });
    },
    async fetchEnquiry(id) {
        return await ApiClient.get(`enquiries/${id}`);
    },
    async completeEnquiry(id) {
        return await ApiClient.post(`enquiries/${id}/complete`);
    },
    async createEnquiry(enquiry) {
        return await ApiClient.post('enquiries', enquiry);
    },
    async postEnquiry(enquiry) {
        return await ApiClient.post('enquiries', enquiry);
    },
    async patchEnquiry(id, enquiry) {
        // Quickfix: flatten the fileCategory
        const enquiryClone = _cloneDeep(enquiry);
        (enquiryClone.requestedFiles ?? []).forEach((file) => {
            if (file.fileCategory?.['@id']) {
                file.fileCategory = file.fileCategory['@id'];
            }
        });

        return await ApiClient.patch(`enquiries/${id}`, enquiryClone, {
            headers: {
                'Content-Type': 'application/ld+json',
            },
        });
    },
    async createByEnquiryTemplate(customer, enquiryTemplate, publicId = undefined, status = 'draft', overrideName = '') {
        return await ApiClient.post('enquiries/create-by-enquiry-template', {
            customer,
            enquiryTemplate,
            status,
            publicId,
            overrideName,
        });
    },
    async deleteEnquiry(id) {
        return await ApiClient.delete(`enquiries/${id}`);
    },
    async hardArchiveEnquiry(id) {
        return await ApiClient.post(`enquiries/${id}/hard-archive`);
    },
    async download(id) {
        return await ApiClient.get(`enquiries/${id}/download`, { responseType: 'blob' });
    },
    async fetchEnquiryStatusChanges(id) {
        return await ApiClient.get(`enquiry-status-changes?enquiry=${id}`);
    },
    async fetchFileStatusChanges(id) {
        return await ApiClient.get(`file-status-changes?file.enquiry=${id}`);
    },
};

export default EnquiryService;
