import _moduleHelper from '@/store/modules/_moduleHelper';
import FileTemplatesService from '@/api/fileTemplates.service';

export const NAMESPACE = 'FILE_TEMPLATES';

export const STATE = {
    FILE_TEMPLATES: 'FILE_TEMPLATES',
    PAGE: 'PAGE',
    ITEMS_PER_PAGE: 'ITEMS_PER_PAGE',
    TOTAL_ITEMS: 'TOTAL_ITEMS',
    SEARCH: 'SEARCH',
    ORDER_BY: 'ORDER_BY',
    ORDER: 'ORDER',
};

export const GETTERS = {};

export const MUTATIONS = {
    SET_FILE_TEMPLATES: 'SET_FILE_TEMPLATES',
    SET_PAGE: 'SET_PAGE',
    SET_ITEMS_PER_PAGE: 'SET_ITEMS_PER_PAGE',
    SET_TOTAL_ITEMS: 'SET_TOTAL_ITEMS',
    SET_SEARCH: 'SET_SEARCH',
    SET_ORDER_BY: 'SET_ORDER_BY',
    SET_ORDER: 'SET_ORDER',
};

export const ACTIONS = {
    GET_FILE_TEMPLATES: 'GET_FILE_TEMPLATES',
    POST_FILE_TEMPLATE: 'POST_FILE_TEMPLATE',
    PATCH_FILE_TEMPLATE: 'PATCH_FILE_TEMPLATE',
    DELETE_FILE_TEMPLATE: 'DELETE_FILE_TEMPLATE',
};

export const MODULE = {
    namespaced: true,
    state: {
        [STATE.FILE_TEMPLATES]: [],
        [STATE.PAGE]: 1,
        [STATE.ITEMS_PER_PAGE]: 10,
        [STATE.TOTAL_ITEMS]: null,
        [STATE.SEARCH]: '',
        [STATE.ORDER_BY]: 'sortIndex',
        [STATE.ORDER]: 'asc',
    },
    getters: {},
    mutations: {
        [MUTATIONS.SET_FILE_TEMPLATES]: (state, fileTemplates) => state[STATE.FILE_TEMPLATES] = fileTemplates,
        [MUTATIONS.SET_PAGE]: (state, page) => state[STATE.PAGE] = page,
        [MUTATIONS.SET_ITEMS_PER_PAGE]: (state, itemsPerPage) => state[STATE.ITEMS_PER_PAGE] = itemsPerPage,
        [MUTATIONS.SET_TOTAL_ITEMS]: (state, totalItems) => state[STATE.TOTAL_ITEMS] = totalItems,
        [MUTATIONS.SET_SEARCH]: (state, search) => state[STATE.SEARCH] = search,
        [MUTATIONS.SET_ORDER_BY]: (state, orderBy) => state[STATE.ORDER_BY] = orderBy,
        [MUTATIONS.SET_ORDER]: (state, order) => state[STATE.ORDER] = order,
    },
    actions: {
        async [ACTIONS.GET_FILE_TEMPLATES]({ state, commit }) {
            const { data } = await FileTemplatesService.getFileTemplates(
                state[STATE.SEARCH],
                state[STATE.PAGE],
                state[STATE.ITEMS_PER_PAGE],
                state[STATE.ORDER_BY],
                state[STATE.ORDER],
            );
            commit(MUTATIONS.SET_FILE_TEMPLATES, data['hydra:member']);
            commit(MUTATIONS.SET_TOTAL_ITEMS, data['hydra:totalItems']);
        },
        async [ACTIONS.POST_FILE_TEMPLATE]({ dispatch }, fileTemplate) {
            const { data } = await FileTemplatesService.postFileTemplate(fileTemplate);
            await dispatch(ACTIONS.GET_FILE_TEMPLATES);

            return data;
        },
        async [ACTIONS.PATCH_FILE_TEMPLATE]({ dispatch }, fileTemplate) {
            const { data } = await FileTemplatesService.patchFileTemplate(fileTemplate);
            await dispatch(ACTIONS.GET_FILE_TEMPLATES);

            return data;
        },
        async [ACTIONS.DELETE_FILE_TEMPLATE]({ dispatch }, fileTemplate) {
            await FileTemplatesService.deleteFileTemplate(fileTemplate);
            await dispatch(ACTIONS.GET_FILE_TEMPLATES);
        },
    },
};

export default {
    NAMESPACE,
    STATE,
    GETTERS,
    MUTATIONS,
    ACTIONS,
    MODULE,
    ..._moduleHelper(NAMESPACE),
};
