import { render, staticRenderFns } from "./FinancingProjectCard.vue?vue&type=template&id=12f32bb7&scoped=true&"
import script from "./FinancingProjectCard.vue?vue&type=script&lang=js&"
export * from "./FinancingProjectCard.vue?vue&type=script&lang=js&"
import style0 from "./FinancingProjectCard.vue?vue&type=style&index=0&id=12f32bb7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12f32bb7",
  null
  
)

export default component.exports
import {QCard,QCardSection,QBadge,QSeparator,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QBadge,QSeparator})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
