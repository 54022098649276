//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerStore from '@/store/modules/customerStore';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import AcceptCheckboxInvite from '@/components/customer/invite/AcceptCheckboxInvite';

// TODO: bankEmployee -> AcceptCheckboxInvite
export default {
    components: { AcceptCheckboxInvite },
    data() {
        return {
            password: '',
            passwordRepeat: '',
            accept: false,
        };
    },
    methods: {
        async submit() {
            this.$q.loading.show();
            try {
                await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.ACTIVATE, {
                    invitationCode: this.$route.params.invitationCode,
                    password: this.password,
                });
                await this.$router.push({ name: 'admin-dashboard' });
            } catch (error) {
                if (error?.response?.status === 422) {
                    this.allowResend = true;
                }
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        checkbox(value, { target }) {
            if (target.nodeName === 'A') {
                this.accept = !value;
                window.open(target.getAttribute('href'), target.getAttribute('target'));
            }
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-dashboard' });
        }
        next();
    },
};
