//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerStore from '@/store/modules/customerStore';
import configStore from '@/store/modules/configStore';
import PasswordResetDialog from '@/components/core/dialogs/PasswordResetDialog';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import ApiClient from '@/api/_apiClient';
import CredentialLogin from '@/components/customer/login/CredentialLogin';
import YesLogin from '@/components/customer/login/YesLogin';
import TechnicalMessages from '@/components/core/TechnicalMessages';

export default {
    components: { TechnicalMessages, YesLogin, CredentialLogin },
    data() {
        return {
            tab: 'yes',
        };
    },
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    methods: {
        async yes() {
            this.$q.loading.show();
            try {
                const { data } = await ApiClient.get('/_yes/login', {
                    baseURL: '',
                });
                window.location.href = data.url;
            } catch (error) {
                this.$root.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async submit({ email, password }) {
            this.$q.loading.show();
            try {
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
                await customerStore.dispatch(customerStore.ACTIONS.LOGIN, { email, password });
                await configStore.dispatch(configStore.ACTIONS.FETCH_CURRENT);
                await this.$router.push({ name: 'customer-dashboard' });
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        passwordReset() {
            this.$q.dialog({
                parent: this,
                component: PasswordResetDialog,
            }).onOk(async (email) => {
                this.$q.loading.show();
                try {
                    await customerStore.dispatch(customerStore.ACTIONS.REQUEST_PASSWORD_RESET, email);
                    this.$notify(
                        `Sofern Ihr Account mit der E-Mail-Adresse "${email}" besteht, finden Sie nun in Ihrem Postfach
                         eine E-Mail mit der Sie Ihr Passwort zurücksetzen können.`,
                        'positive',
                        'top',
                        { timeout: 10000 }
                    );
                } catch (e) {
                    this.$handleError(e);
                }
                this.$q.loading.hide();
            });
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (customerStore.getters[customerStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'customer-dashboard' });
        }

        if (to.query['token']) {
            try {
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
                await customerStore.dispatch(customerStore.ACTIONS.LOGIN_VIA_TOKEN, to.query['token']);
                await configStore.dispatch(configStore.ACTIONS.FETCH_CURRENT);
                // ROLE_CUSTOMER means account was activated
                if (customerStore.getters[customerStore.GETTERS.HAS_ROLE]('ROLE_CUSTOMER')) {
                    return next({ name: 'customer-dashboard' });
                }
                return next({ name: 'customer-activation' });
            } catch (error) {
                return next(vm => {
                    vm.$handleError(error);
                    vm.$router.replace({ name: 'customer-login' });
                });
            }
        }

        if (to.query['yes-error'] !== undefined) {
            return next(vm => {
                vm.$router.replace({ name: 'customer-login' });
                const message = to.query['yes-error'] || 'Beim Login mit Ihrer Bank ist ein Fehler aufgetreten.';
                vm.$notify(message, 'negative', 'top', { timeout: 30000 });
            });
        }

        next();
    },
};
