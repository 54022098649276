//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Loading } from 'quasar';
import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';
import IntroDialog from '@/components/upload/dialogs/IntroDialog';
import CustomerService from '@/api/customer.service';

export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        ...customerStore.mapState({
            loggedInCustomer: customerStore.STATE.LOGGED_IN_CUSTOMER,
        }),
        ...customerStore.mapGetters({
            isLoggedIn: customerStore.GETTERS.IS_LOGGED_IN,
            loggedInCustomerLoaded: customerStore.GETTERS.LOGGED_IN_CUSTOMER_LOADED,
            customerHasRole: customerStore.GETTERS.HAS_ROLE,
        }),
    },
    methods: {
        intro() {
            this.$emit('input', false);
            this.$q.dialog({
                component: IntroDialog,
                parent: this,
            });
        },
        async logout() {
            Loading.show();
            await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
            this.$emit('input', false);
            Loading.hide();
            await this.$router.push({ name: 'customer-login' });
        },
        loginSelbstauskunft() {
            const win = window.open('', '_blank');
            win.document.write('Eine Sekunde Geduld bitte...');
            (async () => {
                Loading.show();
                try {
                    const { data } = await CustomerService.getLoginForSelbstauskunft();
                    win.location.href = data['href'];
                } catch (error) {
                    this.$handleError(error);
                }
                Loading.hide();
            })();
        },
    },
};
