import Vue from 'vue';
import VueCurrencyInput from 'vue-currency-input';

const pluginOptions = {
    globalOptions: {
        currency: 'EUR',
        locale: 'de-DE',
        precision: {
            min: 0,
            max: 2,
        },
        distractionFree: {
            hideNegligibleDecimalDigits: true,
            hideCurrencySymbol: true,
            hideGroupingSymbol: false,
        },
        allowNegative: false,
    },
};
Vue.use(VueCurrencyInput, pluginOptions);
