const disbursementStatusConfig = {
    draft: {
        text: 'Entwurf',
        color: 'grey-3',
        textColor: 'primary',
    },
    submitted: {
        text: 'In Prüfung',
        color: 'primary',
        textColor: 'primary-invert',
    },
    approved: {
        text: 'Genehmigt',
        color: 'positive',
        textColor: 'positive-invert',
    },
    disbursed: {
        text: 'Ausgezahlt',
        color: 'positive',
        textColor: 'positive-invert',
    },
    problem: {
        text: 'Klärungsbedarf',
        color: 'warning',
        textColor: 'warning-invert',
    },
    rejected: {
        text: 'Abgelehnt',
        color: 'negative',
        textColor: 'negative-invert',
    },
    archived: {
        text: 'Archiviert',
        color: 'grey-3',
        textColor: 'positive',
    },
};

export default disbursementStatusConfig;
