//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';

export default {
    computed: {
        roles() {
            return this.loggedInBankEmployee.roles ?? [];
        },
        ...bankEmployeeStore.mapState({
            loggedInBankEmployee: bankEmployeeStore.STATE.LOGGED_IN_BANK_EMPLOYEE,
        }),
        ...bankEmployeeStore.mapGetters({
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
    },
    beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }
        next();
    },
};
