//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import { date } from 'quasar';
import BankEmployeeService from '@/api/bankEmployee.service';

export default {
    data() {
        const tableConfig = bankEmployeeStore.state[bankEmployeeStore.STATE.CUSTOMER_TABLE_CONFIG];

        return {
            bankEmployeeOptions: [],
            selectedBankEmployee: null,
            search: tableConfig.search,
            pagination: tableConfig.pagination,
            showArchived: tableConfig.showArchived,
            columns: [
                { name: 'customerId', label: 'Kunden-Nr.', field: 'customerId', sortable: false },
                { name: 'email', label: 'E-Mail', field: 'email', sortable: true },
                {
                    name: 'lastName',
                    label: 'Kunde',
                    field: row => {
                        return row.lastName + ', ' + row.firstName;
                    },
                    sortable: true,
                },
                {
                    name: 'bankEmployee',
                    label: 'Berater',
                    field: 'bankEmployeeIdentifier',
                },
                {
                    name: 'activatedAt',
                    label: 'Aktiviert am',
                    field: 'activatedAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
                {
                    name: 'status',
                    label: 'Status',
                    field: row => {
                        return row.archivedAt ? 'Archiviert' : (row.requestedDeletionAt ? 'Löschanfrage' : 'Aktiv');
                    },
                    sortable: true,
                },
            ],
        };
    },
    computed: {
        computedColumns() {
            const computedColumns = this.columns;
            computedColumns.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name === this.pagination.sortBy)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });

            return computedColumns;
        },
        ...bankEmployeeStore.mapState({
            pageData: bankEmployeeStore.STATE.CUSTOMER_LIST,
            tableConfig: bankEmployeeStore.STATE.CUSTOMER_TABLE_CONFIG,
        }),
    },
    watch: {
        search(search) {
            bankEmployeeStore.commit(bankEmployeeStore.MUTATIONS.SET_CUSTOMER_TABLE_CONFIG, {
                ...this.tableConfig,
                search,
            });
        },
        pagination: {
            deep: true,
            handler(pagination) {
                bankEmployeeStore.commit(bankEmployeeStore.MUTATIONS.SET_CUSTOMER_TABLE_CONFIG, {
                    ...this.tableConfig,
                    pagination,
                });
            },
        },
        showArchived(showArchived) {
            bankEmployeeStore.commit(bankEmployeeStore.MUTATIONS.SET_CUSTOMER_TABLE_CONFIG, {
                ...this.tableConfig,
                showArchived,
            });
        },
    },
    mounted() {
        this.updateTable({ pagination: this.pagination });
    },
    methods: {
        async bankEmployeeFilter(val, update) {
            const { data } = await BankEmployeeService.getBankEmployees({ 'search': val, 'exists[archivedAt]': false });
            const options = [{ value: -1, label: '--- Kein Berater ---' }];
            data['hydra:member'].forEach(item => {
                // no archived bank employees
                if (!item.archivedAt) {
                    options.push({
                        value: item.id,
                        label: `${item.lastName}, ${item.firstName}`,
                    });
                }
            });
            update(() => {
                this.bankEmployeeOptions = options.sort((n1, n2)=> n1.label.localeCompare(n2.label));
            });
        },
        async refresh() {
            await this.updateTable({ pagination: this.pagination }, true);
            this.$notify('Kundenübersicht wurde aktualisiert.');
        },
        async updateTable(props, resetPage = false) {
            this.$q.loading.show();
            try {
                const params = {
                    'items-per-page': props.pagination.rowsPerPage,
                    'page': resetPage ? 1 : props.pagination.page,
                };

                if (props.pagination.sortBy) {
                    params[`order[${props.pagination.sortBy}]`] = props.pagination.descending ? 'desc' : 'asc';
                }

                if (this.search) {
                    params['search'] = this.search;
                }

                if (!this.showArchived) {
                    params['exists[archivedAt]'] = false;
                }

                if (this.selectedBankEmployee) {
                    if (this.selectedBankEmployee['value'] === -1) {
                        params['exists[bankEmployee]'] = false;
                    } else {
                        params['bankEmployee.id'] = this.selectedBankEmployee['value'];
                    }
                }

                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.FETCH_CUSTOMER_LIST, params);
                this.pagination = Object.assign({}, this.pagination, props.pagination);
                this.pagination.rowsNumber = this.pageData['hydra:totalItems'];

                if (resetPage) {
                    this.pagination.page = 1;
                }
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        rowClick(event, row) {
            this.$router.push({ name: 'admin-customer-details', params: { id: row.id } });
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }
        next();
    },
};
