import ApiClient from '@/api/_apiClient';

const CustomerService = {
    async login(email, password) {
        return await ApiClient.post('customers/login', {
            email,
            password,
        });
    },
    async logout() {
        return await ApiClient.get('customers/logout');
    },
    async refresh() {
        return await ApiClient.post('customers/refresh');
    },
    async getCustomer(id) {
        return await ApiClient.get(`customers/${id}`);
    },
    async getCustomers(params) {
        return await ApiClient.get('customers', { params });
    },
    async editCustomer(id, params) {
        return await ApiClient.patch(`customers/${id}`, params);
    },
    async inviteCustomer(id) {
        return await ApiClient.post(`customers/${id}/invite`);
    },
    async requestDeletion() {
        return await ApiClient.post('customers/request-deletion');
    },
    async cancelDeletionRequest(id) {
        return await ApiClient.post(`customers/${id}/cancel-deletion-request`);
    },
    async registerWithInvitationCode(invitationCode, password) {
        return await ApiClient.post('customers/register-with-invitation-code', {
            invitationCode,
            password,
        });
    },
    async requestActivationCode(customerId) {
        return await ApiClient.post(`customers/${customerId}/request-activation-code`);
    },
    async activate(customerId, activationCode) {
        return await ApiClient.post(`customers/${customerId}/activate`, { activationCode });
    },
    requestPasswordReset(email) {
        return ApiClient.post('customers/request-password-reset', {
            email,
        });
    },
    resetPassword(passwordResetCode, password) {
        return ApiClient.post('customers/reset-password', {
            passwordResetCode,
            password,
        });
    },
    changePassword(currentPassword, newPassword) {
        return ApiClient.post('customers/change-password', {
            currentPassword,
            newPassword,
        });
    },
    requestSelbstauskunft(id) {
        return ApiClient.post(`customers/${id}/request-selbstauskunft`);
    },
    getLoginForSelbstauskunft() {
        return ApiClient.get('customers/get-login-for-selbstauskunft');
    },
    fetchAgree21PersonStammdaten(personennummer) {
        return ApiClient.get(`customers/fetch-agree21-person-stammdaten/${personennummer}`);
    },
    hardArchive(id) {
        return ApiClient.post(`customers/${id}/hard-archive`);
    },
};

export default CustomerService;
