export default (response) => {
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    let matches = /filename="?(.*?)"?$/.exec(response.headers['content-disposition']);
    a.download = matches[1] ?? 'download';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
};
