import MmmButton from '@/plugins/mmm-components/components/buttons/MmmButton';
import MmmPrimaryButton from '@/plugins/mmm-components/components/buttons/MmmPrimaryButton';
import MmmSecondaryButton from '@/plugins/mmm-components/components/buttons/MmmSecondaryButton';
import MmmFlatButton from '@/plugins/mmm-components/components/buttons/MmmFlatButton';
import MmmOutlineButton from '@/plugins/mmm-components/components/buttons/MmmOutlineButton';
import MmmRoundButton from '@/plugins/mmm-components/components/buttons/MmmRoundButton';
import MmmNavigationBackButton from '@/plugins/mmm-components/components/buttons/MmmNavigationBackButton';

export default (Vue) => {
    Vue.component('mmm-button', MmmButton);
    Vue.component('mmm-primary-button', MmmPrimaryButton);
    Vue.component('mmm-secondary-button', MmmSecondaryButton);
    Vue.component('mmm-flat-button', MmmFlatButton);
    Vue.component('mmm-outline-button', MmmOutlineButton);
    Vue.component('mmm-round-button', MmmRoundButton);
    Vue.component('mmm-navigation-back-button', MmmNavigationBackButton);
};
