//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FinancingProjectStatusBadge from '@/components/valutierung/FinancingProjectStatusBadge';
export default {
    components: { FinancingProjectStatusBadge },
    props: {
        financingProject: {
            type: Object,
            required: true,
        },
    },
};
