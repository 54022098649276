//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Loading } from 'quasar';
import ValutierungService from '@/api/valutierung.service';
import DisbursementStatusBadge from '@/components/valutierung/DisbursementStatusBadge';
import PdfPreviewDialog from '@/components/PdfPreviewDialog';
import downloadBlobResponse from '@/util/downloadBlobResponse';

export default {
    components: { DisbursementStatusBadge },
    data() {
        return {
            disbursement: {},
            documentFiles: {},
            isRequired: false,
            // response: ' ',
            value: {
                type: String,
                default: '""',
            },
            comment: '',
        };
    },
    methods: {
        disable() {
            if (this.disbursement.status === 'submitted' || this.disbursement.status === 'problem') {
                return false;
            }
            return true;
        },
        async refreshDisbursement() {
            const { data: disbursement } = await ValutierungService.getDisbursementById(this.disbursement.id);
            this.disbursement = disbursement;
            this.$notify('Vorgang wurde aktualisiert.');
        },
        async preview(id) {
            this.$q.dialog({
                parent: this,
                component: PdfPreviewDialog,
                url: `/api/valutierung-document-files/${id}/preview`,
            });
        },
        previewSummary() {
            this.$q.dialog({
                parent: this,
                component: PdfPreviewDialog,
                url: `/api/valutierung-disbursements/${this.disbursement.id}/summary-as-pdf?inline`,
            });
        },
        previewCompleteDisbursement() {
            this.$q.dialog({
                parent: this,
                component: PdfPreviewDialog,
                url: `/api/valutierung-disbursements/${this.disbursement.id}/complete-disbursement-as-pdf?inline`,
            });
        },
        async approve() {
            Loading.show();
            await ValutierungService.approveDisbursement(this.disbursement.id, { __context: { message: this.comment } });
            await this.refreshDisbursement();
            this.comment = '';
            Loading.hide();

        },
        async reject() {
            Loading.show();
            await ValutierungService.rejectDisbursement(this.disbursement.id, { __context: { message: this.comment } });
            await this.refreshDisbursement();
            this.comment = '';
            Loading.hide();
        },
        async inquire() {
            if (this.comment == '') {
                return this.isRequired = true;
            }
            Loading.show();
            await ValutierungService.inquireDisbursement(this.disbursement.id, { __context: { message: this.comment } });
            await this.refreshDisbursement();
            this.isRequired = false;
            this.comment = '';
            Loading.hide();
        },
        async downloadSummary() {
            Loading.show();
            const response = await ValutierungService.downloadSummaryAsPdf(this.disbursement.id);
            downloadBlobResponse(response);
            Loading.hide();
        },
        async downloadCompleteDisbursement() {
            Loading.show();
            const response = await ValutierungService.downloadCompleteDisbursementAsPdf(this.disbursement.id);
            downloadBlobResponse(response);
            Loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const { data: disbursement } = await ValutierungService.getDisbursementById(to.params.id);
            const { data: documentFiles } = await ValutierungService.getDocumentFiles({ disbursement: disbursement['@id'] });
            next(vm => {
                vm.disbursement = disbursement;
                vm.documentFiles = documentFiles['hydra:member'];
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
