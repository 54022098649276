import _ from 'lodash';
import { date as qDate, format } from 'quasar';

const size = (value) => format.humanStorageSize(value);
const date = (value, format = 'DD.MM.YYYY') => qDate.formatDate(value, format);
const datetime = (value, format = 'DD.MM.YYYY HH:mm:ss') => qDate.formatDate(value, format);
const capitalize = (value) => _.upperFirst(value);
const truncate = (value, length = 16) => _.truncate(value, { length });
const centToEuro = (value, precision = 'auto') => {
    if (precision === 'auto') {
        precision = (value / 100 === Math.floor(value / 100)) ? 0 : 2;
    }

    const formater = new window.Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    });

    return formater.format(value / 100);
};

export default (Vue) => {
    Vue.filter('size', size);
    Vue.filter('date', date);
    Vue.filter('datetime', datetime);
    Vue.filter('capitalize', capitalize);
    Vue.filter('centToEuro', centToEuro);
    Vue.filter('truncate', truncate);

    Vue.prototype.$centToEuro = centToEuro;
};

export {
    size,
    date,
    datetime,
    capitalize,
    centToEuro,
    truncate,
};
