//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileUploader from '@/components/FileUploader';
import ValutierungService from '@/api/valutierung.service';
import PdfPreviewDialog from '@/components/PdfPreviewDialog';
import { scroll } from 'quasar';

const { getScrollTarget, setScrollPosition } = scroll;

export default {
    components: { FileUploader },
    props: {
        value: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            files: [],
            commentState: !!this.value.documentFile.comment,
            comment: this.value.documentFile.comment ? '' + this.value.documentFile.comment : '',
            expansionItemState: !this.value.documentFile.uploaded,
            fileUploaderConfig: {
                uploading: false,
                error: false,
            },
            uploadAgain: false,
        };
    },
    computed: {
        computedFileUploaderConfig() {
            return {
                disabled: this.isUploaded && !this.uploadAgain,
                text: this.isUploaded && !this.uploadAgain ?
                    'Ihre Datei(en) wurde(n) **erfolgreich** zu **einem PDF-Dokument** zusammengefasst.\n\n' +
                    'Sie können das PDF-Dokument über die **Vorschau** ansehen, prüfen und ggfs. korrigieren.' :
                    undefined,
                icon: this.isUploaded && !this.uploadAgain ? 'fas fa-file-pdf' : undefined,
                iconColor: undefined,
                message: undefined,
                comment: undefined,
                ...this.fileUploaderConfig,
            };
        },
        isUploaded() {
            return !!this.value.documentFile?.uploaded;
        },
    },
    methods: {
        fileUploaderInput() {
            this.fileUploaderConfig.error = false;
            this.$refs.fileUploader.resetProgress();
        },
        async upload() {
            this.$q.loading.show();
            this.fileUploaderConfig.error = false;
            this.fileUploaderConfig.uploading = true;
            this.comment = '';
            try {
                await ValutierungService.uploadDocumentFile(
                    this.value.documentFile.id,
                    this.files,
                    null,
                    this.$refs.fileUploader.progress
                );
                const { data } = await ValutierungService.getResourceByIri(this.value.documentFile['@id']);
                this.value.documentFile = data;
                this.uploadAgain = false;
                this.$refs.fileUploader.reset();
            } catch (error) {
                this.$handleError(error);
                this.fileUploaderConfig.error = true;
            }
            this.fileUploaderConfig.uploading = false;
            this.$q.loading.hide();
        },
        abort() {
            this.uploadAgain = false;
            this.$refs.fileUploader.reset();
        },
        preview() {
            this.$q.dialog({
                parent: this,
                component: PdfPreviewDialog,
                url: `${this.value.documentFile['@id']}/preview`,
            });
        },
        async saveComment() {
            if (this.commentState && this.comment) {
                await ValutierungService.uploadDocumentFile(
                    this.value.documentFile.id,
                    [],
                    this.comment
                );
                const { data } = await ValutierungService.getResourceByIri(this.value.documentFile['@id']);
                this.value.documentFile = data;
                this.uploadAgain = false;
            }
        },
        validate() {
            if (!this.value.documentTemplate.required) {
                return true;
            }
            if (this.commentState) {
                if (this.comment) {
                    this.fileUploaderConfig.error = false;

                    return true;
                }
            } else {
                if (this.isUploaded) {
                    return true;
                }
            }

            this.expansionItemState = true;
            this.fileUploaderConfig.error = true;

            return false;
        },
        resetValidation() {
            // noop
        },
        focus() {
            if (this.commentState) {
                this.$refs.commentInput.focus();
            } else {
                const el = this.$refs.list.$el;
                const target = getScrollTarget(el);
                const offset = el.offsetTop;
                const duration = 250;
                setScrollPosition(target, offset, duration);
            }
        },
    },
};
