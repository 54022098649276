export const VERSION = window?.APP_VERSION ?? 'dev-local';
export const MAJOR_VERSION = /^\d+/.exec(VERSION)?.[0] ?? VERSION;
export const MAJOR_MINOR_VERSION = /^\d+\.\d+/.exec(VERSION)?.[0] ?? VERSION;
export const MAJOR_MINOR_PATCH_VERSION = /^\d+\.\d+\.\d+/.exec(VERSION)?.[0] ?? VERSION;

export default {
    VERSION,
    MAJOR_VERSION,
    MAJOR_MINOR_VERSION,
    MAJOR_MINOR_PATCH_VERSION,
};
