//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';

export default {
    data() {
        return {
            activationCode: '',
        };
    },
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        ...customerStore.mapState({
            customer: customerStore.STATE.LOGGED_IN_CUSTOMER,
        }),
    },
    methods: {
        async requestNewActivationCode() {
            this.$q.loading.show();
            try {
                await customerStore.dispatch(customerStore.ACTIONS.REQUEST_ACTIVATION_CODE);
                this.$notify('Ein neue Aktivierungscode wurde versandt.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
        async submit() {
            this.$q.loading.show();
            try {
                await customerStore.dispatch(customerStore.ACTIONS.ACTIVATE, this.activationCode);
                this.$notify('Ihr Account wurde erfolgreich aktiviert.', 'positive');
                await this.$router.push({ name: 'customer-dashboard' });
            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            try {
                await customerStore.dispatch(customerStore.ACTIONS.REFRESH_LOGGED_IN_CUSTOMER);
            } catch (e) {
                await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
                return next({ name: 'customer-login' });
            }

            if (customerStore.getters[customerStore.GETTERS.HAS_ROLE]('ROLE_CUSTOMER')) {
                return next({ name: 'customer-dashboard' });
            }

            const customer = customerStore.state[customerStore.STATE.LOGGED_IN_CUSTOMER];
            if (customer.mobile && !customer.hasActivationCode) {
                await customerStore.dispatch(customerStore.ACTIONS.REQUEST_ACTIVATION_CODE);
            }

            next();
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
