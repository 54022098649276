//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const defaultFileCategory = {
    sortIndex: 0,
    name: '',
};

export default {
    props: {
        value: {
            type: Object,
            default: () => ({ ...defaultFileCategory }),
        },
        type: {
            type: String,
            default: 'create',
            validator: (value) => ['create', 'edit'].indexOf(value) !== -1,
        },
    },
    data() {
        return {
            fileCategory: { ...defaultFileCategory, ...this.value },
        };
    },
    computed: {
        headline() {
            switch (this.type) {
                case 'edit':
                    return 'Dokumentenkategorie bearbeiten';
                case 'create':
                default:
                    return 'Dokumentenkategorie erstellen';
            }
        },
        buttonLabel() {
            switch (this.type) {
                case 'edit':
                    return 'Speichern';
                case 'create':
                default:
                    return 'Anlegen';
            }
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async ok() {
            if (await this.$refs.form.validate() === false) {
                return;
            }

            this.$emit('ok', this.fileCategory);
            this.hide();
        },
        cancel() {
            this.hide();
        },
    },
};
