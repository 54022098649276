import { render, staticRenderFns } from "./DisbursementDocument.vue?vue&type=template&id=3520b44c&scoped=true&"
import script from "./DisbursementDocument.vue?vue&type=script&lang=js&"
export * from "./DisbursementDocument.vue?vue&type=script&lang=js&"
import style0 from "./DisbursementDocument.vue?vue&type=style&index=0&id=3520b44c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3520b44c",
  null
  
)

export default component.exports
import {QList,QExpansionItem,QSeparator,QIcon,QTooltip,QInput,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QList,QExpansionItem,QSeparator,QIcon,QTooltip,QInput,QField})
