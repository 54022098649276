//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';

export default {
    data() {
        return {
            password: '',
            passwordRepeat: '',
        };
    },
    methods: {
        async submit() {
            this.$q.loading.show();
            try {
                await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.RESET_PASSWORD, {
                    passwordResetCode: this.$route.params.passwordResetCode,
                    password: this.password,
                });
                this.$notify('Ihr Passwort wurde erfolgreich geändert.', 'positive');
                await this.$router.push({ name: 'admin-login' });
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-dashboard' });
        }
        next();
    },
};
