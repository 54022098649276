//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';
import { VERSION } from '@/version';

export default {
    data() {
        return {
            VERSION: VERSION,
        };
    },
    computed: {
        titleRelativPronounNominativ() {
            return this.config?.app?.titleRelativPronounNominativ || 'das';
        },
        title() {
            return this.config?.app?.title || 'ServicePortal';
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
            footerUrls: configStore.STATE.FOOTER_URLS,
        }),
    },
};
