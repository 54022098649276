//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        disable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['yes', 'credentials'],
};
