import { render, staticRenderFns } from "./App.vue?vue&type=template&id=1a7a2bfc&scoped=true&"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=1a7a2bfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a7a2bfc",
  null
  
)

export default component.exports
import {QSpinnerTail} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSpinnerTail})
