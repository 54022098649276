//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';

export default {
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
};
