import ApiClient from '@/api/_apiClient';

const FileService = {
    async fetchFile(id) {
        return await ApiClient.get(`files/${id}`);
    },
    async createFile(file) {
        return await ApiClient.post('files', file);
    },
    async createFileByFileTemplate(payload) {
        return await ApiClient.post('files/create-by-file-template', payload);
    },
    async patchFile(id, file) {
        return await ApiClient.patch(`files/${id}`, file);
    },
    async deleteFile(id) {
        return await ApiClient.delete(`files/${id}`);
    },
    async upload(id, files, onUploadProgress = null) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        if (!Array.isArray(files)) {
            files = [files];
        }

        const formData = new FormData();
        files.forEach(file => formData.append('file[]', file));

        return await ApiClient.post(`files/${id}/upload`, formData, { headers, onUploadProgress });
    },
    async ready(id) {
        return await ApiClient.post(`files/${id}/ready`);
    },
    async problem(id, context = {}) {
        return await ApiClient.post(`files/${id}/problem`, {
            __context: context,
        });
    },
    async unproblem(id, context = {}) {
        return await ApiClient.post(`files/${id}/unproblem`, {
            __context: context,
        });
    },
    async fetchFileStatusChanges(id) {
        return await ApiClient.get(`file-status-changes?file=${id}`);
    },
    async fetchCategories() {
        return await ApiClient.get('file-categories');
    },
    downloadSummaryAsPdf(id) {
        return ApiClient.get(`files/${id}/download`,  { responseType: 'blob' });
    },
    async agree21Archive(id, file, customer) {
        return await ApiClient.post(`files/${id}/agree21-archive`, {
            file,
            customer,
        });
    },
    async patchByFileTemplate(id, fileTemplateId) {
        return await ApiClient.post(`files/${id}/patch-by-file-template`, { fileTemplateId });
    },
};

export default FileService;
