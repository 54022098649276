//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';

export default {
    computed: {
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        ...customerStore.mapGetters({
            isLoggedIn: customerStore.GETTERS.IS_LOGGED_IN,
        }),
    },
    methods: {
        async start() {
            try {
                await this.$router.push({ name: 'customer-login' });
            } catch (e) {
                // ignore redirect-error if user is already logged in
            }
        },
    },
};
