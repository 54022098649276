import Vue from 'vue';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

marked.setOptions( {
    breaks: true,
});

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if (node.nodeName.toLowerCase() !== 'a') {
        return;
    }

    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
});

const cleanMarkdown = function (el, { value }) {
    if (value === undefined) {
        value = el.innerHTML;
    }
    const markdown = marked.parse(String(value));
    el.innerHTML = markdown ? DOMPurify.sanitize(markdown, {}) : '';
};

Vue.directive('markdown', {
    inserted: cleanMarkdown,
    update: cleanMarkdown,
});
