//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash';
import fileTemplatesStore from '@/store/modules/fileTemplatesStore';
import FileAndFileTemplateDialog from '@/components/upload/dialogs/FileAndFileTemplateDialog';
import FileTemplateDeleteDialog from '@/components/upload/dialogs/FileTemplateDeleteDialog';

export default {
    data() {
        return {
            loading: false,
            pagination: {
                sortBy: fileTemplatesStore.state[fileTemplatesStore.STATE.ORDER_BY],
                descending: fileTemplatesStore.state[fileTemplatesStore.STATE.ORDER] === 'desc',
                page: fileTemplatesStore.state[fileTemplatesStore.STATE.PAGE],
                rowsPerPage: fileTemplatesStore.state[fileTemplatesStore.STATE.ITEMS_PER_PAGE],
                rowsNumber: fileTemplatesStore.state[fileTemplatesStore.STATE.TOTAL_ITEMS],
            },
            columns: [
                { name: 'sortIndex', label: 'Sortierindex', field: 'sortIndex', sortable: true, align: 'left' },
                { name: 'name', label: 'Bezeichnung', field: 'name', sortable: true, align: 'left' },
                { name: 'description', label: 'Beschreibung', field: (item) => _.truncate(item.description, { length: 100 }), align: 'left' },
                { name: 'fileCategory.name', label: 'Kategorie', field: (item) => item.fileCategory?.name || '[Sonstige Dokumente]', sortable: true, align: 'left' },
                { name: 'actions', label: 'Aktionen', field: () => 'v-slot:body-cell-actions', align: 'left' },
            ],
        };
    },
    computed: {
        search: {
            get: () => fileTemplatesStore.state[fileTemplatesStore.STATE.SEARCH],
            set: (search) => fileTemplatesStore.commit(fileTemplatesStore.MUTATIONS.SET_SEARCH, search),
        },
        ...fileTemplatesStore.mapState({
            fileTemplates: fileTemplatesStore.STATE.FILE_TEMPLATES,
        }),
    },
    mounted() {
        if (!this.pagination.rowsNumber) {
            this.updateTable(this.pagination);
        }
    },
    methods: {
        resetSearch() {
            this.search = '';
            this.updateTable(this.pagination);
        },
        rowClick(event, row) {
            this.edit(row);
        },
        async updateTable(pagination) {
            fileTemplatesStore.commit(fileTemplatesStore.MUTATIONS.SET_PAGE, pagination.page);
            fileTemplatesStore.commit(fileTemplatesStore.MUTATIONS.SET_ITEMS_PER_PAGE, pagination.rowsPerPage);
            fileTemplatesStore.commit(fileTemplatesStore.MUTATIONS.SET_ORDER_BY, pagination.sortBy);
            fileTemplatesStore.commit(fileTemplatesStore.MUTATIONS.SET_ORDER, pagination.descending ? 'desc' : 'asc');
            this.loading = true;
            await fileTemplatesStore.dispatch(fileTemplatesStore.ACTIONS.GET_FILE_TEMPLATES);
            this.loading = false;
            this.pagination = Object.assign({}, this.pagination, pagination);
            this.pagination.rowsNumber = fileTemplatesStore.state[fileTemplatesStore.STATE.TOTAL_ITEMS];
        },
        create() {
            this.$q.dialog({
                component: FileAndFileTemplateDialog,
                parent: this,
                entity: 'FileTemplate',
            }).onOk(async (fileTemplate) => {
                this.$q.loading.show();
                try {
                    await fileTemplatesStore.dispatch(fileTemplatesStore.ACTIONS.POST_FILE_TEMPLATE, fileTemplate);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        edit(fileTemplate) {
            this.$q.dialog({
                component: FileAndFileTemplateDialog,
                parent: this,
                value: fileTemplate,
                entity: 'FileTemplate',
                type: 'edit',
            }).onOk(async (editedFileTemplate) => {
                this.$q.loading.show();
                try {
                    await fileTemplatesStore.dispatch(fileTemplatesStore.ACTIONS.PATCH_FILE_TEMPLATE, editedFileTemplate);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
        remove(fileTemplate) {
            this.$q.dialog({
                component: FileTemplateDeleteDialog,
                parent: this,
                name: fileTemplate.name,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await fileTemplatesStore.dispatch(fileTemplatesStore.ACTIONS.DELETE_FILE_TEMPLATE, fileTemplate);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
    },
};
