//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileCategoriesService from '@/api/fileCategories.service';
import FileTemplatesService from '@/api/fileTemplates.service';
import FileTemplateOverrideDialog from '@/components/upload/dialogs/FileTemplateOverrideDialog';
import FileService from '@/api/file.service';
import _cloneDeep from 'lodash/cloneDeep';
import { date } from 'quasar';

const defaultModel = {
    name: '',
    fileTemplate: null,
    description: '',
    tooltip: '',
    fileCategory: null,
    required: true,
    sortIndex: 0,
    accept: 'image/*, application/pdf',
    maxSize: null,
    expires: false,
    expiresInDays: 0,
    expiresAt: null,
    agree21ShelfId: null,
    agree21Comment: null,
    agree21FormId: null,
    agree21FormName: null,
};

export default {
    props: {
        value: {
            type: Object,
            default: () => ({ ...defaultModel }),
        },
        entity: {
            type: String,
            default: 'File',
            validator: (value) => ['FileTemplate', 'File'].indexOf(value) !== -1,
        },
        type: {
            type: String,
            default: 'create',
            validator: (value) => ['create', 'edit'].indexOf(value) !== -1,
        },
    },
    data() {
        return {
            model: {
                ...defaultModel,
                ..._cloneDeep(this.value),
                expires: this.entity === 'File' ? !!this.value.expiresAt : !!this.value.expiresInDays,
            },
            fileCategoryOptions: [{ value: null, label: '-- Ohne Kategorie --' }],
            fileTemplateOptions: [],
            acceptOptions: [
                {
                    label: 'Bilder oder PDFs',
                    value: 'image/*, application/pdf',
                    icon: 'fal fa-copy',
                },
                {
                    label: 'nur Bilder',
                    value: 'image/*',
                    icon: 'fal fa-file-image',
                },
                {
                    label: 'nur PDFs',
                    value: 'application/pdf',
                    icon: 'fal fa-file-pdf',
                },
            ],
            maxSizeOptions: [
                {
                    label: 'keine Einschränkung (maximaler Upload ist 64 MB)',
                    value: null,
                },
                {
                    label: '512 kB',
                    value: 512 * 1024,
                },
                {
                    label: '1 MB',
                    value: 1024 * 1024,
                },
                {
                    label: '2 MB',
                    value: 2 * 1024 * 1024,
                },
                {
                    label: '5 MB',
                    value: 5 * 1024 * 1024,
                },
                {
                    label: '10 MB',
                    value: 10 * 1024 * 1024,
                },
                {
                    label: '20 MB',
                    value: 20 * 1024 * 1024,
                },
                {
                    label: '30 MB',
                    value: 30 * 1024 * 1024,
                },
            ],
        };
    },
    computed: {
        headline() {
            let entity = 'Dokument';
            if (this.entity === 'FileTemplate') {
                entity = 'Dokumentvorlage';
            }

            switch (this.type) {
                case 'edit':
                    return `${entity} bearbeiten`;
                case 'create':
                default:
                    return `${entity} erstellen`;
            }
        },
        buttonLabel() {
            switch (this.type) {
                case 'edit':
                    return 'Speichern';
                case 'create':
                default:
                    return 'Anlegen';
            }
        },
    },
    mounted() {
        if (this.model.fileCategory) {
            this.fileCategoryOptions.push({
                value: this.model.fileCategory['@id'],
                label: this.model.fileCategory.name,
            });
            this.model.fileCategory = this.model.fileCategory['@id'];
        }
    },
    methods: {
        parseToIsoDate(val, format = 'DD.MM.YYYY') {
            if (val) {
                return date.formatDate(date.extractDate(val, format), 'YYYY-MM-DD');
            }
        },
        async fileCategoryFilter(val, update) {
            const { data } = await FileCategoriesService.getFileCategories(val, 1, 30, 'name');
            const options = [{ value: null, label: '-- Ohne Kategorie --' }];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item['@id'],
                    label: item.name,
                });
            });

            update(() => {
                this.fileCategoryOptions = options;
            });
        },
        async fileTemplateFilter(val, update) {
            const { data } = await FileTemplatesService.getFileTemplates(val, 1, 30, 'name');
            const options = [];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item.id,
                    label: item.name,
                });
            });

            update(() => {
                this.fileTemplateOptions = options;
            });
        },
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async ok() {
            if (await this.$refs.form.validate() === false) {
                return;
            }

            this.$emit('ok', {
                ...this.model,
                expiresInDays: this.model.expires ? this.model.expiresInDays : 0,
                expiresAt: this.model.expires ? this.model.expiresAt : null,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
        toggleDatePicker() {
            this.$refs.qDateExpiresAt.toggle();
        },
        overrideWithTemplate() {
            this.$q.dialog({
                parent: this,
                component: FileTemplateOverrideDialog,
                name: this.model.name,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await FileService.patchByFileTemplate(this.model.id, this.model.fileTemplate);
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
                this.$emit('ok');
                this.hide();
            });
        },
    },
};
