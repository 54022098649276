//
//
//
//
//
//

import fileStatusConfig from '@/config/fileStatus.config';

export default {
    props: {
        status: {
            type: String,
            required: true,
        },
    },
    computed: {
        config() {
            if (fileStatusConfig[this.status]) {
                return fileStatusConfig[this.status];
            }

            return {
                color: 'grey-3',
                textColor: 'primary',
                text: this.status,
            };
        },
    },
};
