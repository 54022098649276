//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            email: '',
            salutation: '',
            salutationOptions: ['NO_SALUTATION', 'MR', 'MRS'],
            firstName: '',
            lastName: '',
            mobile: '',
            plainPassword: '',
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        ok() {
            this.$emit('ok', {
                email: this.email,
                salutation: this.salutation,
                firstName: this.firstName,
                lastName: this.lastName,
                mobile: this.mobile,
                plainPassword: this.plainPassword,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
    },
};
