//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            message: null,
            comment: '',
            options: [
                { label: 'ist unklar', value: 'Dokument ist unklar.' },
                { label: 'liegt nicht vor', value: 'Dokument liegt nicht vor.' },
                { label: 'wurde an die Bank übergeben', value: 'Dokument wurde an die Bank übergeben.' },
                { label: 'muss nachgereicht werden', value: 'Dokument muss nachgereicht werden.' },
            ],
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        ok() {
            this.$emit('ok', {
                message: this.message,
                comment: this.comment,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
    },
};
