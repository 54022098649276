//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileTemplatesService from '@/api/fileTemplates.service';

export default {
    data() {
        return {
            fileTemplate: '',
            fileTemplateOptions: [{ value: '', label: 'Ohne Vorlage' }],
            name: '',
        };
    },
    methods: {
        async fileTemplateFilter(val, update) {
            const { data } = await FileTemplatesService.getFileTemplates(val, 1, 30, 'name');
            const options = [{ value: '', label: 'Ohne Vorlage' }];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item['@id'],
                    label: item.name,
                });
            });

            update(() => {
                this.fileTemplateOptions = options;
            });
        },
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async ok() {
            const isValid = await this.$refs.form.validate(true);
            if (!isValid) {
                return;
            }

            this.$emit('ok', {
                fileTemplate: this.fileTemplate,
                name: this.name,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
    },
};
