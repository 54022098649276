//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        value: {
            type: String,
            default: '""',
        },
        template: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        rules() {
            if (this.template.required) {
                return [v => !!v || 'Pflichtfeld'];
            }

            return [];
        },
    },
    methods: {
        parseValue(value) {
            try {
                return  JSON.parse(value);
            } catch (e) {
                return '';
            }
        },
        input(value) {
            this.$emit('input', JSON.stringify(value));
        },
        validate() {
            return this.$refs.input.validate();
        },
        resetValidation() {
            return this.$refs.input.resetValidation();
        },
        focus() {
            this.$refs.input.focus();
        },
    },
};
