var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{attrs:{"padding":""}},[_c('div',{staticClass:"row no-wrap items-start"},[_c('mmm-navigation-back-button',{attrs:{"to":{ name: 'admin-upload-dashboard' }}}),_c('h1',[_vm._v("Vorgangsübersicht")])],1),(_vm.pageData)?_c('q-table',{attrs:{"title":"Vorgänge","row-key":"@id","data":_vm.pageData['hydra:member'],"columns":_vm.computedColumns,"pagination":_vm.tableConfig.pagination,"rows-per-page-options":[5, 10, 15, 20],"separator":"cell"},on:{"update:pagination":function($event){return _vm.$set(_vm.tableConfig, "pagination", $event)},"request":_vm.updateTable,"row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(props){return [_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row justify-between"},[_c('mmm-primary-button',{attrs:{"label":"Neuen Vorgang anlegen"},on:{"click":_vm.createEnquiry}}),_c('div',{staticClass:"row q-gutter-md"},[_c('q-toggle',{attrs:{"label":"Archivierte Vorgänge anzeigen"},on:{"input":function($event){return _vm.updateTable(props, true)}},model:{value:(_vm.tableConfig.showArchived),callback:function ($$v) {_vm.$set(_vm.tableConfig, "showArchived", $$v)},expression:"tableConfig.showArchived"}}),_c('q-select',{staticStyle:{"min-width":"200px"},attrs:{"label":"Statusfilter","options":Object.keys(_vm.filteredEnquiryStatusConfig),"multiple":"","outlined":"","dense":"","use-chips":"","clearable":"","color":"primary"},on:{"input":function($event){return _vm.updateTable(props, true)}},scopedSlots:_vm._u([{key:"selected-item",fn:function(ref){
var opt = ref.opt;
var index = ref.index;
var removeAtIndex = ref.removeAtIndex;
return [(false)?_c('enquiry-status',{attrs:{"status":opt},on:{"click":function($event){return removeAtIndex(index)}}}):_vm._e(),_c('q-chip',{attrs:{"size":"sm","removable":""},on:{"remove":function($event){return removeAtIndex(index)}}},[_vm._v(" "+_vm._s(_vm._f("enquiryStatus")(opt))+" ")])]}},{key:"option",fn:function(ref){
var itemProps = ref.itemProps;
var itemEvents = ref.itemEvents;
var opt = ref.opt;
return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_vm._v(" "+_vm._s(_vm._f("enquiryStatus")(opt))+" ")])]}}],null,true),model:{value:(_vm.tableConfig.statusFilter),callback:function ($$v) {_vm.$set(_vm.tableConfig, "statusFilter", $$v)},expression:"tableConfig.statusFilter"}}),_c('q-input',{attrs:{"outlined":"","dense":"","debounce":"500","color":"primary","placeholder":"Suche","clearable":""},on:{"input":function($event){return _vm.updateTable(props, true)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"fal fa-search","color":"primary"}})]},proxy:true}],null,true),model:{value:(_vm.tableConfig.search),callback:function ($$v) {_vm.$set(_vm.tableConfig, "search", $$v)},expression:"tableConfig.search"}})],1)],1)])]}},{key:"body-cell-status",fn:function(ref){
var row = ref.row;
return [_c('q-td',[_c('enquiry-status',{attrs:{"status":row.status}})],1)]}}],null,false,303434313)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }