import { render, staticRenderFns } from "./FinancingProjectStatusBadge.vue?vue&type=template&id=3ee00794&"
import script from "./FinancingProjectStatusBadge.vue?vue&type=script&lang=js&"
export * from "./FinancingProjectStatusBadge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBadge} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBadge})
