//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date } from 'quasar';
import ValutierungService from '@/api/valutierung.service';
import { centToEuro } from '@/plugins/filter';
import CustomerService from '@/api/customer.service';
import CustomerInviteFirst from './CustomerInviteFirst';
import configStore from '@/store/modules/configStore';

export default {
    name: 'CustomerProjectDetails',
    data() {
        return {
            projectDetails: {
                minimumDisbursementAmount: 0,
            },
            editableProject: false,
            editProject: false,
            customerId: '',
            columns: [
                { name: 'name', label: 'Name', field: 'name', sortable: true },
                { name: 'creditNumber', label: 'Kreditnummer', field: 'creditNumber', sortable: true },
                {
                    name: 'description',
                    label: 'Kreditbeschreibung',
                    field: 'description',
                    sortable: true,
                    style: 'white-space: normal',
                },
                {
                    name: 'amount',
                    label: 'Gesamtbetrag',
                    field: 'amount',
                    sortable: true,
                    format: v => centToEuro(v),
                },
                {
                    name: 'remainingAmount',
                    label: 'Restauszahlungsbetrag',
                    field: 'remainingAmount',
                    sortable: true,
                    format: v => centToEuro(v),
                },
                {
                    name: 'category.name',
                    label: 'Kategorie',
                    field: row => row.category?.name,
                    sortable: true,
                },
            ],
            columnsValutierungen: [
                { name: 'name', label: 'Name', field: 'name', sortable: true },
                { name: 'status', label: 'Status', field: 'status', sortable: true },
                {
                    name: 'amount',
                    label: 'Betrag',
                    field: 'amount',
                    sortable: true,
                    format: v => centToEuro(v),
                },
                {
                    name: 'createdAt',
                    label: 'Erstellt am',
                    field: 'createdAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
                {
                    name: 'submittedAt',
                    label: 'Abgeschickt am',
                    field: 'submittedAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
                {
                    name: 'updatedAt',
                    label: 'Geändert am',
                    field: 'updatedAt',
                    sortable: true,
                    format: v => date.formatDate(v, 'YYYY-MM-DD HH:mm:ss'),
                },
            ],
        };
    },
    computed: {
        computedColumns() {
            const computedColumns = this.columns;
            computedColumns.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });

            return computedColumns;
        },
        computedColumnsValutierungen() {
            const computedColumnsValutierungen = this.columnsValutierungen;
            computedColumnsValutierungen.forEach(column => {
                column.align = column.align ?? 'left';
                column.classes = column.classes ?? 'ellipsis';
                column.headerClasses = (column.name)
                    ? 'bg-primary text-primary-invert'
                    : 'bg-grey-3';
            });
            return computedColumnsValutierungen;
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    methods: {
        edit() {
            this.editProject = true;
        },
        async patchProject() {
            this.$q.loading.show();
            try {
                await ValutierungService.patchFinancingProject(this.projectDetails.id, {
                    publicId: this.projectDetails.publicId,
                    name: this.projectDetails.name,
                    description: this.projectDetails.description,
                    minimumDisbursementAmount: this.projectDetails.minimumDisbursementAmount,
                    maxDisbursements: parseInt(this.projectDetails.maxDisbursements),
                });
                this.$notify('Das Vorhaben wurde erfolgreich bearbeitet.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            this.editProject = false;
            this.$q.loading.hide();
        },
        async activate() {
            this.$q.loading.show();
            try {
                await ValutierungService.patchFinancingProject(this.projectDetails.id, {
                    status: 'active',
                });
                this.$notify('Das Vorhaben wurde erfolgreich aktiviert.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            await this.refresh();
            this.editableProject = false;
            this.$q.loading.hide();
        },
        async checkCustomerInvited() {
            const { data: customer } = await CustomerService.getCustomer(this.customerId);
            if(!customer.invitedAt && !customer.activatedAt) {
                this.$q.dialog({
                    parent: this,
                    component: CustomerInviteFirst,
                }).onOk(() => {
                    this.activate();
                });
            } else {
                await this.activate();
            }
        },
        cancel() {
            this.editProject = false;
        },
        rowClickValutierung(event, row) {
            this.$router.push({ name: 'admin-valutierung-disbursement', params: { id: row.id } });
        },
        rowClickCredit: function (event, row) {
            this.$router.push({
                name: 'admin-valutierung-credit-details',
                params: { creditId: row.id, edit: this.editableProject },
            });
        },
        async refresh() {
            const { data: project } = await ValutierungService.getFinancingProjectById(this.projectDetails.id);
            this.projectDetails = project;

        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const { data: project } = await ValutierungService.getFinancingProjectById(to.params.vorhabenId);
            const isEditable = project.status === 'draft';
            next(vm => {
                vm.projectDetails = project;
                vm.editableProject = isEditable;
                vm.customerId = to.params.id;
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
