//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ValutierungService from '@/api/valutierung.service';
import DisbursementStatusBadge from '@/components/valutierung/DisbursementStatusBadge';
import DeleteDisbursementDialog from '@/components/valutierung/dialogs/DeleteDisbursementDialog';
import FinancingProjectStatusBadge from '@/components/valutierung/FinancingProjectStatusBadge';

export default {
    components: { FinancingProjectStatusBadge, DisbursementStatusBadge },
    data() {
        return {
            financingProject: {},
        };
    },
    computed: {
        remainingAmountsPerFinancingProjectType() {
            const remainingAmountsPerFinancingProjectType = [];

            for (const fptId in this.financingProject.remainingAmountsPerFinancingProjectType) {
                const fpt = this.financingProject.financingProjectTypes.find((item) => item.id === fptId);
                remainingAmountsPerFinancingProjectType.push({
                    financingProjectType: fpt,
                    remainingAmount: this.financingProject.remainingAmountsPerFinancingProjectType[fptId],
                });
            }

            return remainingAmountsPerFinancingProjectType;
        },
    },
    methods: {
        deleteDisbursement(disbursement) {
            this.$q.dialog({
                parent: this,
                component: DeleteDisbursementDialog,
            }).onOk(async () => {
                this.$q.loading.show();
                try {
                    await ValutierungService.deleteDisbursement(disbursement.id);
                    const { data } = await ValutierungService.getFinancingProjectById(this.$route.params.id);
                    this.financingProject = data;
                    this.$notify('Auszahlungsantrag wurde erfolgreich gelöscht.');
                } catch (e) {
                    this.$handleError(e);
                }
                this.$q.loading.hide();
            });
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const { data: financingProject } = await ValutierungService.getFinancingProjectById(to.params.id);
            next(vm => {
                vm.financingProject = financingProject;
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
