//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date } from 'quasar';

export default {
    props: {
        value: {
            type: String,
            default: '""',
        },
        template: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        rules() {
            const rules = [
                v => {
                    if (v === '' || v === null) {
                        return true;
                    }

                    // check format
                    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
                    if (!regex.test(v)) {
                        return 'Bitte geben Sie ein valides Datum (TT.MM.JJJJ) ein.';
                    }


                    // check for valid day/month combinations
                    const matches = regex.exec(v);
                    if (isNaN(Date.parse(`${matches[3]}/${matches[2]}/${matches[1]}`))) {
                        return 'Bitte geben Sie ein valides Datum (TT.MM.JJJJ) ein.';
                    }

                    return true;
                },
            ];

            if (this.template.required) {
                rules.push(v => !!v || 'Pflichtfeld');
            }

            if ((this.template.minDate ?? null) !== null) {
                const min = date.formatDate(date.subtractFromDate(new Date(), { days: this.template.minDate }), 'YYYY/MM/DD');
                rules.push(v => {
                    if (v === '' || v === null) {
                        return true;
                    }

                    if (date.formatDate(date.extractDate(v, 'DD.MM.YYYY'), 'YYYY/MM/DD') >= min) {
                        return true;
                    }

                    return `Datum liegt zu weit in der Vergangenheit (mehr als ${this.template.minDate} Tage).`;
                });
            }

            if ((this.template.maxDate ?? null) !== null) {
                const max = date.formatDate(date.addToDate(new Date(), { days: this.template.maxDate }), 'YYYY/MM/DD');
                rules.push(v => {
                    if (v === '' || v === null) {
                        return true;
                    }

                    if (date.formatDate(date.extractDate(v, 'DD.MM.YYYY'), 'YYYY/MM/DD') <= max) {
                        return true;
                    }

                    return `Datum liegt zu weit in der Zukunft (mehr als ${this.template.maxDate} Tage).`;
                });
            }

            return rules;
        },
    },
    methods: {
        parseValue(value) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return '';
            }
        },
        input(value) {
            this.$emit('input', JSON.stringify(value));
        },
        options(dateToCheck) {
            let check = true;

            if ((this.template.minDate ?? null) !== null) {
                const min = date.formatDate(date.subtractFromDate(new Date(), { days: this.template.minDate }), 'YYYY/MM/DD');
                check = check && dateToCheck >= min;
            }

            if ((this.template.maxDate ?? null) !== null) {
                const max = date.formatDate(date.addToDate(new Date(), { days: this.template.maxDate }), 'YYYY/MM/DD');
                check = check && dateToCheck <= max;
            }

            return check;
        },
        validate() {
            return this.$refs.input.validate();
        },
        resetValidation() {
            return this.$refs.input.resetValidation();
        },
        focus() {
            this.$refs.input.focus();
        },
    },
};
