//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date, Loading } from 'quasar';
import configStore from '@/store/modules/configStore';
import downloadBlobResponse from '@/util/downloadBlobResponse';
import enquiryStore from '@/store/modules/enquiryStore';
import StatusChangeHistory from '@/components/upload/StatusChangeHistory';
import PdfPreviewDialog from '@/components/PdfPreviewDialog';
import EnquiryAttachmentsService from '@/api/enquiryAttachments.service';

export default {
    components: {
        StatusChangeHistory,
    },
    data() {
        return {
            showHistory: false,
        };
    },
    computed: {
        debug() {
            return this.$route.query.debug !== undefined;
        },
        ready() {
            if (this.enquiry.requestedFiles.some(file => file.status === 'uploaded')) {
                return false;
            }

            return this.enquiry.requestedFiles.every(file => !file.required || file.status !== 'requested');
        },
        nextAction() {
            if (this.enquiry.status !== 'open') {
                return 'Keine Handlung von Ihnen nötig.';
            }

            if (this.ready) {
                return 'Sie können den Vorgang nun abschließen.';
            }

            if (this.enquiry.requestedFiles.some(file => file.required && file.status === 'requested')) {
                if (this.enquiry.expiresAt) {
                    return `Bitte laden Sie bis zum ${date.formatDate(this.enquiry.expiresAt, 'DD.MM.YYYY')} die benötigten Unterlagen hoch.`;
                }

                return 'Bitte laden Sie die benötigten Unterlagen hoch.';
            }

            if (this.enquiry.requestedFiles.some(file => file.status === 'uploaded')) {
                return 'Bitte bestätigen sie Ihre hochgeladenen Dokumente, damit Ihr Berater die Prüfung beginnen kann.';
            }

            return '[In Arbeit] Aktionsbeschreibung fehlt noch';
        },
        context() {
            let message = this.lastEnquiryStatusChange?.context?.message;
            let comment = this.lastEnquiryStatusChange?.context?.comment;

            return {
                show: message && ['customer', 'access_token'].indexOf(this.lastEnquiryStatusChange?.initiatedBy) === -1,
                message,
                comment,
                colorClass: ['open', 'expired', 'aborted'].indexOf(this.enquiry.status) ? 'text-negative' : 'text-primary',
            };
        },
        items() {
            return [
                {
                    key: 'requested',
                    label: 'Fehlende Dokumente',
                    count: this.filesWithStatus('requested').length,
                },
                {
                    key: 'uploaded',
                    label: 'Hochgeladene Dokumente, die auf Ihre Bestätigung warten',
                    count: this.filesWithStatus('uploaded').length,
                },
                {
                    key: 'problem',
                    label: 'Dokumente mit Klärungsbedarf',
                    count: this.filesWithStatus(['problem']).length,
                },
                {
                    key: 'ready,verified,processed,archived',
                    label: 'Erledigte Dokumente',
                    count: this.filesWithStatus(['ready', 'verified', 'processed', 'archived']).length,
                },
            ].filter(item => item.count);
        },
        publicEnquiryAttachments() {
            return (this.enquiry?.enquiryAttachments ?? []).filter((enquiryAttachment) => enquiryAttachment.public);
        },
        mergedStatusChanges() {
            return [
                ...this.enquiryStatusChanges,
                ...this.fileStatusChanges,
            ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        },
        ...enquiryStore.mapState({
            enquiryStatusChanges: enquiryStore.STATE.CURRENT_ENQUIRY_STATUS_CHANGES,
            fileStatusChanges: enquiryStore.STATE.CURRENT_ENQUIRY_FILE_STATUS_CHANGES,
        }),
        ...enquiryStore.mapGetters({
            enquiry: enquiryStore.GETTERS.CURRENT_ENQUIRY,
            files: enquiryStore.GETTERS.FILES,
            filesWithStatus: enquiryStore.GETTERS.FILES_WITH_STATUS,
            lastEnquiryStatusChange: enquiryStore.GETTERS.LAST_ENQUIRY_STATUS_CHANGE,
        }),
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        activateEnquiryAttachments() {
            return this.config?.upload?.activateEnquiryAttachments;
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            await Promise.all([
                enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY, to.params.id),
                enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_STATUS_CHANGES, to.params.id),
                enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_FILE_STATUS_CHANGES, to.params.id),
            ]);
            next();
        } catch (error) {
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY);
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY_STATUS_CHANGES);
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY_FILE_STATUS_CHANGES);
            next({ name: 'customer-hub' });
        }
    },
    methods: {
        async complete() {
            this.$q.loading.show();
            try {
                await enquiryStore.dispatch(enquiryStore.ACTIONS.COMPLETE_ENQUIRY, this.$route.params.id);
                await enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY_STATUS_CHANGES, this.$route.params.id);
                this.$notify('Vorgang wurde erfolgreich abgeschlossen.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        viewEnquiryAttachment(enquiryAttachment) {
            this.$q.dialog({
                parent: this,
                component: PdfPreviewDialog,
                url: `/api/enquiry-attachments/${enquiryAttachment.id}/preview`,
            });
        },
        async downloadEnquiryAttachment(enquiryAttachment) {
            Loading.show();
            const response = await EnquiryAttachmentsService.downloadEnquiryAttachment(enquiryAttachment);
            downloadBlobResponse(response);
            Loading.hide();
        },
    },
};
