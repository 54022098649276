import ApiClient from '@/api/_apiClient';

const FileCategoriesService = {
    getFileCategories(search = '', page = 1, itemsPerPage = 10, orderBy = 'sortIndex', order = 'asc') {
        return ApiClient.get('file-categories', {
            params: {
                'name': search,
                'page': page,
                'items-per-page': itemsPerPage,
                [`order[${orderBy}]`]: order,
            },
        });
    },
    postFileCategory(fileCategory) {
        return ApiClient.post('file-categories', fileCategory);
    },
    patchFileCategory(fileCategory) {
        return ApiClient.patch(`file-categories/${fileCategory.id}`, fileCategory);
    },
    deleteFileCategory(fileCategory) {
        return ApiClient.delete(`file-categories/${fileCategory.id}`);
    },
};

export default FileCategoriesService;
