const fileStatusConfig = {
    requested: {
        sortOrder: 1,
        text: 'Angefragt',
        color: 'grey-3',
        textColor: 'primary',
    },
    uploaded: {
        sortOrder: 2,
        text: 'Hochgeladen',
        color: 'grey-3',
        textColor: 'primary',
    },
    ready: {
        sortOrder: 3,
        text: 'Bereit',
        color: 'grey-3',
        textColor: 'positive',
    },
    verified: {
        sortOrder: 4,
        text: 'Geprüft',
        color: 'positive',
        textColor: 'positive-invert',
    },
    processed: {
        sortOrder: 5,
        text: 'Verarbeitet',
        color: 'positive',
        textColor: 'positive-invert',
    },
    archived: {
        sortOrder: 6,
        text: 'Archiviert',
        color: 'positive',
        textColor: 'positive-invert',
    },
    problem: {
        sortOrder: 7,
        text: 'Klärung',
        color: 'negative',
        textColor: 'negative-invert',
    },
};

export default fileStatusConfig;
