import _ from 'lodash';
import CustomerService from '@/api/customer.service';
import _moduleHelper from '@/store/modules/_moduleHelper';

export const NAMESPACE = 'CUSTOMER';

export const STATE = {
    TOKEN: 'TOKEN',
    TOKEN_PAYLOAD: 'TOKEN_PAYLOAD',
    LOGGED_IN_CUSTOMER: 'LOGGED_IN_CUSTOMER',
};

export const GETTERS = {
    IS_LOGGED_IN: 'IS_LOGGED_IN',
    REFRESH_NEEDED: 'REFRESH_NEEDED',
    LOGGED_IN_CUSTOMER_LOADED: 'LOGGED_IN_CUSTOMER_LOADED',
    ROLES: 'ROLES',
    HAS_ROLE: 'HAS_ROLE',
};

export const MUTATIONS = {
    SET_TOKEN: 'SET_TOKEN',
    RESET_TOKEN: 'RESET_TOKEN',
    SET_TOKEN_PAYLOAD: 'SET_TOKEN_PAYLOAD',
    RESET_TOKEN_PAYLOAD: 'RESET_TOKEN_PAYLOAD',
    SET_LOGGED_IN_CUSTOMER: 'SET_LOGGED_IN_CUSTOMER',
    RESET_LOGGED_IN_CUSTOMER: 'RESET_LOGGED_IN_CUSTOMER',
};

export const ACTIONS = {
    LOGIN: 'LOGIN',
    LOGIN_VIA_TOKEN: 'LOGIN_VIA_TOKEN',
    LOGOUT: 'LOGOUT',
    REFRESH: 'REFRESH',
    REFRESH_LOGGED_IN_CUSTOMER: 'REFRESH_LOGGED_IN_CUSTOMER',
    REGISTER_WITH_INVITATION_CODE: 'REGISTER_WITH_INVITATION_CODE',
    REQUEST_ACTIVATION_CODE: 'REQUEST_ACTIVATION_CODE',
    ACTIVATE: 'ACTIVATE',
    REQUEST_PASSWORD_RESET: 'REQUEST_PASSWORD_RESET',
    RESET_PASSWORD: 'RESET_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

export const MODULE = {
    namespaced: true,
    state: {
        [STATE.TOKEN]: null,
        [STATE.TOKEN_PAYLOAD]: {},
        [STATE.LOGGED_IN_CUSTOMER]: {},
    },
    getters: {
        [GETTERS.IS_LOGGED_IN](state) {
            return !!state[STATE.TOKEN_PAYLOAD].id;
        },
        [GETTERS.REFRESH_NEEDED](state, getter) {
            return getter[GETTERS.IS_LOGGED_IN] && state[STATE.TOKEN] === null;
        },
        [GETTERS.LOGGED_IN_CUSTOMER_LOADED](state) {
            return Object.keys(state[STATE.LOGGED_IN_CUSTOMER]).length > 0;
        },
        [GETTERS.ROLES](state) {
            return state[STATE.TOKEN_PAYLOAD].roles ?? [];
        },
        [GETTERS.HAS_ROLE](state) {
            return (roles, customer = undefined, all = false) => {
                let customerRoles;
                if (customer === undefined) {
                    customerRoles = _.uniq([...(state[STATE.TOKEN_PAYLOAD].roles ?? []), ...(state[STATE.TOKEN_PAYLOAD].inheritedRoles ?? [])]);
                } else {
                    customerRoles = _.uniq([...(customer.roles ?? []), ...(customer.inheritedRoles ?? [])]);
                }

                if (!Array.isArray(roles)) {
                    roles = [roles];
                }

                const checks = [];
                roles.forEach((role) => checks.push(customerRoles.includes(role)));

                if (all) {
                    return checks.every(v => !!v);
                }

                return checks.some(v => !!v);
            };
        },
    },
    mutations: {
        [MUTATIONS.SET_TOKEN](state, token) {
            state[STATE.TOKEN] = token;
        },
        [MUTATIONS.RESET_TOKEN](state) {
            state[STATE.TOKEN] = null;
        },
        [MUTATIONS.SET_TOKEN_PAYLOAD](state, payload) {
            state[STATE.TOKEN_PAYLOAD] = Object.assign({}, payload);
        },
        [MUTATIONS.RESET_TOKEN_PAYLOAD](state) {
            state[STATE.TOKEN_PAYLOAD] = {};
        },
        [MUTATIONS.SET_LOGGED_IN_CUSTOMER](state, payload) {
            state[STATE.LOGGED_IN_CUSTOMER] = Object.assign({}, payload);
        },
        [MUTATIONS.RESET_LOGGED_IN_CUSTOMER](state) {
            state[STATE.LOGGED_IN_CUSTOMER] = {};
        },
    },
    actions: {
        async [ACTIONS.LOGIN]({ commit, state }, { email, password }) {
            let { data } = await CustomerService.login(email, password);
            await handleToken(data.token, commit, state);
        },
        async [ACTIONS.LOGIN_VIA_TOKEN]({ commit, state, dispatch }, token) {
            await handleToken(token, commit, state);
            await dispatch(ACTIONS.REFRESH);
        },
        async [ACTIONS.LOGOUT]({ commit }) {
            await CustomerService.logout();
            commit(MUTATIONS.RESET_TOKEN);
            commit(MUTATIONS.RESET_TOKEN_PAYLOAD);
            commit(MUTATIONS.RESET_LOGGED_IN_CUSTOMER);
        },
        async [ACTIONS.REFRESH]({ commit, state }) {
            let { data } = await CustomerService.refresh();
            await handleToken(data.token, commit, state);
        },
        async [ACTIONS.REFRESH_LOGGED_IN_CUSTOMER]({ commit, state }) {
            let { data } = await CustomerService.getCustomer(state[STATE.TOKEN_PAYLOAD]?.id ?? '404');
            commit(MUTATIONS.SET_LOGGED_IN_CUSTOMER, data);
        },
        async [ACTIONS.REGISTER_WITH_INVITATION_CODE]({ commit, state, dispatch }, { invitationCode, password }) {
            let { data } = await CustomerService.registerWithInvitationCode(invitationCode, password);
            await handleToken(data.token, commit, state);
            await dispatch(ACTIONS.REFRESH);
        },
        [ACTIONS.REQUEST_ACTIVATION_CODE]({ state }) {
            return CustomerService.requestActivationCode(state[STATE.TOKEN_PAYLOAD]?.id ?? '404');
        },
        async [ACTIONS.ACTIVATE]({ dispatch, state }, activationCode) {
            await CustomerService.activate(state[STATE.TOKEN_PAYLOAD]?.id ?? '404', activationCode);
            await dispatch(ACTIONS.REFRESH_LOGGED_IN_CUSTOMER);
            await dispatch(ACTIONS.REFRESH);
        },
        [ACTIONS.REQUEST_PASSWORD_RESET](_, email) {
            return CustomerService.requestPasswordReset(email);
        },
        [ACTIONS.RESET_PASSWORD](_, { passwordResetCode, password }) {
            return CustomerService.resetPassword(passwordResetCode, password);
        },
        [ACTIONS.CHANGE_PASSWORD](_, { currentPassword, newPassword }) {
            return CustomerService.changePassword(currentPassword, newPassword);
        },
    },
};

export default {
    NAMESPACE,
    STATE,
    GETTERS,
    MUTATIONS,
    ACTIONS,
    MODULE,
    ..._moduleHelper(NAMESPACE),
};

async function handleToken(token, commit, state) {
    commit(MUTATIONS.SET_TOKEN, token);

    const parts = token.split('.');
    commit(MUTATIONS.SET_TOKEN_PAYLOAD, JSON.parse(atob(parts[1])));

    let { data } = await CustomerService.getCustomer(state[STATE.TOKEN_PAYLOAD].id);
    commit(MUTATIONS.SET_LOGGED_IN_CUSTOMER, data);
}
