import ConfigService from '@/api/config.service';
import _moduleHelper from '@/store/modules/_moduleHelper';

export const NAMESPACE = 'CONFIG';

export const STATE = {
    CONFIG: 'CONFIG',
    FOOTER_URLS: 'FOOTER_URLS',
};

export const GETTERS = {
    CONFIG_LOADED: 'CONFIG_LOADED',
};

export const MUTATIONS = {
    SET_CONFIG: 'SET_CONFIG',
    SET_FOOTER_URLS: 'SET_FOOTER_URLS',
};

export const ACTIONS = {
    FETCH_CURRENT: 'FETCH_CURRENT',
    FETCH_FOOTER_URLS: 'FETCH_FOOTER_URLS',
};

export const MODULE = {
    namespaced: true,
    state: {
        [STATE.CONFIG]: {},
        [STATE.FOOTER_URLS]: [],
    },
    getters: {
        [GETTERS.CONFIG_LOADED](state) {
            return Object.keys(state[STATE.CONFIG]).length > 0;
        },
    },
    mutations: {
        [MUTATIONS.SET_CONFIG](state, config) {
            state[STATE.CONFIG] = Object.assign({}, config);
        },
        [MUTATIONS.SET_FOOTER_URLS](state, footerUrls) {
            state[STATE.FOOTER_URLS] = footerUrls;
        },
    },
    actions: {
        async [ACTIONS.FETCH_CURRENT]({ commit }) {
            let { data } = await ConfigService.fetchCurrent();
            commit(MUTATIONS.SET_CONFIG, data);

            return data;
        },
        async [ACTIONS.FETCH_FOOTER_URLS]({ commit }) {
            let { data } = await ConfigService.fetchFooterUrls();
            commit(MUTATIONS.SET_FOOTER_URLS, data['hydra:member']);

            return data;
        },
    },
};

export default {
    NAMESPACE,
    STATE,
    GETTERS,
    MUTATIONS,
    ACTIONS,
    MODULE,
    ..._moduleHelper(NAMESPACE),
};
