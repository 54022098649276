//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ValutierungService from '@/api/valutierung.service';

export default {
    name: 'CustomerCreditNew',
    data() {
        return {
            creditName: '',
            creditDescription: '',
            creditNumber: '',
            amount: 0,
            creditCategory: '',
            projectTypes: [],
            creditCategoryOptions: [],
            projectTypeOptions: [],
            projectId: '',
        };
    },
    methods: {
        async addCredit() {
            const types = [];
            for (let id in this.projectTypes) {
                types.push(this.projectTypes[id]['@id']);
            }
            this.$q.loading.show();
            try {
                await ValutierungService.postCredit({
                    name: this.creditName,
                    description: this.creditDescription,
                    creditNumber: this.creditNumber,
                    amount: this.amount,
                    financingProject: this.projectId,
                    category: this.creditCategory?.['@id'] ?? null,
                    financingProjectTypes: types,
                });
                await this.$router.push({ name: 'admin-valutierung-project-details' });
                this.$notify('Der Kredit wurde erfolgreich angelegt.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const { data: categoryTypes } = await ValutierungService.getCreditCategories();
            const { data: projectTypes } = await ValutierungService.getFinancingProjectTypes();

            next(vm => {
                vm.creditCategoryOptions = categoryTypes['hydra:member'];
                vm.projectTypeOptions = projectTypes['hydra:member'];
                vm.projectId = '/api/valutierung-financing-projects/' + to.params.vorhabenId;
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
