//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ValutierungService from '@/api/valutierung.service';
import configStore from '@/store/modules/configStore';
import moment from 'moment';
import { centToEuro } from '@/plugins/filter';

export default {
    data() {
        return {
            financingProject: {
                credits: [],
            },
            disbursementRequest: {
                name: '',
                disbursementPositions: [
                    {
                        creditId: null,
                        financingProjectTypeId: null,
                        amount: 0,
                    },
                ],
            },
        };
    },
    computed: {
        creditOptions() {
            const options = [];
            if (this.financingProject.credits.length > 1) {
                options.push({ value: null, label: '--Bitte wählen--' });
            }
            this.financingProject.credits.forEach(credit => {
                if (credit.remainingAmount > 0) {
                    options.push({
                        value: credit.id,
                        label: `${credit.creditNumber} - ${credit.name}`,
                        selected: this.disbursementRequest.disbursementPositions.some((position) => position.creditId === credit.id),
                    });
                }
            });

            return options;
        },
        financingProjectTypeOptionsByCreditId() {
            return (creditId) => {
                const options = [];
                const credit = this.creditById(creditId);

                if (credit?.financingProjectTypes?.length > 1) {
                    options.push({ value: null, label: '--Bitte wählen--' });
                }

                credit?.financingProjectTypes.forEach(financingProjectType => {
                    options.push({
                        value: financingProjectType.id,
                        label: financingProjectType.name,
                    });
                });

                return options;
            };
        },
        creditById() {
            return (id) => this.financingProject.credits.find(credit => credit.id === id || credit['@id'] === id);
        },
        financingProjectTypeById() {
            return (id) => this.financingProject.financingProjectTypes.find(type => type.id === id || type['@id'] === id);
        },
        minimumDisbursementAmount() {
            if (this.financingProject.minimumDisbursementAmount) {
                return this.financingProject.minimumDisbursementAmount;
            }

            return this.config.valutierung.defaultMinimumDisbursementAmount ?? 500000;
        },
        minimumDisbursementAmountByCreditId() {
            return (creditId) => {
                if (this.availableAmountByCreditId(creditId) < this.minimumDisbursementAmount) {
                    return this.availableAmountByCreditId(creditId);
                }

                return this.minimumDisbursementAmount;
            };
        },
        availableAmountByCreditId() {
            return (creditId) => {
                const credit = this.creditById(creditId);

                return credit?.remainingAmount ?? 0;
            };
        },
        maximumAmountByFinancingProjectTypeId() {
            return (value, financingProjectTypeId) => {
                const financingProjectType = this.financingProjectTypeById(financingProjectTypeId);

                if (value <= (financingProjectType?.maximumDisbursementAmount ?? value)) {
                    return true;
                }

                return `Betrag übersteigt maximalen Auszahlungs-Betrag (${centToEuro(financingProjectType?.maximumDisbursementAmount)}) des Zwecks`;
            };
        },
        maximumTotalAmountByFinancingProjectTypeId() {
            return (value, financingProjectTypeId) => {
                if (value <= (this.financingProject?.remainingAmountsPerFinancingProjectType?.[financingProjectTypeId] ?? value)) {
                    return true;
                }

                return `Betrag übersteigt maximale Auszahlungs-Summe (${centToEuro(this.financingProject?.remainingAmountsPerFinancingProjectType[financingProjectTypeId])}) des Zwecks`;
            };
        },
        infoForPosition() {
            return (index) => {
                let info = [];

                const position = this.disbursementRequest.disbursementPositions[index];

                if (position.financingProjectTypeId) {
                    const financingProjectType = this.financingProjectTypeById(position.financingProjectTypeId);
                    if (financingProjectType.infotext && financingProjectType.name) {
                        info.push(
                            `**Hinweis zu einer Auszahlung mit dem Zweck "${financingProjectType.name}":**\n\n` +
                                financingProjectType.infotext
                        );
                    }
                }

                if (position.creditId) {
                    const credit = this.creditById(position.creditId);
                    if (credit?.category?.infotext && credit?.category?.name) {
                        info.push(
                            `**Hinweis zu einer Auszahlung aus einem Kredit der Kategorie "${credit.category.name}":**\n\n` +
                                credit.category.infotext
                        );
                    }
                }

                return info.join('\n\n');
            };
        },
        isFinalDisbursement() {
            return this.financingProject.remainingDisbursements <= 1;
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
    },
    mounted() {
        this.generateName();
        if (this.isFinalDisbursement) {
            this.prefillPositions();
        }
    },
    methods: {
        addPosition() {
            this.disbursementRequest.disbursementPositions.push(
                {
                    creditId: null,
                    financingProjectTypeId: null,
                    amount: 0,
                },
            );
            if (this.financingProject.credits.length === 1) {
                const index = this.disbursementRequest.disbursementPositions.length - 1;
                this.disbursementRequest.disbursementPositions[index].creditId = this.financingProject.credits[0].id;
                this.$nextTick(() => {
                    this.creditInput(index);
                });
            }
        },
        removePosition(index) {
            this.disbursementRequest.disbursementPositions.splice(index, 1);
        },
        creditInput(index) {
            const creditId = this.disbursementRequest.disbursementPositions[index].creditId;
            this.disbursementRequest.disbursementPositions[index].financingProjectTypeId
                = this.financingProjectTypeOptionsByCreditId(creditId)[0]?.value ?? null;
        },
        generateName() {
            this.disbursementRequest.name = `${this.isFinalDisbursement ? 'Finaler ' : ''}Auszahlungsantrag vom ${moment().format('DD.MM.YYYY - hh:mm:ss [Uhr]')}`;
        },
        prefillPositions() {
            this.disbursementRequest.disbursementPositions = [];

            this.financingProject.credits.forEach((credit, index) => {
                this.disbursementRequest.disbursementPositions.push({
                    creditId: credit.id,
                    financingProjectTypeId: null,
                    amount: credit.remainingAmount,
                });
                this.creditInput(index);
            });
        },
        async submit() {
            if (!this.validateFinancingProjectTypeSums()) {
                return;
            }

            if (!this.validateSums()) {
                this.$notify(
                    'Ihre Auszahlungspositionen würden aufsummiert einen Ihrer Kredite überziehen.',
                    'negative'
                );

                return;
            }

            this.$q.loading.show();
            try {
                const { data: disbursement } = await ValutierungService.createDisbursement(this.financingProject.id, this.disbursementRequest);
                await this.$router.push({
                    name: 'customer-disbursement',
                    params: {
                        financingProjectId: this.financingProject.id,
                        disbursementId: disbursement.id,
                    },
                    query: {
                        next: null,
                    },
                });
                this.$notify(
                    'Ihr Auszahlungsantrag wurde angelegt und die nötigen Angaben und Nachweise können nun eingereicht werden.',
                    'positive'
                );
            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
        validateFinancingProjectTypeSums() {
            const amountPerFinancingProjectType = {};
            this.disbursementRequest.disbursementPositions.forEach(position => {
                if (amountPerFinancingProjectType[position.financingProjectTypeId] === undefined) {
                    amountPerFinancingProjectType[position.financingProjectTypeId] = 0;
                }

                amountPerFinancingProjectType[position.financingProjectTypeId] += position.amount;
            });

            return Object.entries(amountPerFinancingProjectType).every(([financingProjectTypeId, amount]) => {
                const financingProjectType = this.financingProjectTypeById(financingProjectTypeId);
                let allowedAmount = financingProjectType?.maximumDisbursementAmount ?? false;
                if (amount > (allowedAmount || amount)) {
                    this.$notify(
                        `Ihre Auszahlungspositionen würden aufsummiert den maximalen Betrag von ${centToEuro(allowedAmount)} pro Auszahlung des Zwecks "${financingProjectType.name}" überschreiten.`,
                        'negative'
                    );

                    return false;
                }

                allowedAmount = this.financingProject?.remainingAmountsPerFinancingProjectType[financingProjectTypeId] ?? false;
                if (amount > (allowedAmount || amount)) {
                    this.$notify(
                        `Ihre Auszahlungspositionen würden aufsummiert die noch verfügbare Summe von ${centToEuro(allowedAmount)} des Zwecks "${financingProjectType.name}" überziehen.`,
                        'negative'
                    );

                    return false;
                }

                return true;
            });
        },
        validateSums() {
            const amountPerCredit = {};
            this.disbursementRequest.disbursementPositions.forEach(position => {
                if (amountPerCredit[position.creditId] === undefined) {
                    amountPerCredit[position.creditId] = 0;
                }

                amountPerCredit[position.creditId] += position.amount;
            });

            return Object.entries(amountPerCredit).every(([creditId, amount]) => {
                return this.creditById(creditId).remainingAmount >= amount;
            });
        },
    },
    async beforeRouteEnter(to, from, next) {
        try {
            const { data: financingProject } = await ValutierungService.getFinancingProjectById(to.params.financingProjectId);
            next(vm => {
                vm.financingProject = financingProject;
                if (financingProject.credits.length === 1) {
                    vm.disbursementRequest.disbursementPositions[0].creditId = financingProject.credits[0].id;
                    vm.$nextTick(() => {
                        vm.creditInput(0);
                    });
                }
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
