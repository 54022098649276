//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import fileStore from '@/store/modules/fileStore';
import enquiryStore from '@/store/modules/enquiryStore';
import FileUploader from '@/components/upload/FileUploader';

export default {
    components: { FileUploader },
    computed: {
        ...fileStore.mapState({
            file: fileStore.STATE.CURRENT_FILE,
        }),
        ...fileStore.mapGetters({
            lastFileStatusChange: fileStore.GETTERS.LAST_FILE_STATUS_CHANGE,
        }),
        ...enquiryStore.mapState({
            enquiry: enquiryStore.STATE.CURRENT_ENQUIRY,
        }),
    },
    async beforeRouteEnter(to, from, next) {
        try {
            await Promise.all([
                enquiryStore.dispatch(enquiryStore.ACTIONS.FETCH_ENQUIRY, to.params.id),
                fileStore.dispatch(fileStore.ACTIONS.FETCH_FILE, to.params.fileId),
                fileStore.dispatch(fileStore.ACTIONS.FETCH_FILE_STATUS_CHANGES, to.params.fileId),
            ]);
            next();
        } catch (error) {
            enquiryStore.commit(enquiryStore.MUTATIONS.RESET_CURRENT_ENQUIRY);
            fileStore.commit(fileStore.MUTATIONS.RESET_CURRENT_FILE);
            fileStore.commit(fileStore.MUTATIONS.RESET_CURRENT_FILE_STATUS_CHANGES);
            next({ name: 'customer-hub' });
        }
    },
    methods: {
        async upload(file, files) {
            this.$q.loading.show();
            try {
                await fileStore.dispatch(fileStore.ACTIONS.UPLOAD, {
                    id: file.id,
                    files,
                    onUploadProgress: (event) => this.$refs.uploader.progress(event),
                });
                await fileStore.dispatch(fileStore.ACTIONS.FETCH_FILE, file.id),
                this.$refs.uploader.completeUpload();
                this.$notify('Ihre Datei(en) wurde(n) erfolgreich verarbeitet.', 'positive');
            } catch (error) {
                this.$refs.uploader.uploadError();
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async ready(file) {
            this.$q.loading.show();
            try {
                await fileStore.dispatch(fileStore.ACTIONS.READY, file.id);
                this.$router.back();
                this.$notify('Datei wurde zur Überprüfung freigegeben.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async problem(file, context) {
            this.$q.loading.show();
            try {
                await fileStore.dispatch(fileStore.ACTIONS.PROBLEM, { id: file.id, context });
                await Promise.all([
                    fileStore.dispatch(fileStore.ACTIONS.FETCH_FILE, file.id),
                    fileStore.dispatch(fileStore.ACTIONS.FETCH_FILE_STATUS_CHANGES, file.id),
                ]);
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async unproblem(file, context) {
            this.$q.loading.show();
            try {
                await fileStore.dispatch(fileStore.ACTIONS.UNPROBLEM, { id: file.id, context });
                await Promise.all([
                    fileStore.dispatch(fileStore.ACTIONS.FETCH_FILE, file.id),
                    fileStore.dispatch(fileStore.ACTIONS.FETCH_FILE_STATUS_CHANGES, file.id),
                ]);
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
};
