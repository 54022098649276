//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import bankEmployeeStore from '@/store/modules/bankEmployeeStore';

export default {
    computed: {
        isInUploadModule() {
            return this.$route.matched.some(match => match.path === '/admin/upload');
        },
        roles() {
            return this.loggedInBankEmployee?.roles ?? [];
        },
        ...bankEmployeeStore.mapState({
            loggedInBankEmployee: bankEmployeeStore.STATE.LOGGED_IN_BANK_EMPLOYEE,
        }),
        ...bankEmployeeStore.mapGetters({
            isLoggedIn: bankEmployeeStore.GETTERS.IS_LOGGED_IN,
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
    },
    methods: {
        async logout() {
            await bankEmployeeStore.dispatch(bankEmployeeStore.ACTIONS.LOGOUT);
            await this.$router.push({ name: 'admin-login' });
        },
    },
};
