import { colors } from 'quasar';
import Color from 'color';

function scaleColor(hexValue, lightness = 0.9, alpha = -0.85) {
    let color = Color(hexValue);

    let lightnessDelta = (100 - color.lightness()) * lightness;
    if (lightness < 0) {
        lightnessDelta = color.lightness() * lightness;
    }
    const newLightness = color.lightness() + lightnessDelta;

    let alphaDelta = (1 - color.alpha()) * alpha;
    if (alpha < 0) {
        alphaDelta = color.alpha() * alpha;
    }
    const newAlpha = color.alpha() + alphaDelta;

    const newColor = color.lightness(newLightness).alpha(newAlpha).rgb();

    return `rgba(${Math.round(newColor.red())}, ${Math.round(newColor.green())}, ${Math.round(newColor.blue())}, ${parseFloat(newColor.alpha()).toPrecision(1)})`;
}

async function updateForIE($colors) {
    let { default: cssVars } = await import('css-vars-ponyfill');
    cssVars({
        variables: {
            'q-color-primary': $colors.primary,
            'q-color-primary-invert': $colors.primaryInvert,
            'q-color-secondary': $colors.secondary,
            'q-color-secondary-invert': $colors.secondaryInvert,
            'q-color-accent': $colors.accent,
            'q-color-accent-invert': $colors.accentInvert,
            'q-color-positive': $colors.positive,
            'q-color-positive-invert': $colors.positiveInvert,
            'q-color-negative': $colors.negative,
            'q-color-negative-invert': $colors.negativeInvert,
            'q-color-info': $colors.info,
            'q-color-info-invert': $colors.infoInvert,
            'q-color-warning': $colors.warning,
            'q-color-warning-invert': $colors.warningInvert,
            'q-color-positive-background': scaleColor($colors.positive),
            'q-color-negative-background': scaleColor($colors.negative),
            'q-color-warning-background': scaleColor($colors.warning),
        },
    });
}

export default ($colors) => {
    const userAgent = (window.navigator && window.navigator.userAgent || '').toLowerCase();
    if (userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/)) {
        updateForIE($colors);
        return;
    }
    colors.setBrand('primary', $colors.primary);
    colors.setBrand('primary-invert', $colors.primaryInvert);
    colors.setBrand('secondary', $colors.secondary);
    colors.setBrand('secondary-invert', $colors.secondaryInvert);
    colors.setBrand('accent', $colors.accent);
    colors.setBrand('accent-invert', $colors.accentInvert);
    colors.setBrand('positive', $colors.positive);
    colors.setBrand('positive-invert', $colors.positiveInvert);
    colors.setBrand('negative', $colors.negative);
    colors.setBrand('negative-invert', $colors.negativeInvert);
    colors.setBrand('info', $colors.info);
    colors.setBrand('info-invert', $colors.infoInvert);
    colors.setBrand('warning', $colors.warning);
    colors.setBrand('warning-invert', $colors.warningInvert);
    colors.setBrand('positive-background', scaleColor($colors.positive));
    colors.setBrand('negative-background', scaleColor($colors.negative));
    colors.setBrand('warning-background', scaleColor($colors.warning));
};
