//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { date } from 'quasar';
import FileService from '@/api/file.service';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import PdfPreviewDialog from '@/components/PdfPreviewDialog';

export default {
    name: 'CustomerRequestedFileDetails',
    data() {
        return {
            statusMessage: '',
            editFile: false,
            requestedFile: null,
            categories: null,
            checkExpiresAt: false,
        };
    },
    methods: {
        edit() {
            this.editFile = true;
        },
        async preview() {
            this.$q.dialog({
                parent: this,
                component: PdfPreviewDialog,
                url: '/api/files/' + this.requestedFile.id + '/preview',
            });
        },
        async patchRequestedFile() {
            this.$q.loading.show();
            try {
                if (!this.checkExpiresAt) {
                    this.requestedFile.expiresAt = null;
                }
                await FileService.patchFile(this.requestedFile.id,
                                            {
                                                name: this.requestedFile.name,
                                                description: this.requestedFile.description,
                                                required: this.requestedFile.required,
                                                tooltip: this.requestedFile.tooltip,
                                                sortIndex: this.sortIndex,
                                                fileCategory: this.requestedFile.fileCategory['@id'],
                                                expiresAt: this.requestedFile.expiresAt,
                                            });
                this.$notify('Die Anfrage wurde erfolgreich bearbeitet.', 'positive');
            } catch (e) {
                this.$handleError(e);
            }
            this.editFile = false;
            this.$q.loading.hide();
        },
        cancel() {
            this.editFile = false;
        },
        async changeStatus(status) {
            this.$q.loading.show();
            try {

                await FileService.patchFile(this.requestedFile.id,
                                            {
                                                status: status,
                                                __context: {
                                                    message: this.statusMessage,
                                                },
                                            });
                this.$notify('Die Anfrage wurde erfolgreich bearbeitet.', 'positive');
                this.statusMessage = '';
                await this.refresh();
                if (status === 'verified') {
                    this.$router.back();
                }

            } catch (e) {
                this.$handleError(e);
            }
            this.$q.loading.hide();
        },
        async refresh() {
            let { data: requestedFile } = await FileService.fetchFile(this.requestedFile.id);
            this.requestedFile = requestedFile;
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (!bankEmployeeStore.getters[bankEmployeeStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'admin-login' });
        }

        try {
            let { data: requestedFile } = await FileService.fetchFile(to.params.fileId);
            const { data: categories } = await FileService.fetchCategories();

            let check = false;
            if (requestedFile.expiresAt) {
                check = true;
                requestedFile.expiresAt = date.formatDate(requestedFile.expiresAt, 'DD.MM.YYYY');
            }

            next(vm => {
                vm.requestedFile = requestedFile;
                vm.categories = categories['hydra:member'];
                vm.checkExpiresAt = check;
            });
        } catch (error) {
            next(vm => vm.$handleError(error));
        }
    },
};
