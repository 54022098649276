//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BankEmployeeService from '@/api/bankEmployee.service';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import configStore from '@/store/modules/configStore';
import CustomerService from '@/api/customer.service';

export default {
    name: 'NewCustomer',
    data() {
        return {
            bankEmployee: null,
            selbstauskunftBeraterEmail: null,
            customerId: '',
            email: '',
            salutation: '',
            salutationOptions: [
                { value: 'NO_SALUTATION', label: 'Keine Anrede' },
                { value: 'MRS', label: 'Frau' },
                { value: 'MR', label: 'Herr' },
            ],
            isProf: false,
            isDr: false,
            availableRoles: [
                {
                    label: 'Einreicher-Modul',
                    value: 'ROLE_CUSTOMER_UPLOAD',
                    hasRole: 'ROLE_BANK_EMPLOYEE_UPLOAD',
                },
                {
                    label: 'Valutierungs-Modul',
                    value: 'ROLE_CUSTOMER_VALUTIERUNG',
                    hasRole: 'ROLE_BANK_EMPLOYEE_VALUTIERUNG',
                },
                {
                    label: 'Selbstauskunft',
                    value: 'ROLE_CUSTOMER_SELBSTAUSKUNFT',
                    hasRole: 'ROLE_BANK_EMPLOYEE_SELBSTAUSKUNFT',
                },
            ],
            roles: [],
            firstName: '',
            lastName: '',
            mobile: '',
            bankEmployeeOptions: [{ value: null, label: '--Kein Berater--' }],
        };
    },
    computed: {
        roleValue() {
            return (role) => {
                return this.roles?.indexOf(role) !== -1;
            };
        },
        ...configStore.mapState({
            configData: configStore.STATE.CONFIG,
        }),
        ...bankEmployeeStore.mapGetters({
            hasRole: bankEmployeeStore.GETTERS.HAS_ROLE,
        }),
    },
    methods: {
        toggleRole(val, role) {
            if (val) {
                this.roles.push(role);
            } else {
                this.roles = this.roles.filter(r => r !== role);
            }
        },
        async fetchPersonStammdaten() {
            this.$q.loading.show();
            try {
                const { data } = await CustomerService.fetchAgree21PersonStammdaten(this.customerId);
                this.firstName = data?.NatDaten?.Vorname ?? '';
                this.lastName = data?.NatDaten?.Nachname ?? '';

                switch (data?.NatDaten?.Anrede ?? '') {
                    case 'Frau':
                        this.salutation = 'MRS';
                        break;
                    case 'Herr':
                        this.salutation = 'MR';
                        break;
                    case 'Keine Anrede':
                        this.salutation = 'NO_SALUTATION';
                        break;
                    default:
                        this.salutation = '';
                        break;
                }

                this.isDr = /Dr\./.test(data?.NatDaten?.Titel ?? '');
                this.isProf = /Prof\./.test(data?.NatDaten?.Titel ?? '');

                const email = (data?.Kontaktdaten?.Kontakt ?? []).find((kontakt) => kontakt.Kontaktmedium === 'E-Mail');
                this.email = email?.Wert ?? '';

                const mobile = (data?.Kontaktdaten?.Kontakt ?? []).find((kontakt) => kontakt.Kontaktmedium === 'Mobiltelefon');
                this.mobile = mobile?.Wert ?? '';
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        async bankEmployeeFilter(val, update) {
            const { data } = await BankEmployeeService.getBankEmployees({ 'items-per-page': 30, 'search': val, 'exists[archivedAt]': false });
            const options = [{ value: null, label: '--Kein Berater--' }];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item['@id'],
                    label: item.email,
                });
            });

            update(() => {
                this.bankEmployeeOptions = options;
            });
        },
        async submit() {
            this.$q.loading.show();
            try {
                const { data: newCustomer } = await BankEmployeeService.createCustomer({
                    email: this.email,
                    roles: this.roles,
                    customerId: this.customerId,
                    salutation: this.salutation,
                    isProf: this.isProf,
                    isDr: this.isDr,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    mobile: this.mobile,
                    bankEmployee: this.bankEmployee,
                    selbstauskunftBeraterEmail: this.selbstauskunftBeraterEmail,
                });
                await this.$router.push({ name: 'admin-customer-details', params: { id: newCustomer.id } });
                this.$notify('Der Kunde wurde erfolgreich angelegt.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
        selbstauskunftBeraterEmailChanged(value) {
            this.selbstauskunftBeraterEmail = typeof value === 'string' ? value : (value?.label ?? null);
        },
    },
};
