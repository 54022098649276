//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomerService from '@/api/customer.service';
import EnquiryTemplatesService from '@/api/enquiryTemplates.service';

export default {
    props: {
        customer: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        let selectedCustomer = '';
        let customerOptions = []; // [{ value: '', label: '--Bitte wählen--' }];

        if (this.customer !== null) {
            selectedCustomer = this.customer['@id'];
            customerOptions = [{ value: this.customer['@id'], label: `${this.customer.customerId}: ${this.customer.firstName || ''} ${this.customer.lastName || ''} - ${this.customer.email}` }];
        }
        const enquiryTemplateOptions = [{ value: '', label: '-- Ohne Vorlage --' }];

        return {
            selectedCustomer,
            selectedEnquiryTemplate: '',
            publicId: '',
            customerOptions,
            enquiryTemplateOptions,
            overrideName: '',
        };
    },
    methods: {
        async updateOverrideName(enquiryTemplate) {
            if (!enquiryTemplate) {
                return;
            }
            this.overrideName =  this.enquiryTemplateOptions.find((item) => item.value === enquiryTemplate)?.label ?? this.overrideName;
        },
        async customerFilter(val, update) {
            const { data } = await CustomerService.getCustomers({
                'search': val,
                'items-per-page': 30,
            });
            const options = []; // [{ value: '', label: '--Bitte wählen--' }];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item['@id'],
                    label: `${item.customerId}: ${item.firstName} ${item.lastName} - ${item.email}`,
                });
            });

            update(() => {
                this.customerOptions = options;
            });
        },
        async enquiryTemplateFilter(val, update) {
            const { data } = await EnquiryTemplatesService.getEnquiryTemplates(val, 1, 30);
            const options = [{ value: '', label: '-- Ohne Vorlage --' }];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item['@id'],
                    label: item.name,
                });
            });

            update(() => {
                this.enquiryTemplateOptions = options;
            });
        },
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async ok() {
            const isValid = await this.$refs.form.validate(true);
            if (!isValid) {
                return;
            }

            this.$emit('ok', {
                customer: this.selectedCustomer,
                enquiryTemplate: this.selectedEnquiryTemplate,
                publicId: this.publicId,
                overrideName: this.overrideName,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
    },
};
