import Vue from 'vue';
import VueRouter from 'vue-router';
import adminRoutes from './admin.routes';
import customerRoutes from './customer.routes';
import configStore from '@/store/modules/configStore';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    return originalPush.call(
        this,
        location,
        () => {
            this.app.routing = false;
            onResolve ? onResolve(arguments) : void(0);
        },
        (err) => {
            this.app.routing = false;
            onReject ? onReject(err) : void(0);
        },
    );
};

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
    return originalReplace.call(
        this,
        location,
        () => {
            this.app.routing = false;
            onResolve ? onResolve(arguments) : void(0);
        },
        (err) => {
            this.app.routing = false;
            onReject ? onReject(err) : void(0);
        },
    );
};

Vue.use(VueRouter);

const routes = [
    ...adminRoutes,
    ...customerRoutes,
    {
        path: '/',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'root',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/Start'),
                meta: {
                    hideMenu: true,
                },
            },
            {
                path: 'legal',
                name: 'legal',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/Legal'),
            },
            {
                path: 'about',
                name: 'about',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/About'),
            },
            {
                path: '*',
                name: '404',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/Error404'),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    router.app.routing = true;
    const interval = setInterval(() => {
        if (configStore.getters[configStore.GETTERS.CONFIG_LOADED]) {
            clearInterval(interval);
            next();
        }
    }, 5);
});

router.afterEach(() => {
    router.app.routing = false;
});

export default router;
