//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        disable: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            password: '',
            passwordRepeat: '',
        };
    },
    emits: ['submit'],
    methods: {
        submit() {
            this.$emit('submit', this.password);
        },
    },
};
