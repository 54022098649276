const disbursementStatusConfig = {
    draft: {
        text: 'Entwurf',
        color: 'grey-3',
        textColor: 'primary',
    },
    active: {
        text: 'Aktiv',
        color: 'primary',
        textColor: 'primary-invert',
    },
    completed: {
        text: 'Abgeschlossen',
        color: 'positive',
        textColor: 'positive-invert',
    },
    aborted: {
        text: 'Abgebrochen',
        color: 'negative',
        textColor: 'negative-invert',
    },
    archived: {
        text: 'Archiviert',
        color: 'grey-3',
        textColor: 'positive',
    },
};

export default disbursementStatusConfig;
