//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { scroll } from 'quasar';
import ValutierungService from '@/api/valutierung.service';

const { getScrollTarget, setScrollPosition } = scroll;

export default {
    props: {
        value: {
            type: Object,
            required: true,
        },
        template: {
            type: Object,
            required: true,
        },
        disbursement: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            progressElements: [],
            newItemLabel: '',
            acknowledged: false,
            expansionItemState: true,
            error: false,
            ready: false,
        };
    },
    computed: {
        icon() {
            return this.template?.icon ?? 'fal fa-percent';
        },
        completed() {
            return this.ready && !this.value.inputTemplate.progressAllowAdd && this.progressElements.every(({ value }) => value === 100);
        },
    },
    async beforeMount() {
        const { data } = await ValutierungService.getDisbursementProgressForTemplate(
            this.disbursement.id,
            this.template.id
        );
        this.progressElements = data;

        if (!this.value.inputTemplate.progressAllowAdd && this.progressElements.every(({ value }) => value === 100)) {
            this.expansionItemState = false;
        }

        this.ready = true;
        this.submitValue();
    },
    methods: {
        disableSubtract(index) {
            return !this.progressElements[index].editable
                || this.progressElements[index].value <= this.progressElements[index].previousValue;
        },
        subtractTooltip(index) {
            if (this.progressElements[index].value === 0) {
                return undefined;
            }

            if (
                !this.progressElements[index].editable
                || this.progressElements[index].value <= this.progressElements[index].previousValue
            ) {
                return 'Bearbeitung wurde durch einen anderen Auszahlungsantrag zur Wahrung der Konsistenz blockiert.';
            }

            return undefined;
        },
        disableAdd(index) {
            return !this.progressElements[index].editable
                || this.progressElements[index].value >= 100;
        },
        addTooltip(index) {
            if (this.progressElements[index].value === 100) {
                return undefined;
            }

            if (!this.progressElements[index].editable) {
                return 'Bearbeitung wurde durch einen anderen Auszahlungsantrag zur Wahrung der Konsistenz blockiert.';
            }

            return undefined;
        },
        subtract(index) {
            if (this.disableSubtract(index)) {
                return;
            }

            this.progressElements[index].value -= 25;
            this.submitValue();
        },
        add(index) {
            if (this.disableAdd(index)) {
                return;
            }

            this.progressElements[index].value += 25;
            this.submitValue();
        },
        addNewItem() {
            if (!this.newItemLabel) {
                this.$notify('Bezeichnung darf nicht leer sein.', 'warning');
                return;
            }

            if (this.progressElements.some(progressElement => progressElement.label === this.newItemLabel)) {
                this.$notify('Eintrag bereits vorhanden.', 'warning');
                return;
            }

            this.progressElements.push({
                deletable: true,
                editable: true,
                label: this.newItemLabel,
                maxValue: 0,
                nextValue: null,
                previousValue: 0,
                value: 0,
            });

            this.newItemLabel = '';
            this.submitValue();
        },
        deleteItem(index) {
            this.progressElements.splice(index, 1);
            this.submitValue();
        },
        submitValue() {
            const value = [];
            this.progressElements.forEach(progressElement => {
                value.push({
                    label: progressElement.label,
                    value: progressElement.value,
                });
            });
            this.value.inputValue.value = value;

            this.$emit('input', this.value);
        },
        validate() {
            return true;
        },
        resetValidation() {
            // noop
        },
        focus() {
            const el = this.$el;
            const target = getScrollTarget(el);
            const offset = el.offsetTop;
            const duration = 250;
            this.expansionItemState = true;
            setScrollPosition(target, offset, duration);
        },
    },
};
