import ApiClient from '@/api/_apiClient';

const EnquiryAttachmentsService = {
    createEnquiryAttachment(enquiryAttachment) {
        return ApiClient.post('enquiry-attachments', enquiryAttachment);
    },
    patchEnquiryAttachment(enquiryAttachment, payload) {
        return ApiClient.patch(`enquiry-attachments/${enquiryAttachment.id}`, payload);
    },
    deleteEnquiryAttachment(enquiryAttachment) {
        return ApiClient.delete(`enquiry-attachments/${enquiryAttachment.id}`);
    },
    uploadEnquiryAttachment(enquiryAttachment, files) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };

        if (!Array.isArray(files)) {
            files = [files];
        }

        const formData = new FormData();
        files.forEach(file => formData.append('file[]', file));

        return ApiClient.post(`enquiry-attachments/${enquiryAttachment.id}/upload`, formData, { headers });
    },
    downloadEnquiryAttachment(enquiryAttachment) {
        return ApiClient.get(`enquiry-attachments/${enquiryAttachment.id}/download`,  { responseType: 'blob' });
    },
    async agree21Archive(id, enquiryAttachment, customer) {
        return await ApiClient.post(`enquiry-attachments/${id}/agree21-archive`, {
            enquiryAttachment,
            customer,
        });
    },
};

export default EnquiryAttachmentsService;
