//
//
//
//
//
//

import TechnicalMessage from '@/components/core/TechnicalMessage';
import TechnicalMessageService from '@/api/technicalMessage.service';

export default {
    components: { TechnicalMessage },
    props: {
        target: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            technicalMessages: [],
        };
    },
    async beforeMount() {
        const { data } = await TechnicalMessageService.getTechnicalMessages(this.target);
        this.technicalMessages = data['hydra:member'];
    },
};
