export default [
    {
        path: '/login',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'customer-login',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/Login'),
                meta: {
                    hideMenu: true,
                },
            },
        ],
    },
    {
        path: '/invite/:invitationCode',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'customer-invite',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/Invite'),
                meta: {
                    hideMenu: true,
                },
            },
        ],
    },
    {
        path: '/reset-password/:passwordResetCode',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'customer-reset-password',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/ResetPassword'),
                meta: {
                    hideMenu: true,
                },
            },
        ],
    },
    {
        path: '/profile',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'customer-profile',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/Profile'),
            },
        ],
    },
    {
        path: '/aktivierung',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'customer-activation',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/Activation'),
            },
        ],
    },
    {
        path: '/dashboard',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'customer-dashboard',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/Dashboard'),
            },
        ],
    },
    {
        path: '/hub',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'customer-hub',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/Hub'),
            },
            {
                path: 'vorgang/:id',
                name: 'customer-enquiry',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/Enquiry'),
            },
            {
                path: 'vorgang/:id/dokumente/:status',
                name: 'customer-files',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/Files'),
            },
            {
                path: 'vorgang/:id/dokument/:fileId',
                name: 'customer-file',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/File'),
            },
        ],
    },
    {
        path: '/kreditvorhaben',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/customer/CustomerLayout'),
        children: [
            {
                path: '',
                name: 'customer-valutierung-overview',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/valutierung/Overview'),
            },
            {
                path: ':id',
                name: 'customer-financing-project',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/valutierung/FinancingProject'),
            },
            {
                path: ':financingProjectId/auszahlungsantrag',
                name: 'customer-create-disbursement',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/valutierung/CreateDisbursement'),
            },
            {
                path: ':financingProjectId/auszahlungsantrag/:disbursementId',
                name: 'customer-disbursement',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/customer/valutierung/Disbursement'),
            },
        ],
    },
];
