//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        batchResult: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async ok() {
            this.$emit('ok', {});
            this.hide();
        },
        cancel() {
            this.$emit('ok', {});
            this.hide();
        },
    },
};
