import ApiClient from '@/api/_apiClient';

const ConfigService = {
    async fetchCurrent() {
        return await ApiClient.get('configs/current');
    },
    async fetchFooterUrls() {
        return await ApiClient.get('footer-urls');
    },
};

export default ConfigService;
