import ApiClient from '@/api/_apiClient';

const BankEmployeeService = {
    getResourceByIri(iri) {
        return ApiClient.get(iri, {
            baseURL: '',
        });
    },
    async login(email, password) {
        return await ApiClient.post('bank-employees/login', {
            email,
            password,
        });
    },
    async logout() {
        return await ApiClient.get('bank-employees/logout');
    },
    async refresh() {
        return await ApiClient.post('bank-employees/refresh');
    },
    async getBankEmployee(id) {
        return await ApiClient.get(`bank-employees/${id}`);
    },
    async getBankEmployees(params = {}) {
        return await ApiClient.get('bank-employees', { params });
    },
    async createBankEmployees(params) {
        return await ApiClient.post('bank-employees', params);
    },
    async inviteEmployee(id) {
        return await ApiClient.post(`bank-employees/${id}/invite`);
    },
    async activate(invitationCode, password) {
        return await ApiClient.post('bank-employees/activate', {
            invitationCode,
            password,
        });
    },
    requestPasswordReset(email) {
        return ApiClient.post('bank-employees/request-password-reset', {
            email,
        });
    },
    resetPassword(passwordResetCode, password) {
        return ApiClient.post('bank-employees/reset-password', {
            passwordResetCode,
            password,
        });
    },
    async patchBankEmployee(id, params){
        return await ApiClient.patch(`bank-employees/${id}`, params);
    },
    async changeOwnData(data){
        return await ApiClient.post('bank-employees/change-own-data', data);
    },
    async confirmEmail(confirmCode) {
        return await ApiClient.post(`bank-employees/confirm-email/${confirmCode}`);
    },
    async getCustomerList(params) {
        return await ApiClient.get('customers', { params });
    },
    async createCustomer(params) {
        return await ApiClient.post('customers', params);
    },
    async archive(id) {
        return await ApiClient.post(`bank-employees/${id}/archive`);
    },
    async csvExport() {
        return await ApiClient.get('bank-employees/report/csv', { responseType: 'blob' });
    },
};

export default BankEmployeeService;
