import _moduleHelper from '@/store/modules/_moduleHelper';
import FileService from '@/api/file.service';

export const NAMESPACE = 'FILE';

export const STATE = {
    CURRENT_FILE: 'CURRENT_FILE',
    CURRENT_FILE_STATUS_CHANGES: 'CURRENT_FILE_STATUS_CHANGES',
};

export const GETTERS = {
    LAST_FILE_STATUS_CHANGE: 'LAST_FILE_STATUS_CHANGE',
};

export const MUTATIONS = {
    SET_CURRENT_FILE: 'SET_CURRENT_FILE',
    RESET_CURRENT_FILE: 'RESET_CURRENT_FILE',
    SET_CURRENT_FILE_STATUS_CHANGES: 'SET_CURRENT_FILE_STATUS_CHANGES',
    RESET_CURRENT_FILE_STATUS_CHANGES: 'RESET_CURRENT_FILE_STATUS_CHANGES',
};

export const ACTIONS = {
    FETCH_FILE: 'FETCH_FILE',
    CREATE_FILE: 'CREATE_FILE',
    PATCH_CURRENT_FILE: 'PATCH_CURRENT_FILE',
    UPLOAD: 'UPLOAD',
    READY: 'READY',
    PROBLEM: 'PROBLEM',
    UNPROBLEM: 'UNPROBLEM',
    PATCH_FILE: 'PATCH_FILE',
    FETCH_FILE_STATUS_CHANGES: 'FETCH_FILE_STATUS_CHANGES',
};

export const MODULE = {
    namespaced: true,
    state: {
        [STATE.CURRENT_FILE]: null,
        [STATE.CURRENT_FILE_STATUS_CHANGES]: null,
    },
    getters: {
        [GETTERS.LAST_FILE_STATUS_CHANGE](state) {
            let fileStatusChanges = state[STATE.CURRENT_FILE_STATUS_CHANGES]?.['hydra:member'] || [];
            if (!fileStatusChanges.length) {
                return null;
            }

            return fileStatusChanges[0];
        },
    },
    mutations: {
        [MUTATIONS.SET_CURRENT_FILE](state, file) {
            state[STATE.CURRENT_FILE] = Object.assign({}, file);
        },
        [MUTATIONS.RESET_CURRENT_FILE](state) {
            state[STATE.CURRENT_FILE] = null;
        },
        [MUTATIONS.SET_CURRENT_FILE_STATUS_CHANGES](state, fileStatusChanges) {
            state[STATE.CURRENT_FILE_STATUS_CHANGES] = fileStatusChanges;
        },
        [MUTATIONS.RESET_CURRENT_FILE_STATUS_CHANGES](state) {
            state[STATE.CURRENT_FILE_STATUS_CHANGES] = null;
        },
    },
    actions: {
        async [ACTIONS.FETCH_FILE]({ commit }, id) {
            const { data } = await FileService.fetchFile(id);
            commit(MUTATIONS.SET_CURRENT_FILE, data);

            return data;
        },
        async [ACTIONS.UPLOAD](_, { id, files, onUploadProgress = null }) {
            return await FileService.upload(id, files, onUploadProgress);
        },
        async [ACTIONS.READY](_, id) {
            return await FileService.ready(id);
        },
        async [ACTIONS.PROBLEM](_, { id, context = {} }) {
            return await FileService.problem(id, context);
        },
        async [ACTIONS.UNPROBLEM](_, { id, context = {} }) {
            return await FileService.unproblem(id, context);
        },
        async [ACTIONS.FETCH_FILE_STATUS_CHANGES]({ commit }, id) {
            const { data } = await FileService.fetchFileStatusChanges(id);
            commit(MUTATIONS.SET_CURRENT_FILE_STATUS_CHANGES, data);

            return data;
        },
        async [ACTIONS.CREATE_FILE]({ commit }, file) {
            const { data } = await FileService.createFile(file);
            commit(MUTATIONS.SET_CURRENT_FILE, data);

            return data;
        },
        async [ACTIONS.PATCH_CURRENT_FILE]({ commit }, { id, requestedFile }) {
            const { data } = await FileService.patchFile(id, requestedFile);
            commit(MUTATIONS.SET_CURRENT_FILE, data);

            return data;
        },
        async [ACTIONS.PATCH_FILE](_, { id, patchData }) {
            const { data } = await FileService.patchFile(id, patchData);

            return data;
        },
    },
};

export default {
    NAMESPACE,
    STATE,
    GETTERS,
    MUTATIONS,
    ACTIONS,
    MODULE,
    ..._moduleHelper(NAMESPACE),
};
