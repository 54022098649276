//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import customerStore from '@/store/modules/customerStore';

export default {
    data() {
        return {
            password: '',
            passwordRepeat: '',
        };
    },
    methods: {
        async submit() {
            this.$q.loading.show();
            try {
                await customerStore.dispatch(customerStore.ACTIONS.RESET_PASSWORD, {
                    passwordResetCode: this.$route.params.passwordResetCode,
                    password: this.password,
                });
                this.$notify('Ihr Passwort wurde erfolgreich geändert.', 'positive');
                await this.$router.push({ name: 'customer-login' });
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        if (customerStore.getters[customerStore.GETTERS.IS_LOGGED_IN]) {
            return next({ name: 'customer-dashboard' });
        }
        next();
    },
};
