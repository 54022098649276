//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';
import IntroDialog from '@/components/upload/dialogs/IntroDialog';
import CustomerService from '@/api/customer.service';
import { Loading } from 'quasar';

export default {
    data() {
        return {
            menu: null,
        };
    },
    computed: {
        showLogo() {
            return !this.$route.meta?.hideLogo;
        },
        showMenu() {
            return !this.$route.meta?.hideMenu;
        },
        ...configStore.mapState({
            config: configStore.STATE.CONFIG,
        }),
        ...customerStore.mapState({
            loggedInCustomer: customerStore.STATE.LOGGED_IN_CUSTOMER,
        }),
        ...customerStore.mapGetters({
            isLoggedIn: customerStore.GETTERS.IS_LOGGED_IN,
            loggedInCustomerLoaded: customerStore.GETTERS.LOGGED_IN_CUSTOMER_LOADED,
            customerHasRole: customerStore.GETTERS.HAS_ROLE,
        }),
        ...customerStore.mapState({
            customer: customerStore.STATE.LOGGED_IN_CUSTOMER,
        }),
    },
    methods: {
        intro() {
            const menu = this.menu;
            this.$nextTick(() => {
                this.menu = menu;
            });
            this.$q.dialog({
                component: IntroDialog,
                parent: this,
            });
        },
        async logout() {
            Loading.show();
            await customerStore.dispatch(customerStore.ACTIONS.LOGOUT);
            Loading.hide();
            await this.$router.push({ name: 'customer-login' });
        },
        loginSelbstauskunft() {
            const win = window.open('', '_blank');
            win.document.write('Eine Sekunde Geduld bitte...');
            (async () => {
                Loading.show();
                try {
                    const { data } = await CustomerService.getLoginForSelbstauskunft();
                    win.location.href = data['href'];
                } catch (error) {
                    this.$handleError(error);
                }
                Loading.hide();
            })();
        },
        visitCustomerHubOrSingleDocument() {
            const enqs = (this?.customer?.enquiries || []).filter(enq => enq.status !== 'draft');
            if (enqs.length == 1) {
                this.$router.push({ name: 'customer-enquiry', params: { id: enqs[0].id } });
            } else {
                this.$router.push({ name: 'customer-hub' });
            }
        },
    },
};
