import VuexPersistence from 'vuex-persist';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import customerStore from '@/store/modules/customerStore';
import { MAJOR_MINOR_VERSION } from '@/version';

// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
function storageAvailable(type) {
    let storage;
    try {
        storage = window[type];
        const storageTest = '__storage_test__';
        storage.setItem(storageTest, storageTest);
        storage.removeItem(storageTest);
        return true;
    } catch (e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}

export default storageAvailable('localStorage') ? new VuexPersistence({
    key: `SERVICE_PORTAL_STORAGE_v${MAJOR_MINOR_VERSION}`,
    reducer(state) {
        return {
            [bankEmployeeStore.NAMESPACE]: {
                [bankEmployeeStore.STATE.TOKEN_PAYLOAD]: state[bankEmployeeStore.NAMESPACE][bankEmployeeStore.STATE.TOKEN_PAYLOAD],
            },
            [customerStore.NAMESPACE]: {
                [customerStore.STATE.TOKEN_PAYLOAD]: state[customerStore.NAMESPACE][customerStore.STATE.TOKEN_PAYLOAD],
            },
        };
    },
}).plugin : () => {};


