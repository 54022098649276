//
//
//
//
//
//

import disbursementStatusConfig from '@/config/disbursementStatus.config';

export default {
    props: {
        status: {
            type: String,
            default: 'Status',
        },
        align: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        config() {
            if (disbursementStatusConfig[this.status]) {
                return disbursementStatusConfig[this.status];
            }

            return {
                color: 'grey-3',
                textColor: 'primary',
                text: this.status,
            };
        },
    },
};
