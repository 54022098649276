//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileTemplatesService from '@/api/fileTemplates.service';
import _cloneDeep from 'lodash/cloneDeep';

const defaultEnquiryTemplate = {
    name: '',
    description: '',
    fileTemplates: [],
    expires: false,
    expiresInDays: 0,
};

export default {
    props: {
        value: {
            type: Object,
            default: () => _cloneDeep(defaultEnquiryTemplate),
        },
        type: {
            type: String,
            default: 'create',
            validator: (value) => ['create', 'edit'].indexOf(value) !== -1,
        },
    },
    data() {
        const enquiryTemplate = { ...(_cloneDeep(defaultEnquiryTemplate)), ...(_cloneDeep(this.value)), expires: !!this.value.expiresInDays };
        const fileTemplateOptions = [];
        if (enquiryTemplate.fileTemplates.length) {
            enquiryTemplate.fileTemplates.forEach((item, key) => {
                fileTemplateOptions[key] = [{ value: null, label: '-- Zurücksetzen --' }];
            });
        }

        return {
            maximized: false,
            seed: btoa('' + Math.random()),
            fileTemplateToAdd: null,
            fileTemplateToAddOptions: [],
            enquiryTemplate,
            fileTemplateOptions,
        };
    },
    computed: {
        headline() {
            switch (this.type) {
                case 'edit':
                    return 'Vorgangsvorlage bearbeiten';
                case 'create':
                default:
                    return 'Vorgangsvorlage erstellen';
            }
        },
        buttonLabel() {
            switch (this.type) {
                case 'edit':
                    return 'Speichern';
                case 'create':
                default:
                    return 'Anlegen';
            }
        },
    },
    mounted() {
        if (this.enquiryTemplate?.fileTemplates.length) {
            this.enquiryTemplate.fileTemplates.forEach((item, key) => {
                this.fileTemplateOptions[key].push({
                    value: item['@id'],
                    label: item.name,
                });
                this.enquiryTemplate.fileTemplates[key] = item['@id'];
            });
        }
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async ok() {
            if (await this.$refs.form.validate() === false) {
                return;
            }

            this.$emit('ok', {
                ...this.enquiryTemplate,
                expiresInDays: this.enquiryTemplate.expires ? this.enquiryTemplate.expiresInDays : 0,
            });
            this.hide();
        },
        cancel() {
            this.hide();
        },
        async fileTemplatesFilter(key, val, update) {
            const { data } = await FileTemplatesService.getFileTemplates(val, 1, 30, 'name');
            const options = [];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item['@id'],
                    label: item.name,
                });
            });

            update(() => {
                this.fileTemplateOptions[key] = options;
            });
        },
        async fileTemplateToAddFilter(val, update) {
            const { data } = await FileTemplatesService.getFileTemplates(val, 1, 30, 'name');
            const options = [];
            data['hydra:member'].forEach(item => {
                options.push({
                    value: item['@id'],
                    label: item.name,
                });
            });

            update(() => {
                this.fileTemplateToAddOptions = options;
            });
        },
        removeFileTemplate(key) {
            this.seed = btoa('' + Math.random());
            this.enquiryTemplate.fileTemplates.splice(key, 1);
            this.fileTemplateOptions.splice(key, 1);
        },
        addFileTemplate() {
            if (this.fileTemplateToAdd) {
                this.seed = btoa('' + Math.random());
                this.enquiryTemplate.fileTemplates.push(this.fileTemplateToAdd);
                this.fileTemplateOptions.push(this.fileTemplateToAddOptions);
                this.fileTemplateToAdd = null;
            }
        },
    },
};
