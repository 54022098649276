const enquiryStatusConfig = {
    draft: {
        text: 'Entwurf',
        color: 'grey-3',
        textColor: 'primary',
    },
    open: {
        text: 'Offen',
        color: 'grey-3',
        textColor: 'primary',
    },
    completed: {
        text: 'In Prüfung',
        color: 'grey-3',
        textColor: 'positive',
    },
    processed: {
        text: 'Abgeschlossen',
        color: 'positive',
        textColor: 'positive-invert',
    },
    expired: {
        text: 'Abgelaufen',
        color: 'grey-3',
        textColor: 'negative',
    },
    aborted: {
        text: 'Abgebrochen',
        color: 'grey-3',
        textColor: 'negative',
    },
    archived: {
        text: 'Archiviert',
        color: 'positive',
        textColor: 'positive-invert',
    },
};

export default enquiryStatusConfig;
