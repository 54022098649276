import { render, staticRenderFns } from "./CustomerList.vue?vue&type=template&id=b37f6f20&"
import script from "./CustomerList.vue?vue&type=script&lang=js&"
export * from "./CustomerList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QSpace,QBtn,QTooltip,QTable,QToggle,QSelect,QInput,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QSpace,QBtn,QTooltip,QTable,QToggle,QSelect,QInput,QIcon})
