//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileService from '@/api/file.service';
import fileStore from '@/store/modules/fileStore';

export default {
    name: 'CustomerRequestedFileNew',
    data() {
        return {
            name: '',
            description: '',
            tooltip: '',
            expiresAt: null,
            enquiryId: '',
            checkRequired: false,
            checkExpiresAt: false,
            sortIndex: 0,
            categories: null,
            category: '',
        };
    },
    methods: {
        async addRequestedFile() {
            this.$q.loading.show();
            try {
                if(!this.checkExpiresAt) {
                    this.expiresAt = null;
                }
                await fileStore.dispatch(fileStore.ACTIONS.CREATE_FILE, {
                    enquiry: this.enquiryId,
                    name: this.name,
                    description: this.description,
                    tooltip: this.tooltip,
                    status: 'requested',
                    required: this.checkRequired,
                    expiresAt: this.expiresAt,
                    sortIndex: this.sortIndex,
                    fileCategory: this.category['@id'],
                });

                await this.$router.push({ name: 'admin-enquiry-details' });
                this.$notify('Der Vorgang wurde erfolgreich angelegt.', 'positive');
            } catch (error) {
                this.$handleError(error);
            }
            this.$q.loading.hide();
        },
    },
    async beforeRouteEnter(to, from, next) {
        const { data: categories } = await FileService.fetchCategories();
        const enquiryIRI = '/api/enquiries/' + to.params.enquiryId;
        try {
            next(vm => {
                vm.enquiryId = enquiryIRI;
                vm.customerId = to.params.id;
                vm.categories = categories['hydra:member'];
            });
        } catch (e) {
            next(vm => vm.$handleError(e));
        }
    },
};
