import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=99f2b262&scoped=true&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=99f2b262&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99f2b262",
  null
  
)

export default component.exports
import {QPage,QCard,QCardSection,QSeparator,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QCard,QCardSection,QSeparator})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
