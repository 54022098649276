import ApiClient from '@/api/_apiClient';

const DemoService = {
    createDemoCustomer(payload) {
        return ApiClient.post('demo/create-demo-customer', payload);
    },
    inviteDemoCustomer(email) {
        return ApiClient.post('demo/invite-demo-customer', { email });
    },
};

export default DemoService;
