import ApiClient from '@/api/_apiClient';

const TechnicalMessageService = {
    getTechnicalMessages(target = undefined, active = true, page = 1, itemsPerPage = 50) {
        return ApiClient.get('technical-messages', {
            params: {
                'target': target,
                'active': typeof active === 'boolean' ? (active ? '1' : '0') : undefined,
                'page': page,
                'items-per-page': itemsPerPage,
            },
        });
    },
};

export default TechnicalMessageService;
