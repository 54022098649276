//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import enquiryStore from '@/store/modules/enquiryStore';
import EnquiryPatchStatusDialog from '@/components/upload/dialogs/EnquiryPatchStatusDialog';

export default {
    props: {
        enquiry: {
            type: Object,
            required: true,
        },
        context: {
            type: [Object, Array],
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {
        status() {
            return this.enquiry.status;
        },
        contextMessage() {
            return this.context?.message || '';
        },
        warningText() {
            if (this.status === 'completed' && this.enquiry.requestedFiles.some(file => file.status === 'requested' && file.required)) {
                return 'Bitte vergessen Sie nicht im Anschluss den Vorgang zurück an den Kunden zu übergeben.';
            }
            if (this.status === 'processed' && this.enquiry.requestedFiles.some(file => file.status !== 'processed' && file.required)) {
                return 'Der Vorgang wurde als abgeschlossen markiert aber es gibt Dokumente die noch nicht verarbeitet wurden.';
            }

            return '';
        },
    },
    methods: {
        patchEnquiryStatus(newStatus) {
            this.$q.dialog({
                component: EnquiryPatchStatusDialog,
                parent: this,
                enquiry: this.enquiry,
                newStatus: newStatus,
            }).onOk(async (payload) => {
                this.$q.loading.show();
                try {
                    await enquiryStore.dispatch(enquiryStore.ACTIONS.PATCH_CURRENT_ENQUIRY, {
                        id: this.enquiry.id,
                        enquiry: {
                            status: newStatus,
                            __context: {
                                message: payload.message,
                            },
                        },
                    });
                    this.$emit('refresh');
                } catch (error) {
                    this.$handleError(error);
                }
                this.$q.loading.hide();
            });
        },
    },
};
