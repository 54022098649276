export default [
    {
        path: '/admin',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/admin/AdminLayout'),
        children: [
            {
                path: 'login',
                name: 'admin-login',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/Login'),
                meta: {
                    hideMenu: true,
                },
            },
            {
                path: 'reset-password/:passwordResetCode',
                name: 'admin-reset-password',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/ResetPassword'),
                meta: {
                    hideMenu: true,
                },
            },
            {
                path: 'confirm-email/:code',
                name: 'confirm-email',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/ConfirmEmail'),
                meta: {
                    hideMenu: true,
                },
            },
            {
                path: 'invite/:invitationCode',
                name: 'admin-invite',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/bank-employee/BankEmployeeInvite'),
                meta: {
                    hideMenu: true,
                },
            },
            {
                path: '',
                name: 'admin-dashboard',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/Dashboard'),
            },
            {
                path: 'kunden',
                name: 'admin-customer-list',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/CustomerList'),
            },
            {
                path: 'kunde/neu',
                name: 'admin-customer-new',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/CustomerNew'),
            },
            {
                path: 'kunde/:id',
                name: 'admin-customer-details',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/CustomerDetails'),
            },
            {
                path: 'kunde/:id/vorhaben/neu',
                name: 'admin-valutierung-project',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/CustomerProjectNew'),
            },
            {
                path: 'kunde/:id/vorhaben/:vorhabenId',
                name: 'admin-valutierung-project-details',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/CustomerProjectDetails'),
            },
            {
                path: 'kunde/:id/vorhaben/:vorhabenId/kredit/neu',
                name: 'admin-valutierung-credit',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/CustomerCreditNew'),
            },
            {
                path: 'kunde/:id/vorhaben/:vorhabenId/kredit/:creditId',
                name: 'admin-valutierung-credit-details',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/CustomerCreditDetails'),
            },
            // TODO: @deprecated
            {
                path: 'kunde/:id/vorgang/neu',
                name: 'admin-enquiry-new',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/upload/CustomerEnquiryNew'),
            },
            // TODO: @deprecated
            {
                path: 'kunde/:id/vorgang/:enquiryId',
                name: 'admin-enquiry-details',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/upload/CustomerEnquiryDetails'),
            },
            // TODO: @deprecated
            {
                path: 'kunde/:id/vorgang/:enquiryId/dokument/new',
                name: 'admin-enquiry-document-new',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/upload/CustomerRequestFileNew'),
            },
            // TODO: @deprecated
            {
                path: 'kunde/:id/vorgang/:enquiryId/dokument/:fileId',
                name: 'admin-enquiry-document-details',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/customers/upload/CustomerRequestedFileDetails'),
            },
            {
                path: 'bank-mitarbeiter',
                name: 'admin-bank-employee-list',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/bank-employee/BankEmployeeList'),
            },
            {
                path: 'bank-mitarbeiter/neu',
                name: 'admin-bank-employee-new',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/bank-employee/BankEmployeeNew'),
            },
            {
                path: 'profil',
                name: 'bank-employee-profil',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/bank-employee/BankEmployeeProfil'),
            },
            {
                path: 'bank-mitarbeiter/:id',
                name: 'admin-bank-employee-details',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/bank-employee/BankEmployeeDetails'),
            },
        ],
    },
    {
        path: '/admin/valutierung',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/admin/AdminLayout'),
        children: [
            {
                path: 'auszahlungsantraege',
                name: 'admin-valutierung-disbursements',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/valutierung/Disbursements'),
            },
            {
                path: 'auszahlungsantraege/:id',
                name: 'admin-valutierung-disbursement',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/valutierung/Disbursement'),
            },
        ],
    },
    {
        path: '/admin/upload',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/admin/AdminLayout'),
        children: [
            {
                path: '',
                name: 'admin-upload-dashboard',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/upload/Dashboard'),
            },
            {
                path: 'file-categories',
                name: 'admin-upload-file-categories',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/upload/FileCategories'),
            },
            {
                path: 'file-templates',
                name: 'admin-upload-file-templates',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/upload/FileTemplates'),
            },
            {
                path: 'enquiry-templates',
                name: 'admin-upload-enquiry-templates',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/upload/EnquiryTemplates'),
            },
            {
                path: 'enquiries',
                name: 'admin-upload-enquiries',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/upload/Enquiries'),
            },
            // TODO: @deprecated?
            {
                path: 'enquiries/create',
                name: 'admin-upload-enquiry-create',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/upload/EnquiryCreate'),
            },
            {
                path: 'enquiries/:id',
                name: 'admin-upload-enquiry',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/upload/Enquiry'),
            },
        ],
    },
    {
        path: '/admin/demo',
        component: () => import(/* webpackMode: 'eager' */ '@/layouts/admin/AdminLayout'),
        children: [
            {
                path: '',
                name: 'admin-demo-actions',
                component: () => import(/* webpackMode: 'eager' */ '@/pages/admin/demo/DemoActions'),
            },
        ],
    },
];
