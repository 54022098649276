import Vue from 'vue';
import Vuex from 'vuex';
import persistencePlugin from '@/store/persistence.plugin';
import bankEmployeeStore from '@/store/modules/bankEmployeeStore';
import configStore from '@/store/modules/configStore';
import customerStore from '@/store/modules/customerStore';
import enquiryStore from '@/store/modules/enquiryStore';
import fileStore from '@/store/modules/fileStore';
import fileCategoriesStore from '@/store/modules/fileCategoriesStore';
import fileTemplatesStore from '@/store/modules/fileTemplatesStore';
import enquiryTemplatesStore from '@/store/modules/enquiryTemplatesStore';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: (process.env.APP_ENV !== 'production'),
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        [bankEmployeeStore.NAMESPACE]: bankEmployeeStore.MODULE,
        [configStore.NAMESPACE]: configStore.MODULE,
        [customerStore.NAMESPACE]: customerStore.MODULE,
        [enquiryStore.NAMESPACE]: enquiryStore.MODULE,
        [fileStore.NAMESPACE]: fileStore.MODULE,
        [fileCategoriesStore.NAMESPACE]: fileCategoriesStore.MODULE,
        [fileTemplatesStore.NAMESPACE]: fileTemplatesStore.MODULE,
        [enquiryTemplatesStore.NAMESPACE]: enquiryTemplatesStore.MODULE,
    },
    plugins: [persistencePlugin],
});
